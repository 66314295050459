// import React, { useState, useEffect } from 'react';
// import Datetime from "react-datetime";
// import "react-datetime/css/react-datetime.css";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
// import axios from 'axios';
// import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';
// import BaseURL from '../assets/contants/BaseURL';
//  import Header from '../components/header';

// const Reports = () => {
//   const [fromDate, setFromDate] = useState(null);
//   const [toDate, setToDate] = useState(null);
//   // const [meters, setMeters] = useState([]);
//   const [selectedMeter, setSelectedMeter] = useState('');
//   const [reportData, setReportData] = useState([]);
//   const [downloadFormat, setDownloadFormat] = useState('pdf');
//   const [groups, setGroups] = useState([]);
//   const [selectedGroup, setSelectedGroup] = useState("");

//   useEffect(() => {
//     const fetchGroups = async () => {
//       try {
//         const response = await axios.get(`${BaseURL}meters/emsgroups/`);
//         if (response.status === 200) {
//           setGroups(response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching meter groups:", error);
//       }
//     };

//     fetchGroups();
//   }, []);

//   const handleGroupSelect = (groupId) => {
//     if (selectedGroup === groupId) {
//       setSelectedGroup("");
//     } else {
//       setSelectedGroup(groupId);
//     }
//   };

//   const fetchReportData = async () => {
//     if (!fromDate || !toDate || !selectedMeter) {
//       alert('Please select a valid date range and meter.');
//       return;
//     }

//     const requestData = {
//       start_datetime: fromDate.toISOString().slice(0, 16),
//       end_datetime: toDate.toISOString().slice(0, 16),
//       meter_id: selectedMeter,
//     };

//     try {
//       const response = await axios.post(`${BaseURL}data/report/`, requestData);
//       if (response.status === 200) {
//         setReportData(response.data);
//       } else {
//         console.error('Failed to fetch report data:', response);
//       }
//     } catch (error) {
//       console.error('Error fetching report data:', error);
//     }
//   };

//   const downloadReport = () => {
//     if (!reportData || reportData.length === 0) {
//       alert('No data available to download.');
//       return;
//     }

//     const fromDateObj = new Date(fromDate);
//     const toDateObj = new Date(toDate);
  
//     if (downloadFormat === 'pdf') {
//       const doc = new jsPDF('landscape');
  
//       const meterId = reportData[0]?.meter_id;
//       const fromDateFormatted = !isNaN(fromDateObj) ? fromDateObj.toLocaleDateString() : 'N/A';
//       const toDateFormatted = !isNaN(toDateObj) ? toDateObj.toLocaleDateString() : 'N/A';
  
//       doc.setFontSize(20);
//       doc.text(`${meterId}`, doc.internal.pageSize.width / 2, 10, { align: 'center' });
  
//       if (fromDateFormatted && toDateFormatted) {
//         doc.text(
//           `From: ${fromDateFormatted} To: ${toDateFormatted}`,
//           doc.internal.pageSize.width / 2,
//           20,
//           { align: 'center' }
//         );
//       }
  
//       const headers = [
//         'Date', 'kWhR', 'kWhY', 'kWhB',
//         'VPHASE(RY)', 'VPHASE(YB)', 'VPHASE(BR)',
//         'VLINE(R)', 'VLINE(Y)', 'VLINE(B)',
//         'VHar(R)', 'VHar(Y)', 'VHar(B)',
//         'CuR', 'CuY', 'CuB',
//         'CuHar(R)', 'CuHar(Y)', 'CuHar(B)',
//         'PFR', 'PFY', 'PFB',
//       ];
  
//       const rows = reportData.map(data => [
//         data.date,
//         data.total_kWh_R, data.total_kWh_Y, data.total_kWh_B,
//         data.total_VPHASE_RY, data.total_VPHASE_YB,
//         data.total_VPHASE_BR,
//         data.total_VLINE_R, data.total_VLINE_Y, data.total_VLINE_B,
//         data.total_VHar_R, data.total_VHar_Y, data.total_VHar_B,
//         data.total_Cu_R, data.total_Cu_Y, data.total_Cu_B,
//         data.total_CuHar_R, data.total_CuHar_Y, data.total_CuHar_B,
//         data.total_PF_R, data.total_PF_Y, data.total_PF_B,
//       ]);
  
//       doc.autoTable({
//         head: [headers],
//         body: rows,
//         startY: 30,
//         margin: { top: 10, left: 10, right: 10 },
//         pageBreak: 'auto',
//         theme: 'striped',
//         headStyles: {
//           fontSize: 6,
//           fillColor: [0, 102, 204],
//           textColor: 255,
//           halign: 'center',
//         },
//         bodyStyles: {
//           fontSize: 6,
//           textColor: 0,
//           halign: 'center',
//         },
//       });
  
//       doc.save('report.pdf');
//     } else if (downloadFormat === 'csv') {
//       const headers = [
//         'Date', 'kWhR', 'kWhY', 'kWhB',
//         'VPHASE(RY)', 'VPHASE(YB)', 'VPHASE(BR)',
//         'VLINE(R)', 'VLINE(Y)', 'VLINE(B)',
//         'VHar(R)', 'VHar(Y)', 'VHar(B)',
//         'CuR', 'CuY', 'CuB',
//         'CuHar(R)', 'CuHar(Y)', 'CuHar(B)',
//         'PFR', 'PFY', 'PFB',
//       ];
  
//       const rows = reportData.map(data => [
//         data.date,
//         data.total_kWh_R, data.total_kWh_Y, data.total_kWh_B,
//         data.total_VPHASE_RY, data.total_VPHASE_YB,
//         data.total_VPHASE_BR,
//         data.total_VLINE_R, data.total_VLINE_Y, data.total_VLINE_B,
//         data.total_VHar_R, data.total_VHar_Y, data.total_VHar_B,
//         data.total_Cu_R, data.total_Cu_Y, data.total_Cu_B,
//         data.total_CuHar_R, data.total_CuHar_Y, data.total_CuHar_B,
//         data.total_PF_R, data.total_PF_Y, data.total_PF_B,
//       ]);
  
//       const meterId = reportData[0]?.meter_id;
//       const fromDateFormatted = !isNaN(fromDateObj) ? fromDateObj.toLocaleDateString() : 'N/A';
//       const toDateFormatted = !isNaN(toDateObj) ? toDateObj.toLocaleDateString() : 'N/A';
  
//       const metadata = `Meter ID: ${meterId}\nFrom Date: ${fromDateFormatted}  To Date: ${toDateFormatted}\n\n`;
  
//       const csvWithHeader =
//         metadata + headers.join(',') + '\n' + rows.map(row => row.join(',')).join('\n');
  
//       const blob = new Blob([csvWithHeader], { type: 'text/csv' });
//       const url = window.URL.createObjectURL(blob);
  
//       const link = document.createElement('a');
//       link.href = url;
//       link.download = 'report.csv';
//       link.click();
  
//       window.URL.revokeObjectURL(url);
//     }
//   };

//   return (
    // <div style={{ textAlign: 'center', width: '100%' }}>
    //   <div
    //     style={{
    //       backgroundColor: '#007acc',
    //       color: 'white',
    //       alignContent: 'center',
    //       fontSize: '14px',
    //       height: '40px',
    //     }}
    //   >
    //     <Header title="REPORTS" />
    //   </div>

//       <div
//         style={{
//           border: '1px solid #ccc',
//           padding: '10px',
//           marginTop: '20px',
//           boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//           backgroundColor: 'white',
//           maxWidth: '1000px',
//           marginLeft: 'auto',
//           marginRight: 'auto',
//           borderRadius: '8px',
//         }}
//       >
//         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
//           <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//             <label style={{ fontWeight: "bold" }}>From Date:</label>
//             <Datetime
//               selected={fromDate}
//               onChange={(date) => setFromDate(date)}
//               inputProps={{
//                 placeholder: "Select From Date Time",
//                 style: { borderRadius: "10px", border: "1px solid #ccc", padding: "10px", width: "80%" }
//               }}
//             />

//             <label style={{ fontWeight: "bold" }}>To Date:</label>
//             <Datetime
//               selected={toDate}
//               onChange={(date) => setToDate(date)}
//               inputProps={{
//                 placeholder: "Select To Date Time",
//                 style: { borderRadius: "10px", border: "1px solid #ccc", padding: "10px", width: "80%" }
//               }}
//             />
//           </div>

//           <div
//         style={{
//           position: 'relative',
//           display: 'inline-block',
//           width: '150px',
//         }}
//       >
//         <select
//           style={{
//             padding: "10px 15px", 
//             fontSize: "14px", 
//             width: "100%", 
//             height: "38px", 
//             marginBottom: "15px", 
//             marginTop: "15px",
//             borderRadius: "8px", 
//             border: "1px solid #ccc", 
//             backgroundColor: "#f8f8f8", 
//             boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", 
//             appearance: "none",
//             cursor: "pointer",
//           }}
//           value={selectedGroup}
//           onChange={(e) => handleGroupSelect(e.target.value)}
//         >
//           <option value="">Select Group</option>
//           {groups.map((group) => (
//             <option key={group.group_id} value={group.group_id}>
//               {group.group_name}
//             </option>
//           ))}
//         </select>

//         {selectedGroup && (
//           <div
//             style={{
//               position: 'absolute',
//               top: '35px',
//               left: '0',
//               width: '100%',
//               zIndex: '10',
//               marginTop: "25px",
//               display: 'block',
//             }}
//           >
//             <select
//               style={{
//                 padding: "5px", fontSize: "14px", width: "100%", height: "30px", marginBottom: "10px"
//               }}
//               value={selectedMeter}
//               onChange={(e) => setSelectedMeter(e.target.value)}
//             >
//               {groups
//                 .find((group) => group.group_id.toString() === selectedGroup)
//                 ?.meter.map((meter) => (
//                   <option key={meter.id} value={meter.meter_id}>
//                     {meter.meter_name}
//                   </option>
//                 ))}
//             </select>
//           </div>
//         )}
//       </div>
//           <button
//             style={{
//               padding: '8px 12px',
//               backgroundColor: '#007acc',
//               color: 'white',
//               border: 'none',
//               cursor: 'pointer',
//               borderRadius: '8px',
//             }}
//             onClick={fetchReportData}
//           >
//             <FontAwesomeIcon icon={faSearch} />
//           </button>

//           <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//             <button
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 padding: '8px 12px',
//                 backgroundColor: '#007acc',
//                 color: 'white',
//                 border: 'none',
//                 cursor: 'pointer',
//                 borderRadius: '8px',
//               }}
//               onClick={downloadReport}
//             >
//               <span>Download</span>
//               <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '5px' }} />
//             </button>

//             <select
//               style={{ padding: '5px', fontSize: '16px', borderRadius: '8px' }}
//               value={downloadFormat}
//               onChange={(e) => setDownloadFormat(e.target.value)}
//             >
//               <option value="pdf">PDF</option>
//               <option value="csv">CSV</option>
//             </select>
//           </div>
//         </div>
//       </div>

//       <div
//         style={{
//           marginTop: '20px',
//           padding: '10px',
//           boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//           backgroundColor: 'white',
//           maxWidth: '1000px',
//           margin: '20px auto',
//           borderRadius: '8px',
//         }}
//       >
//         <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
//           <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
//             <tr>
//               <th style={{ padding: '10px' }}>Date</th>
//               <th style={{ padding: '10px' }}>Meter ID</th>
//               <th style={{ padding: '10px' }}>Meter Name</th>
//               <th style={{ padding: '10px' }}>First kWh</th>
//               <th style={{ padding: '10px' }}>Last kWh</th>
//               <th style={{ padding: '10px' }}>Total kWh</th>
//               <th style={{ padding: '10px' }}>Unit Rate</th>
//             </tr>
//           </thead>
//           <tbody>
//             {reportData.map((data, index) => (
//               <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
//                 <td style={{ padding: '10px' }}>{data.date}</td>
//                 <td style={{ padding: '10px' }}>{data.meter_id}</td>
//                 <td style={{ padding: '10px' }}>{data.meter_name}</td>
//                 <td style={{ padding: '10px' }}>{data.first_kwhr}</td>
//                 <td style={{ padding: '10px' }}>{data.last_kwhr}</td>
//                 <td style={{ padding: '10px' }}>{data.total_kwh}</td>
//                 <td style={{ padding: '10px' }}>{data.unit_rate}</td>
//               </tr>
//             ))}
//             {reportData.length === 0 && (
//               <tr>
//                 <td colSpan="7" style={{ textAlign: 'center', padding: '10px' }}>
//                   No data available
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Reports;


import React, { useState, useEffect,useRef } from "react";
import "../scss/ReportCard.scss";
import 'chartjs-adapter-moment';
import 'chart.js/auto';
import ExcelJS from 'exceljs';
import Header from '../components/header';
import BaseURL from '../assets/contants/BaseURL';
import axios from 'axios';
import html2canvas from 'html2canvas';
import DatePicker from "react-datepicker";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

ChartJS.register(...registerables, zoomPlugin);

const ReportCard = () => {
  const [selectedMeter, setSelectedMeter] = useState("");
  const [selecteddailyMeter, setSelecteddailyMeter] = useState("");
  const [selectedlogMeter, setSelectedlogMeter] = useState(""); 
  const [selectedhistMeter, setSelectedhistMeter] = useState("");
  const [selectedmonMeter, setSelectedmonMeter] = useState("");
  const [meterId, setMeterId] = useState('');
  const [activeTab, setActiveTab] = useState("Hourly Report");
  const [selectedreportDate, setSelectedreportDate] = useState("");
  const [selectedfromreportDate, setSelectedfromreportDate] = useState("");
  const [selectedtoreportDate, setSelectedtoreportDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [hourlyData, setHourlyData] = useState([]);
  const [monthlyfromDate, setmonthlyFromDate] = useState(null);
  const [monthlytoDate, setmonthlyToDate] = useState(null);
  const [hourlyfromdate, setHourlyfromdate] = useState("");
  const [hourlytodate, setHourlytodate] = useState(""); 
  const [dailyreportData, setDailyreportData] = useState([]);
  const [logData, setLogData] = useState([]);
//   const [logTotalKwh, setLogTotalKwh] = useState(0);
  const [historicalTrend, setHistoricalTrend] = useState(" ");
  const [chartssData, setChartssData] = useState([]);
  const [currentreportPage, setCurrentreportPage] = useState(1);
  const [currentdailyreportPage, setCurrentdailyreportPage] = useState(1);
  const [currentmonthlyreportPage, setCurrentmonthlyreportPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [currenthourlyPage, setCurrenthourlyPage] = useState(1);
  const [totalhourlyPages, setTotalhourlyPages] = useState(1);
  const [peakTimingData, setPeakTimingData] = useState([]);
  const chartRef = useRef();
  const [loading, setLoading] = useState(false);
  const [histloading, sethistLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
 const [searchMeter, setSearchMeter] = useState('');
 const [searchdailyMeter, setSearchdailyMeter] = useState('');
 const [searchmonthMeter, setSearchmonthMeter] = useState('');
 const [searchparameterMeter, setSearchparameterMeter] = useState('');
 const [searchpeakMeter, setSearchpeakMeter] = useState('');
 const [searchhistMeter, setSearchhistMeter] = useState('');
 const [monthsData, setMonthsData] = useState([]);
  const [hourlyloading,sethourlyLoading] = useState(false);
  const [dailyloading,setdailyLoading] = useState(false);
  const [monthloading,setmonthLoading] = useState(false);
  const [parameterloading,setparameterLoading] = useState(false);
  const [peakloading,setpeakLoading] = useState(false);
  const [allMeters, setAllMeters] = useState([]);
  const [filteredMeters, setFilteredMeters] = useState([]);
  const [filteredDailyMeter, setFilteredDailyMeter] = useState([]);
  const [filteredMonthlyMeter, setFilteredMonthlyMeter] = useState([]);
  const [filteredPeakTimingMeter, setFilteredPeakTimingMeter] = useState([]);
  const [filteredHistoricalTrendMeter, setFilteredHistoricalTrendMeter] = useState([]);
  const [filteredParametricMeter, setFilteredParametricMeter] = useState([]);
  const dropdownRef = useRef(null); 
  const [groupData, setGroupData] = useState([]);



  const tabs = ["Hourly Report", "Daily Report", "Monthly Report", "Parametric Report","Peak Timing Report", "Historical Trend"];

const handleMonthlyFromMonthChange = (date) => {
    setmonthlyFromDate(date);
};

const handleMonthlyToMonthChange = (date) => {
    setmonthlyToDate(date);
};

const handleMonthlyReport = async () => {
    try {
        setmonthLoading(true);
        setCurrentmonthlyreportPage(1);
        const formatDate = (date) => {
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${year}-${month < 10 ? '0' + month : month}`;
        };

        const formattedFromDate = formatDate(monthlyfromDate);
        const formattedToDate = formatDate(monthlytoDate);


        const response = await axios.post(`${BaseURL}data/monthly-report/`, {
            from_month_year: formattedFromDate,
            to_month_year: formattedToDate,
            meter_id:selectedmonMeter
        });

        const data = response.data;

        const formattedData = data.months.map((month, index) => ({
            month,
            startKwh: data.columns[0].data[index],
            endKwh: data.columns[1].data[index],
            totalKwh: data.columns[2].data[index],
            totalCost: data.columns[4].data[index],
            totalCo2: data.columns[5].data[index]
        }));
;
        setMonthsData(formattedData);
    } catch (error) {
        console.error("Error fetching monthly report:", error);
    } finally {
        setmonthLoading(false);
    }
};

const rowsmonthlyPerPage = 10;

    const totalmonthlyPages = Math.ceil(monthsData.length / rowsmonthlyPerPage);

    const indexOfmonthlyLastRow = currentmonthlyreportPage * rowsmonthlyPerPage;
    const indexOfmonthlyFirstRow = indexOfmonthlyLastRow - rowsmonthlyPerPage;
    const currentMonthlyRows = monthsData.slice(indexOfmonthlyFirstRow, indexOfmonthlyLastRow);

    const handlemonthlyPageChange = (newPage) => {
        setCurrentmonthlyreportPage(newPage);
    };
  
  useEffect(() => {
    const fetchMeters = async () => {
        try {
            const metersResponse = await axios.get(`${BaseURL}/meters/energymeters/`);
            const meters = metersResponse.data; 
            setAllMeters(meters); 
            setFilteredMeters(meters);
            setFilteredMonthlyMeter(meters); 
            setFilteredDailyMeter(meters);
            setFilteredPeakTimingMeter(meters);
            setFilteredHistoricalTrendMeter(meters);
            setFilteredParametricMeter(meters);
        } catch (error) {
            console.error("Error fetching meter data:", error);
        }
    };

    fetchMeters();
}, []);


useEffect(() => {
    const fetchGroupData = async () => {
        try {
            const response = await fetch(`${BaseURL}/meters/emsgroups/`);
            const data = await response.json();
            setGroupData(data);
        } catch (error) {
            console.error("Error fetching group data:", error);
        }
    };

    fetchGroupData();
}, []);

  const handleSelect = (meterId, tab) => {
    switch (tab) {
        case 'Hourly Report':
            setSelectedMeter(meterId);
            break;
        case 'Daily Report':
            setSelecteddailyMeter(meterId);
            break;
        case 'Parametric Report':
            setSelectedlogMeter(meterId);
            break;
        case 'Historical Trend':
            setSelectedhistMeter(meterId);
            break;
        case 'Peak Timing Report':
            setMeterId(meterId);
            break;
        case'Monthly Report':
            setSelectedmonMeter(meterId); 
            break;
        default:
            break;
    }

    setIsOpen(false);
};

useEffect(() => {
    const filtered = allMeters.filter(meter =>
        meter.meter_name.toLowerCase().includes(searchMeter.toLowerCase()) ||
        meter.meter_id.toLowerCase().includes(searchMeter.toLowerCase())
    );
    setFilteredMeters(filtered);
}, [searchMeter, allMeters]);


useEffect(() => {
    const filtered = allMeters.filter(meter =>
        meter.meter_name.toLowerCase().includes(searchdailyMeter.toLowerCase()) ||
        meter.meter_id.toLowerCase().includes(searchdailyMeter.toLowerCase())
    );
    setFilteredDailyMeter(filtered);
}, [searchdailyMeter, allMeters]);
  

useEffect(() => {
    const filtered = allMeters.filter(meter =>
        meter.meter_name.toLowerCase().includes(searchmonthMeter.toLowerCase()) ||
        meter.meter_id.toLowerCase().includes(searchmonthMeter.toLowerCase())
    );
    setFilteredMonthlyMeter(filtered);
}, [searchmonthMeter, allMeters]);
  
useEffect(() => {
    const filtered = allMeters.filter(meter =>
        meter.meter_name.toLowerCase().includes(searchparameterMeter.toLowerCase()) ||
        meter.meter_id.toLowerCase().includes(searchparameterMeter.toLowerCase())
    );
    setFilteredParametricMeter(filtered);
}, [searchparameterMeter, allMeters]);

  
  useEffect(() => {
    const filtered = allMeters.filter(meter =>
        meter.meter_name.toLowerCase().includes(searchpeakMeter.toLowerCase()) ||
        meter.meter_id.toLowerCase().includes(searchpeakMeter.toLowerCase())
    );
    setFilteredPeakTimingMeter(filtered);
}, [searchpeakMeter, allMeters]);
  
useEffect(() => {
    const filtered = allMeters.filter(meter =>
        meter.meter_name.toLowerCase().includes(searchhistMeter.toLowerCase()) ||
        meter.meter_id.toLowerCase().includes(searchhistMeter.toLowerCase())
    );
    setFilteredHistoricalTrendMeter(filtered);
}, [searchhistMeter, allMeters]);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);

const getGroupName = (meterId) => {
    if (!meterId) {
        return "N/A";
    }
    for (const group of groupData) {
        for (const meter of group.meter) {
            if (meter.meter_id === meterId) {
                return group.meter_name;
            }
        }
    }
    return "Unknown";
};

  const handleHistoricalTrendChange = (event) => {
      setHistoricalTrend(event.target.value);
      setChartssData([]);
  };

  useEffect(() => {
    const fetchData = async () => {
        if (historicalTrend && selectedreportDate) {
            sethistLoading(true);
            try {
                const response = await axios.post(`${BaseURL}data/historical-trend/`, {
                    meter_id:selectedhistMeter,
                    date: selectedreportDate,
                    parameter: historicalTrend.toLowerCase(),
                });

                const { time_intervals, ...parameterValues } = response.data;

                if (time_intervals.length === 0 || Object.keys(parameterValues).every(key => parameterValues[key].length === 0)) {
                    setChartssData([]);
                    return;
                }

                const formattedData = time_intervals.map((time, index) => {
                    const entry = { time };
                    for (const [key, values] of Object.entries(parameterValues)) {
                        entry[key] = values[index];
                    }
                    return entry;
                });

                setChartssData(formattedData);
            } catch (error) {
                console.error("Error fetching historical trend data:", error);
                setChartssData([]);
            } finally {
                sethistLoading(false);
            }
        }
    };

    fetchData();
}, [historicalTrend, selectedhistMeter,selectedreportDate]);
    const downloadChartAsPNG = () => {
      setLoading(true);
      if (chartRef.current) {
        html2canvas(chartRef.current).then((canvas) => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/png');
          link.download = 'chart.png';
          link.click();
          setLoading(false); 
        });
      }else {
        setLoading(false); 
      }
    };
  

    

    const handleGetHourlyReport = async () => {
        if (!hourlyfromdate || !hourlytodate) {
            alert("Please select a From Date and To Date.");
            return;
        }
    
        try {
            sethourlyLoading(true);
    
            const fromDate = hourlyfromdate.split("T")[0];
            const fromTime = hourlyfromdate.split("T")[1];
            const toDate = hourlytodate.split("T")[0];
            const toTime = hourlytodate.split("T")[1];
    
            const payload = {
                meter_id: selectedMeter,
                from_date: fromDate,
                to_date: toDate,
                from_time: fromTime,
                to_time: toTime,
            };

            const response = await axios.post(`${BaseURL}data/hourly-report-pagination/`, payload);

            if (response.data.error) {
                console.warn(response.data.error);
                setHourlyData([]);
                setTotalhourlyPages(0);
                setCurrenthourlyPage(1);
                sethourlyLoading(false);
                return;
            }

            const { date, columns, time_intervals } = response.data.results;
            const totalPage = response.data["total page"];

            if (!columns || columns.length === 0) {
                throw new Error("Columns data is missing or invalid");
            }

            const startKwhData = columns.find(column => column.name === "start kwh")?.data || [];
            const endKwhData = columns.find(column => column.name === "end kwh")?.data || [];
            const totalKwhData = columns.find(column => column.name === "total kwh")?.data || [];
            const totalCostData = columns.find(column => column.name === "total cost")?.data || [];
            const totalCo2Data = columns.find(column => column.name === "total co2")?.data || [];

            const hourlyData = time_intervals.map((interval, index) => ({
                date: date[index],
                timeInterval: interval,
                startKwh: startKwhData[index],
                endKwh: endKwhData[index],
                totalKwh: totalKwhData[index],
                totalCost: totalCostData[index],
                totalCo2: totalCo2Data[index]
            }));
            setHourlyData(hourlyData);
            setTotalhourlyPages(totalPage);
            setCurrenthourlyPage(1);
            sethourlyLoading(false);
        }  catch (error) {
            console.error("Error fetching hourly report:", error);
            setHourlyData([]);
            setTotalhourlyPages(0);
            setCurrenthourlyPage(1);
            sethourlyLoading(false);
        } finally {
            sethourlyLoading(false);
        }
    };
    
    const handlehourlyPageChange = async (page) => {
        if (page < 1 || page > totalhourlyPages || page === currenthourlyPage) return;
    
        try {
            sethourlyLoading(true);
            const fromDate = hourlyfromdate.split("T")[0];
            const fromTime = hourlyfromdate.split("T")[1];
            const toDate = hourlytodate.split("T")[0];
            const toTime = hourlytodate.split("T")[1];
    
            const payload = {
                meter_id: selectedMeter,
                from_date: fromDate,
                to_date: toDate,
                from_time: fromTime,
                to_time: toTime,
            };
    
            const pageUrl = `${BaseURL}data/hourly-report-pagination/?page=${page}`;
    
            const response = await axios.post(pageUrl, payload);
            const { date, columns = [], time_intervals = [] } = response.data.results || {};
    
            if (!columns.length) {
                throw new Error("Columns data is missing or invalid");
            }
    
            const startKwhData = columns.find(column => column.name === "start kwh")?.data || [];
            const endKwhData = columns.find(column => column.name === "end kwh")?.data || [];
            const totalKwhData = columns.find(column => column.name === "total kwh")?.data || [];
            const totalCostData = columns.find(column => column.name === "total cost")?.data || [];
            const totalCo2Data = columns.find(column => column.name === "total co2")?.data || [];
    
            if (time_intervals.length !== date.length) {
                throw new Error("Mismatch between time_intervals and date length");
            }
    
            const newHourlyData = time_intervals.map((interval, index) => ({
                date: date[index],
                timeInterval: interval,
                startKwh: startKwhData[index],
                endKwh: endKwhData[index],
                totalKwh: totalKwhData[index],
                totalCost: totalCostData[index],
                totalCo2: totalCo2Data[index]
            }));
    
            setHourlyData(newHourlyData);
            setCurrenthourlyPage(page);
            sethourlyLoading(false);
        } catch (error) {
            console.error("Error fetching hourly report on page change:", error);
            sethourlyLoading(false);
        }
    };

  const handleGetDailyReport = async () => {
      try {
        setdailyLoading(true);
        setCurrentdailyreportPage(1);
            const fromDateString = fromDate.split("T")[0];
            const fromTime = fromDate.split("T")[1];
            const toDateString = toDate.split("T")[0];
            const toTime = toDate.split("T")[1];
      const response = await axios.post(`${BaseURL}data/daily-report/`, {
          meter_id: selecteddailyMeter,
          start_date: fromDateString,
          end_date: toDateString,
          from_time: fromTime,
          to_time: toTime,
      });

      const data = response.data;
    
            const formattedData = data.dates.map((date, index) => {
                const fromDateTime = `${date} / ${data.Times[index].split(" to ")[0]}`;
                const toDateTime = `${date} / ${data.Times[index].split(" to ")[1]}`;
    
                return {
                    date,
                    fromDate: fromDateTime,
                    toDate: toDateTime,
                    startKwh: data.columns[0].data[index],
                    endKwh: data.columns[1].data[index],
                    totalKwh: data.columns[2].data[index],
                    totalCost: data.columns[3].data[index],
                    totalCo2: data.columns[4].data[index]
                };
            });

          setDailyreportData(formattedData);
      } catch (error) {
          console.error("Error fetching daily report:", error);
      }finally {
        setdailyLoading(false); // Stop loading
    }
};

    const rowsdailyPerPage = 10;

    const totaldailyPages = Math.ceil(dailyreportData.length / rowsdailyPerPage);

    const indexOfdailyLastRow = currentdailyreportPage * rowsdailyPerPage;
    const indexOfdailyFirstRow = indexOfdailyLastRow - rowsdailyPerPage;
    const currentDailyRows = dailyreportData.slice(indexOfdailyFirstRow, indexOfdailyLastRow);

    const handledailyPageChange = (newPage) => {
        setCurrentdailyreportPage(newPage);
    };

    const fetchHourlyReportData = async () => {
        if (!hourlyfromdate || !hourlytodate) {
            alert("Please select a From Date and To Date.");
            return null; 
        }
    
        try {
            const fromDate = hourlyfromdate.split("T")[0];
            const fromTime = hourlyfromdate.split("T")[1];
            const toDate = hourlytodate.split("T")[0];
            const toTime = hourlytodate.split("T")[1];
    
            const payload = {
                meter_id: selectedMeter,
                from_date: fromDate,
                to_date: toDate,
                from_time: fromTime,
                to_time: toTime,
            };
    
            const response = await axios.post(`${BaseURL}data/hourly-report-excel/`, payload);
    
            const { date, columns, time_intervals } = response.data.results || {};
            if (!columns || columns.length === 0 || !time_intervals || !date) {
                throw new Error("Missing or invalid data in the response.");
            }
    
            const startKwhData = columns.find(column => column.name === "start kwh")?.data || [];
            const endKwhData = columns.find(column => column.name === "end kwh")?.data || [];
            const totalKwhData = columns.find(column => column.name === "total kwh")?.data || [];
            const totalCostData = columns.find(column => column.name === "total cost")?.data || [];
            const totalCo2Data = columns.find(column => column.name === "total co2")?.data || [];
    
            return time_intervals.map((interval, index) => ({
                date: date[index] || '',
                timeInterval: interval,
                startKwh: startKwhData[index] || 0,
                endKwh: endKwhData[index] || 0,
                totalKwh: totalKwhData[index] || 0,
                totalCost: totalCostData[index] || 0,
                totalCo2: totalCo2Data[index] || 0
            }));
        } catch (error) {
            console.error("Error fetching hourly report:", error);
            return null;
        }
    };    

  const handleGetLogReport = async () => {
    
    try {
        setparameterLoading(true);
        setCurrentreportPage(1);
        const fromDateString = selectedfromreportDate.split("T")[0];
        const fromTime = selectedfromreportDate.split("T")[1];
        const toDateString = selectedtoreportDate.split("T")[0];
        const toTime = selectedtoreportDate.split("T")[1];
        const response = await axios.post(`${BaseURL}data/logdata-report/`, {
            meter_id: selectedlogMeter,
            from_date: fromDateString,
            to_date: toDateString,
            from_time: fromTime,
            to_time: toTime
        });

        if (response.data.error) {
            console.warn(response.data.error);
            setLogData([]);
            setparameterLoading(false);
            return;
        }

        const data = response.data;
        const formattedData = data.time.map((time, index) => ({
            datetime: data.time[index],
            VR: data.vr_data[index],
            VY: data.vy_data[index],
            VB: data.vb_data[index],
            VRY: data.vry_data[index],
            VYB: data.vyb_data[index],
            VBR: data.vbr_data[index],
            IR: data.cu_r_data[index],
            IY: data.cu_y_data[index],
            IB: data.cu_b_data[index], 
            KVA: data.kva_data[index],
            KW: data.kw_data[index],
            PF: data.pf_avg_data[index],
            KWH: data.kwh_data[index],
        }));

        setLogData(formattedData);
    } catch (error) {
        console.error("Error fetching log report:", error);
        setLogData([]);
    } finally {
        setparameterLoading(false); // Stop loading
    }
};

  const totalreportPage = Math.ceil(logData.length / rowsPerPage);
  const indexOfLastRow = currentreportPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = logData.slice(indexOfFirstRow, indexOfLastRow);

    const handlePageChange = (page) => {
      setCurrentreportPage(page);
  };

  const fetchPeakTimings = async () => {
    try {
        setpeakLoading(true);
      const response = await axios.post(`${BaseURL}data/peak-timing-consumption/`, {
        meter_id: meterId,
        date: selectedreportDate,
      });

      const { time_intervals, rates, bars } = response.data;

      const mappedData = time_intervals.map((timing, index) => ({
        timing,
        rate: rates[index],
        todaysWh: bars[0]?.data[index] || 0,
        totalCost: bars[1]?.data[index] || 0,
        totalCo2: bars[2]?.data[index] || 0,
      }));

      setPeakTimingData(mappedData);
    } catch (error) {
      console.error("Error fetching peak timings:", error);
    } finally {
        setpeakLoading(false); // Stop loading
    }
};

  
  const handleGetReport = () => {
    if (meterId && selectedreportDate) {
      fetchPeakTimings();
    } else {
      alert("Please select both a meter and a date.");
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);

    switch (tab) {
        case "Hourly Report":
            setHourlyData([]); 
            setHourlyfromdate(0); 
            setHourlytodate(0);
            setSelectedMeter("");
            setSearchMeter("");
            setIsOpen(false);
            setCurrenthourlyPage(1);
            setTotalhourlyPages(0);
            break;
        case "Daily Report":
            setDailyreportData([]); 
            setFromDate(""); 
            setToDate(""); 
            setSelecteddailyMeter(""); 
            setSearchdailyMeter("");
            setIsOpen(false);
            setCurrentdailyreportPage(1);
            break;
        case "Monthly Report":
            setMonthsData([]);
            setmonthlyFromDate(null); 
            setmonthlyToDate(null); 
            setSelectedmonMeter("");
            setSearchmonthMeter(""); 
            setIsOpen(false);
            setCurrentmonthlyreportPage(1);
            break;
        case "Parametric Report":
            setLogData([]); 
            setSelectedfromreportDate(0);
            setSelectedtoreportDate(0);
            setSelectedlogMeter(""); 
            setSearchparameterMeter("");
            setIsOpen(false);
            setCurrentreportPage(1);
            break;
        case "Peak Timing Report":
            setPeakTimingData([]); 
            setSelectedreportDate(""); 
            setMeterId("");
            setSearchpeakMeter(""); 
            setIsOpen(false);
            break;
        case "Historical Trend":
            setChartssData([]); 
            setSelectedreportDate("");
            setHistoricalTrend(""); 
            setSelectedhistMeter(""); 
            setSearchhistMeter("");
            setIsOpen(false);
            break;
        default:
            break;
    }
};


const handleHourlyExportToExcel = async (fileName) => {
    const data = await fetchHourlyReportData();

    if (!data) {
        alert("No data available to export.");
        return;
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report');

    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20; 
    worksheet.getColumn(3).width = 15; 
    worksheet.getColumn(4).width = 15; 
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15; 
    worksheet.getColumn(7).width = 15; 

    let dateRow;

    if (activeTab === "Hourly Report") {
        dateRow = worksheet.addRow([`From Date: ${hourlyfromdate}, To Date: ${hourlytodate}`]);
        worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7); 

        for (let i = 1; i <= 7; i++) { 
            dateRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD9EAD3' }, 
            };
        }
    }

    dateRow.font = { bold: true, size: 14 };
    dateRow.alignment = { horizontal: 'center', vertical: 'middle' }; 
    const groupName = getGroupName(selectedMeter);
    const branchRow = worksheet.addRow(['Branch: Coimbatore','', `Meter ID: ${selectedMeter}`,'', `Place of Installation: ${groupName}`]);
    branchRow.font = { bold: true, size: 12 };
    branchRow.getCell(1).alignment = { horizontal: 'left' }; 
    branchRow.getCell(3).alignment = { horizontal: 'center' }; 

    worksheet.getColumn(3).width = Math.max(worksheet.getColumn(4).width || 0, `Meter ID: ${selectedMeter}`.length + 4); 

    const branchCellRange = activeTab === "Parametric Report" ? 14 : (activeTab === "Daily Report" ? 8 : 7);
    for (let i = 1; i <= branchCellRange; i++) {
        branchRow.getCell(i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDDDDDD' }, 
        };
    }

    const titleRow = worksheet.addRow([`${activeTab}`]);
    titleRow.font = { bold: true, size: 16 };
    titleRow.alignment = { horizontal: 'center' };

    worksheet.mergeCells(titleRow.number, 1, titleRow.number, branchCellRange);

    for (let i = 1; i <= branchCellRange; i++) {
        titleRow.getCell(i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1E90FF' }, 
        };
    }

    if (data.length > 0) {
        let headers = ['Date', 'Hour', 'Start WH', 'End WH','Co2', 'Total WH', 'Total Cost'];
        const headerRow = worksheet.addRow(headers);
        headerRow.font = { bold: true };

        headerRow.eachCell((cell, colIndex) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
            if (colIndex >= 1 && colIndex <= 7) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFA500' },
                };
            }
        });

        data.forEach((row, rowIndex) => {
            const rowData = [
                row.date,
                row.timeInterval,
                row.startKwh,
                row.endKwh,
                row.totalCo2,
                row.totalKwh,
                row.totalCost
            ];

            const dataRow = worksheet.addRow(rowData);

            dataRow.eachCell((cell, colIndex) => {
                cell.alignment = { horizontal: 'center', vertical: 'middle' }; 
                let fillColor;
                if (colIndex >= 1 && colIndex <= 7) {
                    fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF';
                }

                if (fillColor) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: fillColor }, 
                    };
                }
            });
        });

        const totals = calculateTotals(data);
        const totalsRow = worksheet.addRow(
            ['', '', 'TOTAL', '','', totals.totalKwh, totals.totalCost]
        );

        totalsRow.font = { bold: true };
        totalsRow.eachCell((cell, colIndex) => {
            if (colIndex >= 1 && colIndex <= 7) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFEB3B' },
                };
                if (colIndex === 6 || colIndex === 7) { 
                    cell.alignment = { horizontal: 'center', vertical: 'middle' };
                } else {
                    cell.alignment = { horizontal: 'right' }; 
                }
                cell.border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' },
                };
            }
        });
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    });
};

  const handleExportToExcel = (data) => {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Report');
  
      worksheet.getColumn(1).width = 30;
      worksheet.getColumn(2).width = 20; 
      worksheet.getColumn(3).width = 20; 
      worksheet.getColumn(4).width = 15; 
      worksheet.getColumn(5).width = 15;
      worksheet.getColumn(6).width = 15; 
      worksheet.getColumn(7).width = 15; 
  
      let dateRow;
  
  if (activeTab === "Parametric Report") {
      dateRow = worksheet.addRow([`Date and Time: ${selectedreportDate}`]);
      worksheet.mergeCells(dateRow.number, 1, dateRow.number, 14); 
  
      for (let i = 1; i <= 14; i++) { 
          dateRow.getCell(i).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFD9EAD3' }, 
          };
      }
  } else if (activeTab === "Hourly Report") {
      
      dateRow = worksheet.addRow([`From Date: ${hourlyfromdate}, To Date: ${hourlytodate}`]);
      worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7); 
  
      for (let i = 1; i <= 7; i++) { 
          dateRow.getCell(i).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFD9EAD3' }, 
          };
      }
    }else if (activeTab === "Monthly Report") {
        const formatDate = (date) => {
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return `${year}-${month < 10 ? '0' + month : month}`;
        };

        const formattedFromDate = formatDate(monthlyfromDate);
        const formattedToDate = formatDate(monthlytoDate);
        dateRow = worksheet.addRow([`From Month: ${formattedFromDate}, To Month: ${formattedToDate}`]);
        worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7); 
    
        for (let i = 1; i <= 7; i++) { 
            dateRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFD9EAD3' }, 
            };
        }
  } else if (activeTab === "Daily Report") {
     
      dateRow = worksheet.addRow([`From Date: ${fromDate}, To Date: ${toDate}`]);
      worksheet.mergeCells(dateRow.number, 1, dateRow.number, 8); 
  
      for (let i = 1; i <= 8; i++) { 
          dateRow.getCell(i).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFD9EAD3' }, 
          };
      }
  }
  
  dateRow.font = { bold: true, size: 14 };
  dateRow.alignment = { horizontal: 'center', vertical: 'middle' };
  const metersId = selectedMeter || selecteddailyMeter || selectedlogMeter || selectedhistMeter;
  const groupName = getGroupName(metersId); 
  const branchRow = worksheet.addRow(['Branch: Coimbatore', ' ', ' ',`Place of Installation: ${groupName}`]);
  branchRow.font = { bold: true, size: 12 };
  branchRow.getCell(1).alignment = { horizontal: 'left' }; 
  branchRow.getCell(3).alignment = { horizontal: 'center' }; 
  
  const branchCellRange = 
  activeTab === "Parametric Report" ? 14 : 
  activeTab === "Daily Report" ? 8 : 
  (activeTab === "Hourly Report" || activeTab === "Monthly Report") ? 7 :
    7; 

for (let i = 1; i <= branchCellRange; i++) {
  branchRow.getCell(i).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFDDDDDD' }, 
  };
}

  
  
  const titleRow = worksheet.addRow([`${activeTab}`]);
  titleRow.font = { bold: true, size: 16 };
  titleRow.alignment = { horizontal: 'center' };
  
  worksheet.mergeCells(titleRow.number, 1, titleRow.number, branchCellRange);
  
  for (let i = 1; i <= branchCellRange; i++) {
      titleRow.getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF1E90FF' }, 
      };
  }
  
      if (data.length > 0) {
          let headers;
          if (activeTab === "Hourly Report") {
              headers = ['Date', 'Hour', 'Start WH', 'End WH', 'Total WH', 'Total Cost'];
          } else if (activeTab === "Daily Report") {
              headers = ['Date','From Date', 'To Date', 'Start WH', 'End WH','Co2', 'Total WH', 'Total Cost'];
          } else if (activeTab === "Parametric Report") {
              headers = ['Date Time', 'VR', 'VY', 'VB', 'VRY', 'VYB', 'VBR','IR', 'IY', 'IB', 'KVA', 'KW', 'PF', 'KWH'];
          }else if (activeTab === "Monthly Report") {
            headers = ['Year', 'Month', 'Start WH', 'End WH','Co2', 'Total WH', 'Total Cost'];
        }
  
         
          const headerRow = worksheet.addRow(headers);
          headerRow.font = { bold: true };
          
          
          headerRow.eachCell((cell, colIndex) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' }; 
            if (activeTab === "Parametric Report" && colIndex >= 1 && colIndex <= 14) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFA500' }, 
                };
            } else if (activeTab === "Daily Report" && colIndex >= 1 && colIndex <= 8) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFA500' }, 
                };
            } else if (colIndex >= 1 && colIndex <= 7) { 
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFA500' }, 
                };
            }
        });
        
          
  
         
          data.forEach((row, rowIndex) => {
            const rowData =
                activeTab === "Hourly Report"
                    ? [
                        row.date,
                        row.timeInterval,
                        row.startKwh,
                        row.endKwh,
                        row.totalKwh.toFixed(2),
                        row.totalCost.toFixed(2)
                      ]
                    : activeTab === "Daily Report"
                    ? [
                        row.date,
                        row.fromDate,
                        row.toDate,
                        row.startKwh,
                        row.endKwh,
                        row.totalCo2.toFixed(2),
                        row.totalKwh.toFixed(2),
                        row.totalCost.toFixed(2)
                      ]
                    : activeTab === "Monthly Report"
                    ? [
                        row.month.split('-')[0],
                        row.month,
                        row.startKwh,
                        row.endKwh,
                        row.totalCo2.toFixed(2),
                        row.totalKwh.toFixed(2),
                        row.totalCost.toFixed(2)
                      ]
                    : [
                        row.datetime,
                        row.VR,
                        row.VY,
                        row.VB,
                        row.VRY,
                        row.VYB,
                        row.VBR,
                        row.IR,
                        row.IY,
                        row.IB,
                        row.KVA,
                        row.KW,
                        row.PF,
                        row.KWH
                      ];

                      
        
        
            const dataRow = worksheet.addRow(rowData);
        
            dataRow.eachCell((cell, colIndex) => {
                cell.alignment = { horizontal: 'center', vertical: 'middle' };
                let fillColor;
                if (activeTab === "Parametric Report" && colIndex >= 1 && colIndex <= 14) {
                    fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
                } else if (colIndex >= 1 && colIndex <= 7) {
                    fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
                } else if (colIndex === 8) { // Additional condition for column 7
                    fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
                }
                
                if (fillColor) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: fillColor }, 
                    };
                }
            });
        });
        
        if (activeTab !== "Parametric Report") {
            const totals = 
                activeTab === "Parametric Report" ? calculateLogTotals(data) : 
                calculateTotals(data);
        
            const totalsRow = worksheet.addRow(
                activeTab === "Parametric Report"
                    ? ['TOTAL', '', '', '', '', '', '', '', '', '', '', '', ' ', totals.totalKwh.toFixed(2)]
                    : activeTab === "Daily Report"
                        ? ['','', 'TOTAL','','','', totals.totalKwh.toFixed(2),totals.totalCost ? totals.totalCost.toFixed(2) : '']
                        : ['', '', 'TOTAL','','', totals.totalKwh.toFixed(2), totals.totalCost ? totals.totalCost.toFixed(2) : '']
            );
        
            if (activeTab === "Parametric Report") {
                worksheet.getColumn(14).width = Math.max(10, totals.totalKwh.toFixed(2).length + 5);
            } else if (activeTab === "Hourly Report") {
                worksheet.getColumn(3).width = Math.max(10, totals.totalKwh.toFixed(2).length + 5);
                if (totals.totalCost) {
                    worksheet.getColumn(6).width = Math.max(10, totals.totalCost.toFixed(2).length + 5);
                }
            } else {
                worksheet.getColumn(7).width = Math.max(10, totals.totalCost ? totals.totalCost.toFixed(2).length + 5 : 10);
            }
        
            totalsRow.font = { bold: true };
        
            totalsRow.eachCell((cell, colIndex) => {
                const rangeLimit = 
                    activeTab === "Parametric Report" ? 14 : 
                    (activeTab === "Hourly Report" || activeTab === "Monthly Report") ? 7 :
                    activeTab === "Daily Report" ? 8 : 7; 
            
                
                if (colIndex >= 1 && colIndex <= rangeLimit) {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFFFEB3B' },
                    };
                    cell.alignment = { horizontal: 'right' };
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' },
                    };
                }
            });
        } 
      }
      const footerRow = worksheet.addRow(['Powered by Innospace']);
      footerRow.font = { bold: true, size: 12 };
      footerRow.alignment = { horizontal: 'center', vertical: 'middle' };
      
      
      const footerCellRange = 
    activeTab === "Parametric Report" ? 14 : 
    (activeTab === "Hourly Report" || activeTab === "Monthly Report") ? 7 :
    activeTab === "Daily Report" ? 8 : 7; 

  
  worksheet.mergeCells(footerRow.number, 1, footerRow.number, footerCellRange);
  
  for (let i = 1; i <= footerCellRange; i++) {
      footerRow.getCell(i).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFDDDDDD' },
      };
  }
  
  
    const meterId = selectedMeter || selecteddailyMeter || selectedlogMeter || selectedhistMeter;
    const date = selectedreportDate || fromDate || toDate || new Date().toISOString().split('T')[0]; 
    const reportName = activeTab.replace(/\s+/g, '_'); 
    const fileName = `${reportName}_${meterId}_${date}.xlsx`;
  
      workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/octet-stream' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
      });
  };
  const handleExportExcel = (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Peak Timing Report');

   
    worksheet.getColumn(1).width = 30;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 30;

    const date = selectedreportDate || new Date().toISOString().split('T')[0];
    const dateRow = worksheet.addRow([`Report Date: ${date}`]);
    worksheet.mergeCells(dateRow.number, 1, dateRow.number, 6);
    dateRow.font = { bold: true, size: 14 };
    dateRow.alignment = { horizontal: 'center', vertical: 'middle' };

    [1, 2, 3,4,5,6].forEach((col) => {
        worksheet.getCell(dateRow.number, col).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD9EAD3' }, 
        };
    });
    const metersId = meterId || searchpeakMeter || selectedMeter || selecteddailyMeter || selectedlogMeter || selectedhistMeter;
    const groupName = getGroupName(metersId); 
    const branchRow = worksheet.addRow(['Branch: Coimbatore', ' ', ' ',`Place of Installation: ${groupName}`]);
    branchRow.font = { bold: true, size: 12 };
    branchRow.getCell(1).alignment = { horizontal: 'left' }; 
    branchRow.getCell(3).alignment = { horizontal: 'center' }; 
[1, 2, 3, 4, 5, 6].forEach((col) => {
    const cell = worksheet.getCell(branchRow.number, col);
    cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFD9D9D9' },
    };
});

    // Add title row
    const titleRow = worksheet.addRow(['Peak Timing Report']);
    worksheet.mergeCells(titleRow.number, 1, titleRow.number, 6);
    titleRow.font = { bold: true, size: 16 };
    titleRow.alignment = { horizontal: 'center', vertical: 'middle' };

    [1, 2, 3,4,5,6].forEach((col) => {
        worksheet.getCell(titleRow.number, col).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FF1E90FF' }, 
        };
    });

    const headers = ['S.No', 'Timing', 'Rate', 'Today\'s Wh', 'Total Cost', 'Total Co2'];
    const headerRow = worksheet.addRow(headers);
    headerRow.font = { bold: true };
    worksheet.getColumn(1).width = 30;  
    worksheet.getColumn(2).width = 20;  
    worksheet.getColumn(3).width = 10;  
    worksheet.getColumn(4).width = 30;  
    worksheet.getColumn(5).width = 15;  
    worksheet.getColumn(6).width = 15;  
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFA500' }, 
      };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
    });
    if (data.length > 0) {
        data.forEach((row, index) => {
          const rowData = [index + 1, row.timing, row.rate, row.todaysWh, row.totalCost, row.totalCo2];
          const dataRow = worksheet.addRow(rowData);
  
          dataRow.eachCell((cell, colIndex) => {
            const fillColor = index % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: fillColor },
            };
            cell.alignment = { horizontal: 'center', vertical: 'middle' }; 
            if (colIndex === 4 || colIndex === 5 || colIndex === 6) { 
                cell.numFmt = '0.0000000000'; 
            }
          });
        });
      } else {
        worksheet.addRow(['No data available']).font = { italic: true };
      }
        const footerRow = worksheet.addRow(['Powered by Innospace']);
        footerRow.font = { bold: true, size: 12 };
        footerRow.alignment = { horizontal: 'center', vertical: 'middle' };

      
        worksheet.mergeCells(footerRow.number, 1, footerRow.number, 6);


        for (let i = 1; i <= 6; i++) {
            footerRow.getCell(i).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFDDDDDD' }, 
            };
        }

        worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });

      
      const meterIds = selectedMeter || selecteddailyMeter || selectedlogMeter || selectedhistMeter;
      const reportName = 'Peak_Timing_Report';
      const fileName = `${reportName}_${meterIds}_${selectedreportDate}.xlsx`;
  
      
      workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      });
    };



    const calculateTotals = (data) => {
        const totalKwh = data.reduce((total, item) => total + item.totalKwh, 0);
        const totalCost = data.reduce((total, item) => total + item.totalCost, 0);
        return { totalKwh, totalCost };
    };
  
  const calculateLogTotals = (data) => {
      const totalKwh = data.reduce((sum, row) => sum + row.KWH, 0);
      return { totalKwh };
  };

  const colorMapping = {
    R: "red",
    Y: "yellow",
    B: "blue",
    RY: "red",
    YB: "yellow",
    BR: "blue",
    Avg: "green",
    Tot: "orange",
};

const getColorForParameter = (key) => {
    const suffix = key.split("_").pop();
    return colorMapping[suffix] || "gray";
};

const chartsssData = {
    labels: chartssData.map((item) => item.time), 
    datasets: Object.keys(chartssData[0] || {})
      .filter((key) => key !== "time" && key !== "selected_day" && key !== "meter_id" && key !== "parameter") 
      .map((key) => ({
        label: `${key}`,
        data: chartssData.map((item) => item[key]),
        borderColor: getColorForParameter(key),
        borderWidth: 1,
        pointRadius: 0,
        fill: false,
      })),
  };
  
  const chartsssOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
        labels: {
          filter: (legendItem) =>
            !["selected_day", "meter_id", "parameter"].includes(legendItem.text),
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        callbacks: {
          title: (tooltipItems) => {
            if (tooltipItems.length) {
              return tooltipItems[0].label;
            }
            return "";
          },
          label: (tooltipItem) => {
            const datasetLabel = tooltipItem.dataset.label || "";
            const value = tooltipItem.raw.toFixed(2);
            return `${datasetLabel}: ${value}`;
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Time",
        },
      },
      y: {
        title: {
          display: true,
          text: historicalTrend,
        },
      },
    },
  };



return (
  <div style={{ textAlign: 'center', width: '100%' }}>
    <div
      style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px',
      }}
    >
      <Header title="REPORTS" />
    </div>
    <div className="dashboard">
      <div className="main-container">
        {/* Navbar */}
        <div className="nav-bar">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={activeTab === tab ? "active" : ""}
              onClick={() => handleTabChange(tab)} 
            >
              {tab}
            </button>
          ))}
        </div>

      {/* Hourly Report Content */}
      {activeTab === "Hourly Report" && (
        <div className="hourly-report">
        <div className="card controls-card">
               <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
            <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <div
                        style={{
                            width: '95%',
                            padding: '8px',
                            borderRadius: '5px',
                            // border: '1px solid #ccc',
                            position: 'relative',
                            marginBottom:'10px'
                        }}
                    >
                    <div ref={dropdownRef}>                       
                    <div
                        className="select-dropdown"
                        onClick={() => setIsOpen(!isOpen)}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                    >
                        <span>{selectedMeter ? filteredMeters.find(meter => meter.meter_id === selectedMeter)?.meter_name : "Select Meter"}</span>
                        <span style={{ fontSize: '13px' }}><i className="fa-solid fa-angle-down"></i></span>
                    </div>

                    {isOpen && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '110%',
                                left: 0,
                                right: 0,
                                backgroundColor: 'white',
                                borderRadius: '5px',
                                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                                zIndex: 10,
                                maxHeight: '330px',
                                overflowY: 'auto',
                                border: '1px solid #000',
                            }}
                        >
                            <div
                                style={{
                                    padding: '10px',
                                    borderBottom: '1px solid #ccc',
                                }}
                            >
                                <input
                                    type="text"
                                    placeholder="Search Meter ID"
                                    value={searchMeter}
                                    onChange={(e) => setSearchMeter(e.target.value)}
                                    style={{
                                        width: '95%',
                                        padding: '8px',
                                        borderRadius: '5px',
                                        border: '1px solid #ccc',
                                    }}
                                />
                            </div>
                            {filteredMeters.length > 0 ? (
                                filteredMeters.map((meter) => (
                                    <div
                                        key={meter.meter_id} 
                                        onClick={() => handleSelect(meter.meter_id, 'Hourly Report')}
                                        style={{
                                            padding: '5px',
                                            cursor: 'pointer',
                                            textAlign: 'left',
                                            fontSize: '12px',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.backgroundColor = '#006ec5';
                                            e.target.style.color = 'white';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.backgroundColor = 'transparent';
                                            e.target.style.color = 'black';
                                        }}
                                    >
                                        {meter.meter_id} - {meter.meter_name}
                                    </div>
                                ))
                            ) : (
                                <div style={{ padding: '10px' }}>No results found</div>
                            )}
                        </div>
                    )}
                                        </div>
                    </div>
            </div>
          </div>
          </div>
            <Datetime
                                    value={hourlyfromdate ? new Date(hourlyfromdate) : null}
                                    onChange={(momentObj) => {
                                        if (momentObj.isValid()) {
                                            setHourlyfromdate(momentObj.format("YYYY-MM-DDTHH:mm"));
                                        }
                                    }}
                                    inputProps={{
                                        placeholder: "Select From Date Time",
                                        style: { borderRadius: "10px", border: "1px solid #007bff", padding: "10px", width: "80%" }
                                      }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm"
                                    closeOnSelect={false}
                                />
            
                                <Datetime
                                    value={hourlytodate ? new Date(hourlytodate) : null}
                                    onChange={(momentObj) => {
                                        if (momentObj.isValid()) {
                                            setHourlytodate(momentObj.format("YYYY-MM-DDTHH:mm"));
                                        }
                                    }}
                                    inputProps={{
                                        placeholder: "Select To Date Time",
                                        style: { borderRadius: "10px", border: "1px solid #007bff", padding: "10px", width: "80%" }
                                      }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm"
                                    closeOnSelect={false}
                                />
            <button onClick={handleGetHourlyReport}>Get Report</button>
            <button onClick={() => handleHourlyExportToExcel('Hourly_Report.xlsx')}>
                <i className="excel-icon">📊</i> Excel
            </button>
        </div>
        {hourlyloading ? (
        <div className="spinner"></div>
      ) : (
        <div className="table-container">
                  <h4 className="table-title">
                    Hourly Log View from {hourlyfromdate || "Selected from Date"} to {hourlytodate || "Selected to date"}
                  </h4>
                  <table>
                      <thead>
                      <tr>
                          <th>Date</th>
                          <th>Hour</th>
                          <th>Start WH</th>
                          <th>End WH</th>
                          <th>Co2</th>
                          <th>Total WH</th>
                          <th>Total Cost</th>
                      </tr>
                      </thead>
                      <tbody>
                      {hourlyData.length > 0 ? (
                          hourlyData.map((row, index) => (
                              <tr key={index}>
                                  <td>{row.date}</td>
                                  <td>{row.timeInterval}</td>
                                  <td>{row.startKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{row.endKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{row.totalCo2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{row.totalKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{row.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                              </tr>
                          ))
                      ) : (
                          <tr>
                              <td colSpan="7" style={{ textAlign: "center" }}>
                                  No Data Available
                              </td>
                          </tr>
                      )}
                  </tbody>
                  <tfoot>
                        <tr>
                            <td colSpan="5" style={{ textAlign: "right" }}>TOTAL</td>
                            <td>{hourlyData.reduce((total, row) => total + row.totalKwh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td>{hourlyData.reduce((total, row) => total + row.totalCost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                    </tfoot>
              </table>
          </div>
          )}
                    <div className="pagination-controls">
                        <button
                            disabled={currenthourlyPage === 1}
                            onClick={() => handlehourlyPageChange(currenthourlyPage - 1)}
                        >
                            Previous
                        </button>
                        <span> {currenthourlyPage} of {totalhourlyPages}</span>
                        <button
                            disabled={currenthourlyPage === totalhourlyPages || totalhourlyPages === 0}
                            onClick={() => handlehourlyPageChange(currenthourlyPage + 1)}
                        >
                            Next
                        </button>
                    </div>
              </div>
              )}

      {/* Daily Report Content */}
      {activeTab === "Daily Report" && (
        <div className="daily-report">
         <div className="card controls-card">
         <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
            <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <div
                        style={{
                            width: '95%',
                            padding: '8px',
                            borderRadius: '5px',
                            // border: '1px solid #ccc',
                            position: 'relative',
                            marginBottom:'10px'
                        }}
                    >
                       <div ref={dropdownRef}>
                        <div
                            className="select-dropdown"
                            onClick={() => setIsOpen(!isOpen)}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                        >
                            <span>{selecteddailyMeter ? filteredDailyMeter.find(meter => meter.meter_id === selecteddailyMeter)?.meter_name : "Select Meter"}</span>
                            <span style={{ fontSize: '13px' }}><i className="fa-solid fa-angle-down"></i></span>
                        </div>

                        {isOpen && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '110%',
                                    left: 0,
                                    right: 0,
                                    backgroundColor: 'white',
                                    borderRadius: '5px',
                                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                                    zIndex: 10,
                                    maxHeight: '330px',
                                    overflowY: 'auto',
                                    border: '1px solid #000',
                                }}
                            >
                                <div
                                    style={{
                                        padding: '10px',
                                        borderBottom: '1px solid #ccc',
                                    }}
                                >
                                    <input
                                        type="text"
                                        placeholder="Search Meter ID or Name"
                                        value={searchdailyMeter}
                                        onChange={(e) => setSearchdailyMeter(e.target.value)}
                                        style={{
                                            width: '95%',
                                            padding: '8px',
                                            borderRadius: '5px',
                                            border: '1px solid #ccc',
                                        }}
                                    />
                                </div>
                                {filteredDailyMeter.length > 0 ? (
                                    filteredDailyMeter.map((meter) => (
                                        <div
                                            key={meter.meter_id}
                                            onClick={() => handleSelect(meter.meter_id, 'Daily Report')}
                                            style={{
                                                padding: '5px',
                                                cursor: 'pointer',
                                                textAlign: 'left',
                                                fontSize: '12px',
                                            }}
                                            onMouseEnter={(e) => {
                                                e.target.style.backgroundColor = '#006ec5';
                                                e.target.style.color = 'white';
                                            }}
                                            onMouseLeave={(e) => {
                                                e.target.style.backgroundColor = 'transparent';
                                                e.target.style.color = 'black';
                                            }}
                                        >
                                            {meter.meter_id} - {meter.meter_name}
                                        </div>
                                    ))
                                ) : (
                                    <div style={{ padding: '10px', textAlign: 'center' }}>No results found</div>
                                )}
                            </div>
                        )}
                        </div>
                    </div>
            </div>
          </div>
          </div>
            <Datetime
                                    value={fromDate ? new Date(fromDate) : null}
                                    onChange={(momentObj) => {
                                        if (momentObj.isValid()) {
                                            setFromDate(momentObj.format("YYYY-MM-DDTHH:mm"));
                                        }
                                    }}
                                    inputProps={{
                                        placeholder: "Select From Date Time",
                                        style: { borderRadius: "10px", border: "1px solid #007bff", padding: "10px", width: "80%" }
                                      }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm"
                                    closeOnSelect={false}
                                />
                                <Datetime
                                    value={toDate ? new Date(toDate) : null}
                                    onChange={(momentObj) => {
                                        if (momentObj.isValid()) {
                                            setToDate(momentObj.format("YYYY-MM-DDTHH:mm"));
                                        }
                                    }}
                                    inputProps={{
                                        placeholder: "Select To Date Time",
                                        style: { borderRadius: "10px", border: "1px solid #007bff", padding: "10px", width: "80%" }
                                      }}
                                    dateFormat="YYYY-MM-DD"
                                    timeFormat="HH:mm"
                                    closeOnSelect={false}
                                />
            <button onClick={handleGetDailyReport}>Get Report</button>
            <button onClick={() => handleExportToExcel(dailyreportData)}>
              <i className="excel-icon">📊</i> Excel
            </button>
            {/* <button onClick={handlePrint}>
              <i className="print-icon">🖨️</i> Print
            </button> */}
          </div>
          {dailyloading ? (
                <div><span className="spinner"></span></div> 
                            ) : (
          <div className="table-container">
                  <h4 className="table-title"> 
                      Daily Log View from {fromDate} to {toDate}
                  </h4>
                  <table>
                      <thead>
                      <tr>
                          <th>Date</th>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>Start WH</th>
                          <th>End WH</th>
                          <th>Co2</th>
                          <th>Total WH</th>
                          {/* <th>Unit Rate</th> */}
                          <th>Total Cost</th>
                      </tr>
                      </thead>
                      <tbody>
                          {currentDailyRows.length > 0 ? (
                              currentDailyRows.map((row, index) => (
                                  <tr key={index}>
                                      <td>{row.date}</td>
                                      <td>{row.fromDate}</td>
                                      <td>{row.toDate}</td>
                                      <td>{row.startKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                       <td>{row.endKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                       <td>{row.totalCo2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{row.totalKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{row.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                  </tr>
                              ))
                          ) : (
                              <tr>
                                  <td colSpan="8" style={{ textAlign: "center" }}>
                                      No Data Available
                                  </td>
                              </tr>
                          )}
                      </tbody>
                      <tfoot>
                      <tr>
                          <td colSpan="6" style={{ textAlign: "right" }}>TOTAL</td>
                          <td>{dailyreportData.reduce((total, row) => total + row.totalKwh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                          <td>{dailyreportData.reduce((total, row) => total + row.totalCost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                      </tr>
                      </tfoot>
                  </table>
                  </div>
                )}
                <div className="pagination-controls">
                        <button
                            disabled={currentdailyreportPage === 1 || currentDailyRows.length === 0}
                            onClick={() => handledailyPageChange(currentdailyreportPage - 1)}
                        >
                            Previous
                        </button>
                        <span>  {currentdailyreportPage}   of   {totaldailyPages}  </span>
                        <button
                            disabled={currentdailyreportPage === totaldailyPages || currentDailyRows.length === 0}
                            onClick={() => handledailyPageChange(currentdailyreportPage + 1)}
                        >
                            Next
                        </button>
                    </div>
              </div>
              )}

        {activeTab === "Parametric Report" && (
        <div className="Parametric-Report">
        <div className="card controls-card">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
            <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <div
                        style={{
                            width: '95%',
                            padding: '8px',
                            borderRadius: '5px',
                            // border: '1px solid #ccc',
                            position: 'relative',
                            marginBottom:'10px'
                        }}
                    >
                                <div ref={dropdownRef}>
            <div
                className="select-dropdown"
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <span>{selectedlogMeter ? filteredParametricMeter.find(meter => meter.meter_id === selectedlogMeter)?.meter_name : "Select Meter"}</span>
                <span style={{ fontSize: '13px' }}><i className="fa-solid fa-angle-down"></i></span>
            </div>

            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '110%',
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                        zIndex: 10,
                        maxHeight: '330px',
                        overflowY: 'auto',
                        border: '1px solid #000',
                    }}
                >
                    <div
                        style={{
                            padding: '10px',
                            borderBottom: '1px solid #ccc',
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Search Meter ID or Name"
                            value={searchparameterMeter}
                            onChange={(e) => setSearchparameterMeter(e.target.value)}
                            style={{
                                width: '95%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                            }}
                        />
                    </div>
                    {filteredParametricMeter.length > 0 ? (
                        filteredParametricMeter.map((meter) => (
                            <div
                                key={meter.meter_id} // Use meter_id as the key
                                onClick={() => handleSelect(meter.meter_id, 'Parametric Report')}
                                style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    textAlign: 'left',
                                    fontSize: '12px',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#006ec5';
                                    e.target.style.color = 'white';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = 'transparent';
                                    e.target.style.color = 'black';
                                }}
                            >
                                {meter.meter_id} - {meter.meter_name} {/* Display meter_id and meter_name */}
                            </div>
                        ))
                    ) : (
                        <div style={{ padding: '10px' }}>No results found</div>
                    )}
                </div>
            )}
        </div>
        </div>
            </div>
          </div>
          </div>
          <Datetime
                                  value={selectedfromreportDate ? new Date(selectedfromreportDate) : null}
                                  onChange={(momentObj) => {
                                      if (momentObj.isValid()) {
                                          setSelectedfromreportDate(momentObj.format("YYYY-MM-DDTHH:mm"));
                                      }
                                  }}
                                  inputProps={{
                                      placeholder: "Select From Date Time",
                                      style: { borderRadius: "10px", border: "1px solid #007bff", padding: "10px", width: "80%", marginTop: '10px' }
                                    }}
                                  dateFormat="YYYY-MM-DD"
                                  timeFormat="HH:mm"
                                  closeOnSelect={false}
                              />
                              <Datetime
                                  value={selectedtoreportDate ? new Date(selectedtoreportDate) : null}
                                  onChange={(momentObj) => {
                                      if (momentObj.isValid()) {
                                          setSelectedtoreportDate(momentObj.format("YYYY-MM-DDTHH:mm"));
                                      }
                                  }}
                                  inputProps={{
                                      placeholder: "Select To Date Time",
                                      style: { borderRadius: "10px", border: "1px solid #007bff", padding: "10px", width: "80%", marginTop: '10px' }
                                    }}
                                  dateFormat="YYYY-MM-DD"
                                  timeFormat="HH:mm"
                                  closeOnSelect={false}
                              />
            <button onClick={handleGetLogReport}>Get Report</button>
            <button onClick={() => handleExportToExcel(logData)}>
              <i className="excel-icon">📊</i> Excel
          </button>
                  
            {/* <button onClick={handlePrint}>
              <i className="print-icon">🖨️</i> Print
            </button> */}
          </div>

          {parameterloading ? (
                <div><span className="spinner"></span></div> 
                            ) : (

          <div className="table-container">
                    <h4 className="table-title">
                        Meter Log View on {selectedreportDate || "Selected Date"}
                    </h4>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>DateTime</th>
                                    <th>VR</th>
                                    <th>VY</th>
                                    <th>VB</th>
                                    <th>VRY</th>
                                    <th>VYB</th>
                                    <th>VBR</th>
                                    <th>IR</th>
                                    <th>IY</th>
                                    <th>IB</th>
                                    <th>KVA</th>
                                    <th>KW</th>
                                    <th>PF</th>
                                    <th>KWH</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRows.length > 0 ? (
                                    currentRows.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.datetime}</td>
                                            <td>{row.VR.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.VY.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.VB.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.VRY.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.VYB.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.VBR.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.IR.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.IY.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.IB.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.KVA.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.KW.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.PF.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.KWH.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="14" style={{ textAlign: "center" }}>
                                            No Data Available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                            )}

                  <div className="pagination-controls">
                      <button
                          disabled={currentreportPage === 1 || currentRows.length === 0}
                          onClick={() => handlePageChange(currentreportPage - 1)}
                      >
                          Previous
                      </button>
                      <span>  {currentreportPage}   of   {totalreportPage}  </span>
                      <button
                          disabled={currentreportPage === totalreportPage || currentRows.length === 0}
                          onClick={() => handlePageChange(currentreportPage + 1)}
                      >
                          Next
                      </button>
                  </div>
              </div>
              )}

           {activeTab === "Historical Trend" && (
                  <div className="historical-trend">
                      <div className="card controls-card">
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
            <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <div
                        style={{
                            width: '95%',
                            padding: '8px',
                            borderRadius: '5px',
                            // border: '1px solid #ccc',
                            position: 'relative',
                            marginBottom:'10px'
                        }}
                    >
                       <div ref={dropdownRef}>
            <div
                className="select-dropdown"
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <span>{selectedhistMeter ? filteredHistoricalTrendMeter.find(meter => meter.meter_id === selectedhistMeter)?.meter_name : "Select Meter"}</span>
                <span style={{ fontSize: '13px' }}><i className="fa-solid fa-angle-down"></i></span>
            </div>

            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '110%',
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                        zIndex: 10,
                        maxHeight: '330px',
                        overflowY: 'auto',
                        border: '1px solid #000',
                    }}
                >
                    <div
                        style={{
                            padding: '10px',
                            borderBottom: '1px solid #ccc',
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Search Meter ID or Name"
                            value={searchhistMeter}
                            onChange={(e) => setSearchhistMeter(e.target.value)}
                            style={{
                                width: '95%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                            }}
                        />
                    </div>
                    {filteredHistoricalTrendMeter.length > 0 ? (
                        filteredHistoricalTrendMeter.map((meter) => (
                            <div
                                key={meter.meter_id} // Use meter_id as the key
                                onClick={() => handleSelect(meter.meter_id, 'Historical Trend')}
                                style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    textAlign: 'left',
                                    fontSize: '12px',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#006ec5';
                                    e.target.style.color = 'white';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = 'transparent';
                                    e.target.style.color = 'black';
                                }}
                            >
                                {meter.meter_id} - {meter.meter_name} {/* Display meter_id and meter_name */}
                            </div>
                        ))
                    ) : (
                        <div style={{ padding: '10px' }}>No results found</div>
                    )}
                </div>
            )}
        </div>
        </div>
            </div>
          </div>
          </div>
                      <select
                          value={historicalTrend}
                          onChange={handleHistoricalTrendChange}
                      >
                          <option>Select Parameters</option>
                          <option value="voltage_phase_to_phase">Voltage Phase to Phase</option>
                          <option value="voltage_phase_to_neutral">Voltage Phase to Neutral</option>
                          <option value="frequency">Frequency</option>
                          <option value="current">Current</option>
                          <option value="current_harmonics">Current Harmonics</option>
                          <option value="voltage_harmonics">Voltage Harmonics</option>
                          <option value="power_factor">PF</option>
                          <option value="KWH">KWH</option>
                          <option value="kvah">KVAH</option>
                          <option value="kw">KW</option>
                          <option value="va">KVA</option>
                          <option value="power">Power</option>
                      </select>
                      <input
                          type="date"
                          value={selectedreportDate}
                          onChange={(e) => setSelectedreportDate(e.target.value)}
                          placeholder="Select Date"
                      />
                          <button onClick={downloadChartAsPNG} disabled={loading}>
                          {loading ? (
                          <span>
                              <span className="spinner" /> 
                          </span>
                          ) : (
                          "Download"
                          )}
                      </button>
                      </div>
          
                      <div className="trend-container" ref={chartRef}>
                          <h3 className="trend-title">{historicalTrend} Trend Report</h3>
                          {histloading ? (
                                                                          <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                                                              <div className="spinner"></div>
                                                                          </div>
                                                                      ) : chartssData.length > 0 ? (
                                                                                      <Line data={chartsssData} options={chartsssOptions} />
                                                                                  ) : (
                                                                                      <p>No data available for the selected parameter or date.</p>
                                                                                  )}
                      </div>
                      </div>
                  )}

                {activeTab === "Peak Timing Report" && (
                    <div className="peak-timing-report">
                        <div className="card controls-card">
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
            <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <div
                        style={{
                            width: '95%',
                            padding: '8px',
                            borderRadius: '5px',
                            // border: '1px solid #ccc',
                            position: 'relative',
                            marginBottom:'10px'
                        }}
                    >
            <div ref={dropdownRef}>
            <div
                className="select-dropdown"
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <span>{meterId ? filteredPeakTimingMeter.find(meter => meter.meter_id === meterId)?.meter_name : "Select Meter"}</span>
                <span style={{ fontSize: '13px' }}><i className="fa-solid fa-angle-down"></i></span>
            </div>

            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '110%',
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                        zIndex: 10,
                        maxHeight: '330px',
                        overflowY: 'auto',
                        border: '1px solid #000',
                    }}
                >
                    <div
                        style={{
                            padding: '10px',
                            borderBottom: '1px solid #ccc',
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Search Meter ID or Name"
                            value={searchpeakMeter}
                            onChange={(e) => setSearchpeakMeter(e.target.value)}
                            style={{
                                width: '95%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                            }}
                        />
                    </div>
                    {filteredPeakTimingMeter.length > 0 ? (
                        filteredPeakTimingMeter.map((meter) => (
                            <div
                                key={meter.meter_id} // Use meter_id as the key
                                onClick={() => handleSelect(meter.meter_id, 'Peak Timing Report')}
                                style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    textAlign: 'left',
                                    fontSize: '12px',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#006ec5';
                                    e.target.style.color = 'white';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = 'transparent';
                                    e.target.style.color = 'black';
                                }}
                            >
                                {meter.meter_id} - {meter.meter_name} 
                            </div>
                        ))
                    ) : (
                        <div style={{ padding: '10px' }}>No results found</div>
                    )}
                </div>
                        )}
                    </div>
                    </div>
            </div>
          </div>
          </div>
                        <input
                            type="date"
                            value={selectedreportDate}
                            onChange={(e) => setSelectedreportDate(e.target.value)}
                            placeholder="Select Date"
                        />
                        <button onClick={handleGetReport}>Get Report</button>
                        <button onClick={() => handleExportExcel(peakTimingData)}>
                            <i className="excel-icon">📊</i> Excel
                        </button>
                        </div>
                        {peakloading ? (
                <div><span className="spinner"></span></div> 
                            ) : (
                        <div className="table-container">
                            <h4 className="table-title">Peak Timing Report</h4>
                            <table>
                            <thead>
                                <tr>
                                <th>S.No</th>
                                <th>Timings</th>
                                <th>Rate</th>
                                <th>Today's Wh</th>
                                <th>Total Cost</th>
                                <th>Total Co2</th>
                                </tr>
                            </thead>
                            <tbody>
                    {peakTimingData.length > 0 ? (
                    peakTimingData.map((row, index) => (
                        <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row.timing}</td>
                        <td>{row.rate}</td>
                        <td>{row.todaysWh}</td>
                        <td>{row.totalCost}</td>
                        <td>{row.totalCo2}</td>
                        </tr>
                    ))
                    ) : (
                    <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                        No Data Available
                        </td>
                    </tr>
                    )}
                </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        {activeTab === "Monthly Report" && (
            <div className="Monthly-Report">
                <div className="card controls-card">
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
            <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
                <div style={{ flex: 1 }}>
                    <div
                        style={{
                            width: '95%',
                            padding: '8px',
                            borderRadius: '5px',
                            // border: '1px solid #ccc',
                            position: 'relative',
                            marginBottom:'10px'
                        }}
                    >
<div ref={dropdownRef}>
            <div
                className="select-dropdown"
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
            >
                <span>{selectedmonMeter ? filteredMonthlyMeter.find(meter => meter.meter_id === selectedmonMeter)?.meter_name : "Select Meter"}</span>
                <span style={{ fontSize: '13px' }}><i className="fa-solid fa-angle-down"></i></span>
            </div>

            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '110%',
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                        zIndex: 10,
                        maxHeight: '330px',
                        overflowY: 'auto',
                        border: '1px solid #000',
                    }}
                >
                    <div
                        style={{
                            padding: '10px',
                            borderBottom: '1px solid #ccc',
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Search Meter ID or Name"
                            value={searchmonthMeter}
                            onChange={(e) => setSearchmonthMeter(e.target.value)}
                            style={{
                                width: '95%',
                                padding: '8px',
                                borderRadius: '5px',
                                border: '1px solid #ccc',
                            }}
                        />
                    </div>
                    {filteredMonthlyMeter.length > 0 ? (
                        filteredMonthlyMeter.map((meter) => (
                            <div
                                key={meter.meter_id} // Use meter_id as the key
                                onClick={() => handleSelect(meter.meter_id, 'Monthly Report')}
                                style={{
                                    padding: '5px',
                                    cursor: 'pointer',
                                    textAlign: 'left',
                                    fontSize: '12px',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#006ec5';
                                    e.target.style.color = 'white';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = 'transparent';
                                    e.target.style.color = 'black';
                                }}
                            >
                                {meter.meter_id} - {meter.meter_name}
                            </div>
                        ))
                    ) : (
                        <div style={{ padding: '10px' }}>No results found</div>
                    )}
                </div>
                        )}
                    </div>
                    </div>
            </div>
          </div>
          </div>
          <DatePicker
                                        selected={monthlyfromDate}
                                        onChange={handleMonthlyFromMonthChange}
                                        dateFormat="yyyy-MM"
                                        showMonthYearPicker
                                        placeholderText="Select From Month"
                                        className="custom-date-picker"
                                    />
                
                                    <DatePicker
                                        selected={monthlytoDate}
                                        onChange={handleMonthlyToMonthChange}
                                        dateFormat="yyyy-MM"
                                        showMonthYearPicker
                                        placeholderText="Select To Month"
                                        className="custom-date-picker"
                                    />
                <button onClick={handleMonthlyReport}>Get Report</button>
                <button onClick={() => handleExportToExcel(monthsData)}>
                    <i className="excel-icon">📊</i> Excel
                </button>
                </div>
                {monthloading ? (
                <div><span className="spinner"></span></div> 
                            ) : (
                                <div className="table-container">
                                <h4 className="table-title">
                                    Monthly Report from {monthlyfromDate ? monthlyfromDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' }) : "Selected from Month"} 
                                    to {monthlytoDate ? monthlytoDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' }) : "Selected to Month"}
                                </h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            {/* <th>Year</th> */}
                                            <th>Month</th>
                                            <th>Start KWh</th>
                                            <th>End KWh</th>
                                            <th>Co2</th>
                                            <th>Total KWh</th>
                                            <th>Total Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentMonthlyRows.length > 0 ? (
                                            currentMonthlyRows.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    {/* <td>{item.year}</td> */}
                                                    <td>{item.month}</td>
                                                    <td>{item.startKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td>{item.endKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td>{item.totalCo2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td>{item.totalKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td>{item.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" style={{ textAlign: "center" }}>No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="5" style={{ textAlign: "right" }}>TOTAL</td>
                                            <td>{monthsData.reduce((total, item) => total + item.totalKwh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{monthsData.reduce((total, item) => total + item.totalCost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            
                )}
                <div className="pagination-controls">
                    <button
                        disabled={currentmonthlyreportPage === 1 || currentMonthlyRows.length === 0}
                        onClick={() => handlemonthlyPageChange(currentmonthlyreportPage - 1)}
                    >
                        Previous
                    </button>
                    <span>  {currentmonthlyreportPage}   of   {totalmonthlyPages}  </span>
                    <button
                        disabled={currentmonthlyreportPage === totalmonthlyPages || currentMonthlyRows.length === 0}
                        onClick={() => handlemonthlyPageChange(currentmonthlyreportPage + 1)}
                    >
                        Next
                    </button>
                 </div>
            </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ReportCard;