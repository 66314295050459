// import React, { useEffect, useState } from 'react';
// import Header from '../components/header';
// import { useLocation } from 'react-router-dom';
// import BaseURL from '../assets/contants/BaseURL';
// import axios from 'axios';

// const ProductionSub = () => {
//   const location = useLocation();
//   const { machineId,groupName } = location.state || {};
  
//   const [machineDetails, setMachineDetails] = useState(null);
//   const [groups, setGroups] = useState([]);
//   // const [remainingMachines, setRemainingMachines] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);


//   useEffect(() => {
//     const fetchMachineDetails = async () => {
//       try {
//         const response = await axios.post(`${BaseURL}/meters/individual-machine-details/`, { machine_id: machineId });
//         setMachineDetails(response.data);
//         setGroups([{ group_name: groupName }]);
//       } catch (err) {
//         setError(err.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchMachineDetails();
//   }, [machineId,groupName]);


//   return (
//     <div className="dashboard-container">
//       <Header title="MACHINE INDIVIDUAL" />

//       <div
//         className="card-container"
//         style={{
//           border: '1px solid #ccc',
//           borderRadius: '10px',
//           padding: '20px',
//           boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
//           backgroundColor: '#fff',
//           marginTop: '50px',
//           maxWidth: '1000px',
//           marginLeft: 'auto',
//           marginRight: 'auto',
//         }}
//       >
//         {/* <h3 className="dashboard-subtitle">{groups.length > 0 ? groups[0].group_name : 'N/A'}</h3> */}
//         <div
//           className="extra-details-row"
//           style={{
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             gap: '20px',
//             width: '50%',
//             margin: '0 auto',
//           }}
//         >
//           <div
//             className="container-1"
//             style={{
//               flex: 1,
//               border: '1px solid #ccc',
//               borderRadius: '10px',
//               padding: '20px',
//               boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
//               backgroundColor: '#f9f9f9',
//             }}
//           >
//             <h4>
//               <strong>Details</strong>
//             </h4>
//             {loading ? (
//               <p>Loading...</p>
//             ) : error ? (
//               <p>Error: {error}</p>
//             ) : (
//               <table
//                 style={{
//                   width: '100%',
//                   borderCollapse: 'collapse',
//                   marginTop: '20px',
//                   fontFamily: "'Roboto', sans-serif",
//                   backgroundColor: '#fff',
//                   boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
//                   borderRadius: '10px',
//                   overflow: 'hidden',
//                 }}
//               >
//                 <tbody>
//                   {[
//                     [
//                       { label: 'Machine Name', value: machineDetails.machine_name || 'N/A' },
//                       { label: 'Machine ID', value: machineDetails.machine_id || 'N/A' },
//                     ],
//                     [
//                       {
//                         label: 'Status',
//                         value: (
//                           <div
//                             style={{
//                               display: 'flex',
//                               flexDirection: 'column',
//                               alignItems: 'center',
//                             }}
//                           >
//                             <div
//                               style={{
//                                 width: '80px',
//                                 height: '20px',
//                                 backgroundColor: machineDetails.machine_status === 1 ? '#f61612' : '#4ded4f',
//                                 borderRadius: '4px',
//                                 marginBottom: '5px',
//                               }}
//                             />
//                           </div>
//                         ),
//                       },
//                       { label: 'Group Name', value: groups.length > 0 ? groups[0].group_name : 'N/A' },
//                     ],
//                     [
//                       { label: 'Manufacture', value: machineDetails.manufacture || 'N/A' },
//                       { label: 'Last Updated', value: machineDetails.update_date_time || 'N/A' },
//                     ],
//                   ].map((row, index) => (
//                     <tr key={index} style={{ borderBottom: '1px solid #ddd' }}>
//                       {row.map((item, idx) => (
//                         <td
//                           key={idx}
//                           style={{
//                             padding: '15px',
//                             width: '50%',
//                             backgroundColor: '#fff',
//                             textAlign: 'center',
//                             verticalAlign: 'top',
//                             transition: 'background-color 0.3s ease',
//                           }}
//                         >
//                           <div
//                             style={{
//                               fontWeight: 'bold',
//                               marginBottom: '5px',
//                               fontSize: '14px',
//                               color: '#333',
//                             }}
//                           >
//                             {item.label}
//                           </div>
//                           <div
//                             style={{
//                               color: '#555',
//                               fontSize: '13px',
//                               wordWrap: 'break-word',
//                             }}
//                           >
//                             {item.value}
//                           </div>
//                         </td>
//                       ))}
//                     </tr>
//                   ))}
//                   <tr>
//                   </tr>
//                 </tbody>
//               </table>
//             )}
//           </div>
//         </div>
//         <div
//           className="meters-container"
//           style={{
//             border: "1px solid #ccc",
//             boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
//             backgroundColor: "#fff",
//             marginTop: "30px",
//             maxWidth: "1000px",
//             marginLeft: "auto",
//             marginRight: "auto",
//             marginBottom: '20px',
//             padding: "10px",
//             borderRadius: "10px",
//           }}
//         >
//           <h4><strong>Meters</strong></h4>
//           {loading ? (
//             <p>Loading meters...</p>
//           ) : error ? (
//             <p>Error: {error}</p>
//           ) : (
//             <table
//               className="meters-table"
//               style={{
//                 width: "100%",
//                 borderCollapse: "collapse",
//                 textAlign: "center",
//               }}
//             >
//               <thead>
//                 <tr>
//                   <th
//                     style={{
//                       border: "1px solid #ddd",
//                       padding: "8px",
//                       backgroundColor: "#f4f4f4",
//                     }}
//                   >
//                     S.No
//                   </th>
//                   <th
//                     style={{
//                       border: "1px solid #ddd",
//                       padding: "8px",
//                       backgroundColor: "#f4f4f4",
//                     }}
//                   >
//                     Meter ID
//                   </th>
//                   <th
//                     style={{
//                       border: "1px solid #ddd",
//                       padding: "8px",
//                       backgroundColor: "#f4f4f4",
//                     }}
//                   >
//                     Meter Name
//                   </th>
//                   <th
//                     style={{
//                       border: "1px solid #ddd",
//                       padding: "8px",
//                       backgroundColor: "#f4f4f4",
//                     }}
//                   >
//        Today's KWh
//                   </th>
//                   <th
//                     style={{
//                       border: "1px solid #ddd",
//                       padding: "8px",
//                       backgroundColor: "#f4f4f4",
//                     }}
//                   >
//              Total Cost
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {machineDetails.meters && machineDetails.meters.length > 0 ? (
//                   machineDetails.meters.map((meter, index) => (
//                     <tr key={meter.meter_id}>
//                       <td style={{ border: "1px solid #ddd", padding: "8px" }}>
//                         {index + 1} 
//                       </td>
//                       <td style={{ border: "1px solid #ddd", padding: "8px" }}>
//                         {meter.meter_id}
//                       </td>
//                       <td style={{ border: "1px solid #ddd", padding: "8px" }}>
//                         {meter.meter_name}
//                       </td>
//                       <td style={{ border: "1px solid #ddd", padding: "8px" }}>
//                       {(machineDetails.total_wh || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
//                       </td>
//                       <td style={{ border: "1px solid #ddd", padding: "8px" }}>
//                       {(machineDetails.total_cost || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
//                       </td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="5" style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
//                       No meters available
//                     </td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductionSub;

import React, { useState, useEffect } from 'react';
import '../scss/Emsmain.scss';
import Chart from 'react-apexcharts';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from '../components/header'
import BaseURL from '../assets/contants/BaseURL';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import axios from 'axios';
import ExcelJS from 'exceljs';
import { Pie } from 'react-chartjs-2';

// const monthNames = [
//     "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
// ];

const ProductionSub = () => {
  const location = useLocation();
      const { machineId, machineName } = location.state || {};
    //   const [selectedDate, setSelectedDate] = useState(new Date());
      const navigate = useNavigate();
      const [productionData, setProductionData] = useState({
        today: { total_wh: 0, total_cost: 0, total_co2: 0, poured_tons: 0, melted_tons: 0, sands: 0 },
        yesterday: { total_wh: 0, total_cost: 0, total_co2: 0, poured_tons: 0, melted_tons: 0, sands: 0 },
        current_month: { total_wh: 0, total_cost: 0, total_co2: 0, poured_tons: 0, melted_tons: 0, sands: 0 },
        last_month: { total_wh: 0, total_cost: 0, total_co2: 0, poured_tons: 0, melted_tons: 0, sands: 0 }
      });
      const [chartData, setChartData] = useState({
        categories: [],
        kWh: [],
        cost: [],
        co2: [],
        poured: [],
        melted: [],
        sand: []
      });
      const [meterData, setMeterData] = useState([]);
      const [total, setTotal] = useState({});
      const [currentPage, setCurrentPage] = useState(1);
      const [itemsPerPage] = useState(10);
      const [loading, setLoading] = useState(true);
    //   const [tableloading, settableLoading] = useState(true);
    //   const [loadingFlags, setLoadingFlags] = useState([]);
    //   const [submachines, setSubmachines] = useState([]);
    //   const [selectedNewYear, setSelectedNewYear] = useState(new Date().getFullYear());
    //   const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    //   const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    //   const [monthlyData, setMonthlyData] = useState({
    //     categories: monthNames,
    //     kWh: Array(12).fill(0),
    //     cost: Array(12).fill(0),
    //     co2: Array(12).fill(0),
    //     poured: Array(12).fill(0),
    //     melted: Array(12).fill(0),
    //     sand: Array(12).fill(0)
    // });
  
    //   const [dailyData, setDailyData] = useState({
    //     categories: [],
    //     kw: [],
    //     cost: [],
    //     co2: [],
    //     poured: [],
    //     melted: [],
    //     sand: []
    // });
    //   const dataFetched = useRef(false);

    //   const handleMonthChange = (e) => setSelectedMonth(parseInt(e.target.value));
    //   const handleYearChange = (e) => setSelectedYear(parseInt(e.target.value));
    //   const handleNewYearChange = (e) => setSelectedNewYear(parseInt(e.target.value));

useEffect(() => {
    const fetchProductionData = async () => {
        try {
            setLoading(true);
            const today = new Date().toISOString().split('T')[0];
            const response = await axios.post(`${BaseURL}meters/production-summary/`, {
                date: today,
                machine_id: machineId
            });
            setProductionData(response.data);
        } catch (error) {
            console.error("Error fetching production data:", error);
        } finally {
            setLoading(false);
        }
    };
    fetchProductionData();
}, [machineId]);

useEffect(() => {
    const fetchProductionTableData = async () => {
        try {
            const today = new Date().toISOString().split('T')[0];
            const response = await axios.post(`${BaseURL}meters/production-table/`, {
                date: today,
                machine_id: machineId
            });
            const data = response.data;
            setMeterData(data.meter_data || []);
            setTotal(data.total || {});
        } catch (error) {
            console.error("Error fetching production data:", error);
        }
    };
    fetchProductionTableData();
}, [machineId]);

    const indexOfLastMachine = currentPage * itemsPerPage;
    const indexOfFirstMachine = indexOfLastMachine - itemsPerPage;
    const currentMachines = meterData.slice(indexOfFirstMachine, indexOfLastMachine);

    const totalPages = Math.ceil(meterData.length / itemsPerPage);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // useEffect(() => {
    //     if (!machineId) return;

    //     const formattedDate = selectedDate.toISOString().split('T')[0];
    //     axios.post(`${BaseURL}meters/hourly-production-consumption/`, {
    //         date: formattedDate,
    //         machine_id: machineId
    //     })
    //     .then(response => {
    //         const data = response.data.hourly_data;
    //         const categories = Object.keys(data);
    //         const kWh = categories.map(time => data[time].wh);
    //         const cost = categories.map(time => data[time].cost);
    //         const co2 = categories.map(time => data[time].co2);
    //         const poured = categories.map(time => data[time].poured_tons);
    //         const melted = categories.map(time => data[time].melted_tons);
    //         const sand = categories.map(time => data[time].sands);

    //         setChartData({ categories, kWh, cost, co2, poured, melted, sand });
    //     })
    //     .catch(error => {
    //         console.error("Error fetching data:", error);
    //     });
    // }, [selectedDate, machineId]);

    // useEffect(() => {
    //     fetchData();
    // }, [selectedMonth, selectedYear]);

    // const fetchData = async () => {
    //     try {
    //         const response = await axios.post(`${BaseURL}meters/production-daily-consumption/`, {
    //             machine_id: machineId,
    //             month: selectedMonth,
    //             year: selectedYear
    //         });
            
    //         const responseData = response.data.Daily_data;
    //         const categories = Object.keys(responseData);
    //         const kw = categories.map(date => responseData[date].kWh || 0);
    //         const cost = categories.map(date => responseData[date].cost || 0);
    //         const co2 = categories.map(date => responseData[date].co2 || 0);
    //         const poured = categories.map(date => responseData[date].poured_tons || 0);
    //         const melted = categories.map(date => responseData[date].melted_tons || 0);
    //         const sand = categories.map(date => responseData[date].sands || 0);

    //         setDailyData({ categories, kw, cost, co2, poured, melted, sand });
    //     } catch (error) {
    //         console.error("Error fetching production data:", error);
    //     }
    // };

    // useEffect(() => {
    //     if (machineId) {
    //         fetchMonthlyData();
    //     }
    // }, [selectedNewYear, machineId]);

    // const fetchMonthlyData = async () => {
    //     try {
    //         const response = await axios.post(`${BaseURL}meters/production-monthly-consumption/`, {
    //             machine_id: machineId,
    //             year: selectedNewYear
    //         });
    //         const data = response.data.monthly_data || {};
    //         const monthNames = [
    //             "January", "February", "March", "April", "May", "June", 
    //             "July", "August", "September", "October", "November", "December"
    //         ];
    
    //         const updatedData = {
    //             categories: monthNames,
    //             kWh: monthNames.map(month => {
    //                 const monthData = data[month] || {};
    //                 return monthData.kwh ?? 0;
    //             }),
    //             cost: monthNames.map(month => {
    //                 const monthData = data[month] || {};
    //                 return monthData.cost ?? 0;
    //             }),
    //             co2: monthNames.map(month => {
    //                 const monthData = data[month] || {};
    //                 return monthData.co2 ?? 0;
    //             }),
    //             poured: monthNames.map(month => {
    //                 const monthData = data[month] || {};
    //                 return monthData.poured ?? 0;
    //             }),
    //             melted: monthNames.map(month => {
    //                 const monthData = data[month] || {};
    //                 return monthData.melted ?? 0;
    //             }),
    //             sand: monthNames.map(month => {
    //                 const monthData = data[month] || {};
    //                 return monthData.sands ?? 0;
    //             }),
    //         };
    
    //         setMonthlyData(updatedData);
    //     } catch (error) {
    //         console.error("Error fetching monthly data:", error);
    //     }
    // };

    // const calculateTotals = (data) => {
    //     const totalKwh = data.reduce((total, item) => total + item.totalKWh, 0);
    //     const totalCost = data.reduce((total, item) => total + item.totalCost, 0);
    //     return { totalKwh, totalCost };
    // };

    const calculateRowTotals = (data) => {
        const totalKwh = data.reduce((total, item) => total + item.totalKwh, 0);
        const totalCost = data.reduce((total, item) => total + item.totalCost, 0);
        const poured = data.reduce((total, item) => total + item.poured, 0);
        const melted = data.reduce((total, item) => total + item.melted, 0);
        const sands = data.reduce((total, item) => total + item.sand, 0);
        return { totalKwh, totalCost, poured, melted, sands };
    };


    const kWhValues = meterData.map((meter) => meter.wh);
    const totalKWh = total.wh || 1;

    const kWhPercentagesForPie = kWhValues.length > 0 && totalKWh > 0 
        ? kWhValues.map((kWh) => {
            const percentage = (kWh / totalKWh) * 100;
            return percentage > 0 ? percentage : 0.001;
        })
        : new Array(meterData.length).fill(0);

    const getColorForMachine = (index) => {
        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
        return colors[index % colors.length];
    };

    const pieChartData = {
        labels: meterData.map((machine) => machine.meter_name),
        datasets: [
            {
                data: kWhPercentagesForPie,
                backgroundColor: meterData.map((_, index) => getColorForMachine(index)),
            },
        ],
    };
    

useEffect(() => {
      const fetchProductionChartData = async () => {
        try {
          const response = await axios.post(`${BaseURL}meters/production-bar-chart/`, {
            machine_id: machineId,
          });
    
          const { chart_data } = response.data;
    
          const categories = chart_data.map((entry) => entry.label);
          const kWh = chart_data.map((entry) => entry.kWh);
          const cost = chart_data.map((entry) => entry.cost);
          const co2 = chart_data.map((entry) => entry.co2);
          const poured = chart_data.map((entry) => entry.poured_tons);
          const melted = chart_data.map((entry) => entry.melted_tons);
          const sand = chart_data.map((entry) => entry.sands);
    
          setChartData({
            categories,
            kWh,
            cost,
            co2,
            poured,
            melted,
            sand,
          });
        } catch (error) {
          console.error('Error fetching production data:', error);
        }
      };
      fetchProductionChartData();
}, [machineId]);



      const [activeTab, setActiveTab] = useState("Daily Report");
          const [fromDate, setFromDate] = useState("");
          const [toDate, setToDate] = useState(""); 
        //   const [monthlyfromDate, setmonthlyFromDate] = useState(null);
        //   const [monthlytoDate, setmonthlyToDate] = useState(null);
        //   const [hourlyfromdate, setHourlyfromdate] = useState("");
        //   const [hourlytodate, setHourlytodate] = useState("");
        //   const [hourlyData, setHourlyData] = useState([]);
          const [dailyreportData, setDailyreportData] = useState([]);
          const [currentdailyreportPage, setCurrentdailyreportPage] = useState(1);
        //   const [currentmonthlyreportPage, setCurrentmonthlyreportPage] = useState(1);
        //   const [rowsPerPage] = useState(10);
        //   const [currenthourlyPage, setCurrenthourlyPage] = useState(1);
        //   const [monthsData, setMonthsData] = useState([]);
        //   const [hourlyloading, setHourlyLoading] = useState(false);
          const [dailyloading, setDailyLoading] = useState(false);
        //   const [monthlyloading, setMonthlyLoading] = useState(false);
        //   const [totalhourlyPages, setTotalHourlyPages] = useState(1);
      
        //   const handleMonthlyFromMonthChange = (date) => {
        //       setmonthlyFromDate(date);
        //   };
      
        //   const handleMonthlyToMonthChange = (date) => {
        //       setmonthlyToDate(date);
        //   };

          const getFormattedDateTime = (date) => {
            const pad = (num) => String(num).padStart(2, "0");
            return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
          };
      
      const tabs = ["Daily Report"];

      const handleTabChange = (tab) => {
        setActiveTab(tab);
    
        // if (tab !== "Hourly") {
        //     setHourlyData([]);
        //     setHourlyfromdate(0);
        //     setHourlytodate(0);
        //     setCurrenthourlyPage(1);
        //     setTotalHourlyPages(1);
        // }
        if (tab !== "Daily Report") {
            setDailyreportData([]);
            setFromDate(0);
            setToDate(0);
            setCurrentdailyreportPage(1);
        }
        // if (tab !== "Monthly") {
        //     setMonthsData([]);
        //     setmonthlyFromDate(0);
        //     setmonthlyToDate(0);
        //     setCurrentmonthlyreportPage(1);
        // }
    };

    // const fetchHourlyReport = async () => {
    //     if (!hourlyfromdate || !hourlytodate) {
    //         alert("Please select both from and to dates.");
    //         return;
    //     }
    //     setHourlyLoading(true);
    //     try {
    //         const payload = {
    //             from_date: hourlyfromdate.split("T")[0],
    //             to_date: hourlytodate.split("T")[0],
    //             from_time: hourlyfromdate.split("T")[1],
    //             to_time: hourlytodate.split("T")[1],
    //             machine_id: machineId,
    //         };
    //         const response = await axios.post(`${BaseURL}meters/hourly-production-report/`,payload);
    //         const data = response.data;
    //         // console.log("Hourly" , data)
    //         const formattedData = data.time_intervals.map((time, index) => ({
    //             date: data.date[index],
    //             hour: time,
    //             startKWh: data.columns[0].data[index],
    //             endKWh: data.columns[1].data[index],
    //             totalKWh: data.columns[2].data[index],
    //             totalCost: data.columns[3].data[index],
    //             co2: data.columns[4].data[index],
    //             poured: data.production_details.total_poured_tons || 0,
    //             melted: data.production_details.total_melted_tons || 0,
    //             sand: data.production_details.total_sands || 0,
    //         }));
    //         setHourlyData(formattedData);
    //         setTotalHourlyPages(Math.ceil(formattedData.length / rowsPerPage));
    //     } catch (error) {
    //         console.error("Error fetching hourly report:", error);
    //     }
    //     setHourlyLoading(false);
    // };

    // const handlehourlyPageChange = (newPage) => {
    //     setCurrenthourlyPage(newPage);
    // };

    // const paginatedData = hourlyData.slice(
    //     (currenthourlyPage - 1) * rowsPerPage,
    //     currenthourlyPage * rowsPerPage
    // );

    const handleGetDailyReport = async () => {
        setDailyLoading(true);
        try {
            const response = await axios.post(`${BaseURL}meters/daily-production-report/`, {
                machine_id: machineId,
                start_date: fromDate,
                end_date: toDate,
            });
    
            const data = response.data;
            console.log("Daily Report:", data);

            const formattedData = data.productions.map(production => ({
                from_date: production.from_date_time,
                to_date: production.to_date_time,
                poured: production.poured_tons,
                melted: production.melted_tons,
                sand: production.sands,
                totalKwh: production.total_kwh,
                totalCost: production.total_cost,
                totalCo2: production.total_co2,
            }));
    
            setDailyreportData(formattedData);
        } catch (error) {
            console.error("Error fetching daily report:", error);
        } finally {
            setDailyLoading(false);
        }
    };
      
          const rowsdailyPerPage = 10;
      
          const totaldailyPages = Math.ceil(dailyreportData.length / rowsdailyPerPage);
      
          const indexOfdailyLastRow = currentdailyreportPage * rowsdailyPerPage;
          const indexOfdailyFirstRow = indexOfdailyLastRow - rowsdailyPerPage;
          const currentDailyRows = dailyreportData.slice(indexOfdailyFirstRow, indexOfdailyLastRow);
      
          const handledailyPageChange = (newPage) => {
              setCurrentdailyreportPage(newPage);
          };

    // const handleMonthlyReport = async () => {
    //         setMonthlyLoading(true);
    //         try {
    //             const formatDate = (date) => {
    //                 const month = date.getMonth() + 1;
    //                 const year = date.getFullYear();
    //                 return `${year}-${month < 10 ? '0' + month : month}`;
    //             };
        
    //             const formattedFromDate = formatDate(monthlyfromDate);
    //             const formattedToDate = formatDate(monthlytoDate);
        
    //             const response = await axios.post(`${BaseURL}meters/monthly-production-report/`, {
    //                 from_month_year: formattedFromDate,
    //                 to_month_year: formattedToDate,
    //                 machine_id: machineId
    //             });
        
    //             const data = response.data;
        
    //             const formattedData = data.months.map((month, index) => ({
    //                 month,
    //                 startKwh: data.columns[0].data[index],
    //                 endKwh: data.columns[1].data[index],
    //                 totalKwh: data.columns[2].data[index],
    //                 totalCost: data.columns[4].data[index],
    //                 totalCo2: data.columns[5].data[index],
    //                 poured: data.columns[6].data[index],
    //                 melted: data.columns[7].data[index],
    //                 sand: data.columns[8].data[index],
    //             }));
    
    //             setMonthsData(formattedData);
    //             setMonthlyLoading(false);
    //         } catch (error) {
    //             console.error("Error fetching monthly report:", error);
    //             setMonthlyLoading(false);
    //         }
    //     };
    
    //     const rowsmonthlyPerPage = 10;
    
    //     const totalmonthlyPages = Math.ceil(monthsData.length / rowsmonthlyPerPage);
    
    //     const indexOfmonthlyLastRow = currentmonthlyreportPage * rowsmonthlyPerPage;
    //     const indexOfmonthlyFirstRow = indexOfmonthlyLastRow - rowsmonthlyPerPage;
    //     const currentMonthlyRows = monthsData.slice(indexOfmonthlyFirstRow, indexOfmonthlyLastRow);
    
    //     const handlemonthlyPageChange = (newPage) => {
    //         setCurrentmonthlyreportPage(newPage);
    //     };

        // const handleHourlyExportToExcel = async () => {
        //     const fromDateTime = new Date(hourlyfromdate);
        //     const toDateTime = new Date(hourlytodate);
        
        //     const formattedFromDate = fromDateTime.toISOString().split('T')[0]; 
        //     const formattedFromTime = fromDateTime.toTimeString().split(' ')[0].replace(/:/g, '-'); 
        
        //     const formattedToDate = toDateTime.toISOString().split('T')[0]; 
        //     const formattedToTime = toDateTime.toTimeString().split(' ')[0].replace(/:/g, '-');
        
        //     const fileName = `Hourly_Report_${formattedFromDate}_${formattedFromTime}_to_${formattedToDate}_${formattedToTime}.xlsx`;
        
        //     const workbook = new ExcelJS.Workbook();
        //     const worksheet = workbook.addWorksheet('Report');

        //     worksheet.columns = [
        //         { width: 30 }, { width: 20 }, { width: 15 }, { width: 15 }, { width: 15 },
        //         { width: 15 }, { width: 15 }
        //     ];

        //     let dateRow = worksheet.addRow([`From Date: ${hourlyfromdate}, To Date: ${hourlytodate}`]);
        //     worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7);
        //     dateRow.font = { bold: true, size: 14 };
        //     dateRow.alignment = { horizontal: 'center', vertical: 'middle' }; 
        //     dateRow.eachCell(cell => {
        //         cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD9EAD3' } };
        //     });

        //     const branchRow = worksheet.addRow(['Branch: Coimbatore', '', `Machine ID: ${machineId}`, '', `Machine Name: ${machineName}`]);
        //     branchRow.font = { bold: true, size: 12 };
        //     branchRow.getCell(1).alignment = { horizontal: 'left' }; 
        //     branchRow.getCell(3).alignment = { horizontal: 'center' }; 
        //     worksheet.getColumn(3).width = 25;

        //     const titleRow = worksheet.addRow([`${activeTab}`]);
        //     titleRow.font = { bold: true, size: 16 };
        //     titleRow.alignment = { horizontal: 'center' };
        //     worksheet.mergeCells(titleRow.number, 1, titleRow.number, 7);
        //     titleRow.eachCell(cell => {
        //         cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF1E90FF' } };
        //     });
        
        //     const headers = ['Date', 'Hour', 'Start WH', 'End WH', 'Co2', 'Total WH', 'Total Cost'];
        //     const headerRow = worksheet.addRow(headers);
        //     headerRow.font = { bold: true };
        //     headerRow.eachCell((cell) => {
        //         cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFA500' } };
        //         cell.alignment = { horizontal: 'center', vertical: 'middle' };
        //     });
        
        //     hourlyData.forEach((row, rowIndex) => {
        //         const dataRow = worksheet.addRow([
        //             row.date, row.hour, row.startKWh, row.endKWh, row.co2,
        //             row.totalKWh, row.totalCost
        //         ]);
        
        //         const fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF';
        //         dataRow.eachCell(cell => {
        //             cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fillColor } };
        //             cell.alignment = { horizontal: 'center', vertical: 'middle' };
        //         });
        //     });
        
        //     const totals = calculateTotals(hourlyData);
        //     const totalsRow = worksheet.addRow(['', '', 'TOTAL', '', '', totals.totalKwh, totals.totalCost]);
        //     totalsRow.font = { bold: true };
        //     totalsRow.eachCell(cell => {
        //         cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFEB3B' } };
        //         cell.alignment = { horizontal: 'right' };
        //         cell.border = {
        //             top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' }
        //         };
        //     });
        
        //     workbook.xlsx.writeBuffer().then((buffer) => {
        //         const blob = new Blob([buffer], { type: 'application/octet-stream' });
        //         const url = window.URL.createObjectURL(blob);
        //         const a = document.createElement('a');
        //         a.href = url;
        //         a.download = fileName;
        //         a.click();
        //         window.URL.revokeObjectURL(url);
        //     });
        // };


        const handleExportToExcel = (data, fileName) => {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report');
        
            worksheet.getColumn(1).width = 30;
            worksheet.getColumn(2).width = 20;
            worksheet.getColumn(3).width = 15;
            worksheet.getColumn(4).width = 15;
            worksheet.getColumn(5).width = 15;
            worksheet.getColumn(6).width = 15;
            worksheet.getColumn(7).width = 15;
            worksheet.getColumn(8).width = 15;
        
            let dateRow;
        
            if (activeTab === "Daily Report") {
                dateRow = worksheet.addRow([`From Date: ${fromDate}, To Date: ${toDate}`]);
                worksheet.mergeCells(dateRow.number, 1, dateRow.number, 8);
        
                for (let i = 1; i <= 8; i++) {
                    dateRow.getCell(i).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFD9EAD3' },
                    };
                }
            }
        
            dateRow.font = { bold: true, size: 14 };
            dateRow.alignment = { horizontal: 'center', vertical: 'middle' };
        
            const branchRow = worksheet.addRow(['Branch: Coimbatore', '', `Machine ID:${machineId}`, '', `Machine Name: ${machineName}`]);
            branchRow.font = { bold: true, size: 12 };
            branchRow.getCell(1).alignment = { horizontal: 'left' };
            branchRow.getCell(3).alignment = { horizontal: 'center' };
        
            worksheet.getColumn(3).width = Math.max(worksheet.getColumn(4).width || 0, `Machine ID:${machineId}`.length + 4);
        
            const branchCellRange = 8;
            for (let i = 1; i <= branchCellRange; i++) {
                branchRow.getCell(i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFDDDDDD' },
                };
            }
        
            const titleRow = worksheet.addRow([`${activeTab}`]);
            titleRow.font = { bold: true, size: 16 };
            titleRow.alignment = { horizontal: 'center' };
        
            worksheet.mergeCells(titleRow.number, 1, titleRow.number, branchCellRange);
        
            for (let i = 1; i <= branchCellRange; i++) {
                titleRow.getCell(i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF1E90FF' },
                };
            }
        
            if (data.length > 0) {
                let headers;
                if (activeTab === "Daily Report") {
                    headers = ['From Date','To Date', 'Co2', 'Total WH', 'Total Cost', 'Poured', 'Melted', 'Sand'];
                }
        
                const headerRow = worksheet.addRow(headers);
                headerRow.font = { bold: true };
        
                headerRow.eachCell((cell, colIndex) => {
                    cell.alignment = { horizontal: 'center', vertical: 'middle' };
                    if (activeTab === "Daily Report" && colIndex >= 1 && colIndex <= 8) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFA500' },
                        };
                    }
                });
        
                data.forEach((row, rowIndex) => {
                    const rowData =
                        activeTab === "Daily Report"
                            ? [
                                row.from_date,
                                row.to_date,
                                row.totalCo2,
                                row.totalKwh,
                                row.totalCost,
                                row.poured,
                                row.melted,
                                row.sand,
                            ]
                            : [];
        
                    const dataRow = worksheet.addRow(rowData);
                    dataRow.height = 20;
        
                    dataRow.eachCell((cell, colIndex) => {
                        let fillColor;
                        if (activeTab === "Daily Report" && colIndex >= 1 && colIndex <= 8) {
                            fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF';
                        }
        
                        if (fillColor) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: fillColor },
                            };
                            cell.alignment = { horizontal: 'center', vertical: 'middle' };
                        }
                    });
                });
        
                if (activeTab === "Daily Report") {
                    const totals = calculateRowTotals(data);
                    const totalsRow = worksheet.addRow(
                        ['','', 'TOTAL',totals.totalKwh.toFixed(2), totals.totalCost.toFixed(2), totals.poured.toFixed(2), totals.melted.toFixed(2), totals.sands.toFixed(2)]
                    );
        
                    totalsRow.font = { bold: true };
                    totalsRow.eachCell((cell, colIndex) => {
                        if (colIndex >= 1 && colIndex <= 8) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFEB3B' },
                            };
                            cell.alignment = { horizontal: 'right' };
                            cell.border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' },
                            };
                        }
                    });
                }
            } else {
                worksheet.addRow(['No data available']).font = { italic: true };
            }
        
            const footerRow = worksheet.addRow(['Powered by Innospace']);
            footerRow.font = { bold: true, size: 12 };
            footerRow.alignment = { horizontal: 'center', vertical: 'middle' };
        
            const footerCellRange = 8;
        
            worksheet.mergeCells(footerRow.number, 1, footerRow.number, footerCellRange);
        
            for (let i = 1; i <= footerCellRange; i++) {
                footerRow.getCell(i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFDDDDDD' },
                };
            }
        
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            });
        }; 
        
        // const handleMonthlyExportToExcel = (data, fileName) => {
        //     const workbook = new ExcelJS.Workbook();
        //     const worksheet = workbook.addWorksheet('Monthly Report');
        
        //     // Set column widths
        //     worksheet.getColumn(1).width = 10; // S.No
        //     worksheet.getColumn(2).width = 20; // Month
        //     worksheet.getColumn(3).width = 15; // Start KWh
        //     worksheet.getColumn(4).width = 15; // End KWh
        //     worksheet.getColumn(5).width = 15; // Co2
        //     worksheet.getColumn(6).width = 15; // Total KWh
        //     worksheet.getColumn(7).width = 15; // Total Cost
        //     worksheet.getColumn(8).width = 15; // Poured
        //     worksheet.getColumn(9).width = 15; // Melted
        //     worksheet.getColumn(10).width = 15; // Sand
        
        //     // Add title row
        //     const titleRow = worksheet.addRow(['Monthly Report']);
        //     titleRow.font = { bold: true, size: 16 };
        //     titleRow.alignment = { horizontal: 'center' };
        //     worksheet.mergeCells(titleRow.number, 1, titleRow.number, 10);
        
        //     // Add date range row
        //     const dateRangeRow = worksheet.addRow([
        //         `From: ${monthlyfromDate ? monthlyfromDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' }) : "Selected from Month"}`,
        //         `To: ${monthlytoDate ? monthlytoDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' }) : "Selected to Month"}`,
        //     ]);
        //     dateRangeRow.font = { bold: true, size: 14 };
        //     worksheet.mergeCells(dateRangeRow.number, 1, dateRangeRow.number, 10);
        
        //     // Add headers
        //     const headers = [
        //         'S.No',
        //         'Month',
        //         'Start KWh',
        //         'End KWh',
        //         'Co2',
        //         'Total KWh',
        //         'Total Cost',
        //         'Poured',
        //         'Melted',
        //         'Sand',
        //     ];
        //     const headerRow = worksheet.addRow(headers);
        //     headerRow.font = { bold: true };
        //     headerRow.eachCell((cell) => {
        //         cell.alignment = { horizontal: 'center', vertical: 'middle' };
        //         cell.fill = {
        //             type: 'pattern',
        //             pattern: 'solid',
        //             fgColor: { argb: 'FFFFA500' }, // Orange background
        //         };
        //     });
        
        //     // Add data rows
        //     data.forEach((item, index) => {
        //         const rowData = [
        //             index + 1, // S.No
        //             item.month, // Month
        //             item.startKwh.toFixed(2), // Start KWh
        //             item.endKwh.toFixed(2), // End KWh
        //             item.totalCo2.toFixed(2), // Co2
        //             item.totalKwh.toFixed(2), // Total KWh
        //             item.totalCost.toFixed(2), // Total Cost
        //             item.poured.toFixed(2), // Poured
        //             item.melted.toFixed(2), // Melted
        //             item.sand.toFixed(2), // Sand
        //         ];
        //         const dataRow = worksheet.addRow(rowData);
        //         dataRow.eachCell((cell) => {
        //             cell.alignment = { horizontal: 'center', vertical: 'middle' };
        //             cell.fill = {
        //                 type: 'pattern',
        //                 pattern: 'solid',
        //                 fgColor: { argb: index % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF' }, // Alternate row colors
        //             };
        //         });
        //     });
        
        //     // Add totals row
        //     const totalsRow = worksheet.addRow([
        //         '', '', '', '', 'TOTAL',
        //         data.reduce((sum, item) => sum + item.totalKwh, 0).toFixed(2), // Total KWh
        //         data.reduce((sum, item) => sum + item.totalCost, 0).toFixed(2), // Total Cost
        //         data.reduce((sum, item) => sum + item.poured, 0).toFixed(2), // Poured
        //         data.reduce((sum, item) => sum + item.melted, 0).toFixed(2), // Melted
        //         data.reduce((sum, item) => sum + item.sand, 0).toFixed(2), // Sand
        //     ]);
        //     totalsRow.font = { bold: true };
        //     totalsRow.eachCell((cell) => {
        //         cell.alignment = { horizontal: 'center', vertical: 'middle' };
        //         cell.fill = {
        //             type: 'pattern',
        //             pattern: 'solid',
        //             fgColor: { argb: 'FFFFEB3B' },
        //         };
        //     });

        //     const footerRow = worksheet.addRow(['Powered by Innospace']);
        //     footerRow.font = { bold: true, size: 12 };
        //     footerRow.alignment = { horizontal: 'center', vertical: 'middle' };
        //     worksheet.mergeCells(footerRow.number, 1, footerRow.number, 10);
        //     footerRow.eachCell((cell) => {
        //         cell.fill = {
        //             type: 'pattern',
        //             pattern: 'solid',
        //             fgColor: { argb: 'FFDDDDDD' },
        //         };
        //     });

        //     workbook.xlsx.writeBuffer().then((buffer) => {
        //         const blob = new Blob([buffer], { type: 'application/octet-stream' });
        //         const url = window.URL.createObjectURL(blob);
        //         const a = document.createElement('a');
        //         a.href = url;
        //         a.download = fileName;
        //         a.click();
        //         window.URL.revokeObjectURL(url);
        //     });
        // };

        // const generateFilename = () => {
        //     const formattedFromDate = monthlyfromDate
        //         ? monthlyfromDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })
        //         : "FromDate";
        //     const formattedToDate = monthlytoDate
        //         ? monthlytoDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit' })
        //         : "ToDate";
            
        //     return `Monthly_Report_${formattedFromDate}_to_${formattedToDate}.xlsx`;
        // };

    return (
        <div className="dashboard-container">
           <Header title="BRADKEN PRODUCTION DASHBOARD" />

            <div className="card-container" style={{
                border: '1px solid #ccc', 
                borderRadius: '10px', 
                padding: '20px', 
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
                backgroundColor: '#fff',
                marginTop: '50px',
                maxWidth: '1000px',
                marginLeft: 'auto',
                marginRight: 'auto'
            }}>
                <h3 className="dashboard-subtitle"><span onClick={() => navigate('/EMS/dashboard')}style={{color: '#007bff',cursor: 'pointer'}}title="Navigate to Dashboard">MAIN</span> { "/" } { machineName } </h3>
                {/* <div className="widgets-container" style={{ display: 'grid',  gap: '20px', marginBottom: '20px' }}> */}
                <div className="widgets-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px', marginBottom: '20px' }}>
                    
                <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Today's</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px' }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                        <div className="spinner"></div>
                                    ) : (
                                        `${productionData.today.total_wh} KWh`
                                    )}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px' }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                        <div className="spinner"></div>
                                    ) : (`Rs ${productionData.today.total_cost}`)}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px' }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                        <div className="spinner"></div>
                                    ) : (`${productionData.today.total_co2}`)}</p>
                                </div>
                                {(productionData.last_month.sands !== 0 || 
                                    productionData.today.sands !== 0 || 
                                    productionData.yesterday.sands !== 0 || 
                                    productionData.current_month.sands !== 0) ? (
                                    <div style={{ display: 'table-row' }}>
                                        <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Sands</p> 
                                        <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                        <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.today.sands}`)}</p>
                                    </div>
                                ) : (
                                    <>
                                        <div style={{ display: 'table-row' }}>
                                            <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Poured</p> 
                                            <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                            <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}> {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.today.poured_tons}`)}</p>
                                        </div>
                                        <div style={{ display: 'table-row' }}>
                                            <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Melted</p> 
                                            <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                            <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}> {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.today.melted_tons}`)}</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #4caf50, #2b9e36)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Yesterday</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.yesterday.total_wh} KWh`)}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `Rs ${productionData.yesterday.total_cost}`)}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.yesterday.total_co2}`)}</p>
                                </div>
                                {(productionData.last_month.sands !== 0 || 
                                    productionData.today.sands !== 0 || 
                                    productionData.yesterday.sands !== 0 || 
                                    productionData.current_month.sands !== 0) ? (
                                        <div style={{ display: 'table-row' }}>
                                        <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Sands</p> 
                                        <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                        <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}> {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.yesterday.sands}`)}</p>
                                    </div>
                                ) : (
                                    <>
                                        <div style={{ display: 'table-row' }}>
                                            <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Poured</p> 
                                            <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                            <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}> {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.yesterday.poured_tons}`)}</p>
                                        </div>
                                        <div style={{ display: 'table-row' }}>
                                            <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Melted</p> 
                                            <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                            <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.yesterday.melted_tons}`)}</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #2196f3, #0b7dda)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Current Month</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.current_month.total_wh} KWh`)}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `Rs ${productionData.current_month.total_cost}`)}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.current_month.total_co2}`)}</p>
                                </div>
                                {(productionData.last_month.sands !== 0 || 
                                    productionData.today.sands !== 0 || 
                                    productionData.yesterday.sands !== 0 || 
                                    productionData.current_month.sands !== 0) ? (
                                        <div style={{ display: 'table-row' }}>
                                        <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Sands</p> 
                                        <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                        <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}> {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.current_month.sands}`)}</p>
                                    </div>
                                ) : (
                                    <>
                                        <div style={{ display: 'table-row' }}>
                                            <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Poured</p> 
                                            <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                            <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}> {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.current_month.poured_tons}`)}</p>
                                        </div>
                                        <div style={{ display: 'table-row' }}>
                                            <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Melted</p> 
                                            <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                            <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>  {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.current_month.melted_tons}`)}</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #f44336, #d32f2f)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Last Month</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}> {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.last_month.total_wh} KWh`)}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `Rs ${productionData.last_month.total_cost}`)}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.last_month.total_co2}`)}</p>
                                </div>
                                {(productionData.last_month.sands !== 0 || 
                                    productionData.today.sands !== 0 || 
                                    productionData.yesterday.sands !== 0 || 
                                    productionData.current_month.sands !== 0) ? (
                                        <div style={{ display: 'table-row' }}>
                                        <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Sands</p> 
                                        <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                        <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}> {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.last_month.sands}`)}</p>
                                    </div>
                                ) : (
                                    <>
                                        <div style={{ display: 'table-row' }}>
                                            <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Poured</p> 
                                            <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                            <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}> {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.last_month.poured_tons}`)}</p>
                                        </div>
                                        <div style={{ display: 'table-row' }}>
                                            <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '5px'  }}>Melted</p> 
                                            <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                            <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}> {loading ? (
                                            <div className="spinner"></div>
                                        ) : ( `${productionData.last_month.melted_tons}`)}</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
        <div
            className="card-container"
            style={{
                border: "1px solid #ccc",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                marginTop: "30px",
                maxWidth: "1000px",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: '20px',
                padding: "10px",
                borderRadius: "10px",
            }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginBottom: '20px',
                }}>
                <div style={{
                                    width: '300px',
                                    height: '300px',
                                    marginBottom: '20px',
                                }}>
                                    <Pie
                                        data={pieChartData}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                    position: 'bottom',
                                                },
                                                tooltip: {
                                                    callbacks: {
                                                        label: function (tooltipItem) {
                                                            const value = tooltipItem.raw.toFixed(2);
                                                            return `${tooltipItem.label}: ${value}%`;
                                                        },
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            </div>
            <table
                className="sub-meters-table"
                style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "center",
                }}
            >
                <thead>
                <tr>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    S.NO
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Meter Name
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Meter ID
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Today's KWh
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Total Cost
                    </th>
                    <th style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                        }}
                    >
                    Unit %
                    </th>
                </tr>
                </thead>
                <tbody>
                {currentMachines.map((meter, index) => (
                    <tr
                    key={meter.meter_id}
                    >
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                       {index + 1 + (currentPage - 1) * itemsPerPage}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                        {meter.meter_name}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                       {meter.meter_id}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                        }}
                    >
                        {meter.wh}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                        }}
                    >
                       Rs {meter.cost} 
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                        <div
                            style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: getColorForMachine(index),
                            borderRadius: '50%',
                            display: 'inline-block',
                            marginRight: '8px',
                            }}
                        ></div>
                        <span>{kWhPercentagesForPie[index].toFixed(2)}%</span>
                    </td>
                    </tr>
                    ))}
                </tbody>
                <tfoot>
                <tr>
                    <td
                    colSpan="3"
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                    }}
                    >
                    Total
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                        textAlign: "right",
                    }}
                    >
                    {total.wh}
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                        textAlign: "right",
                    }}
                    >
                    Rs {total.cost}
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                        textAlign: "right",
                    }}
                    >
                    </td>
                </tr>
                </tfoot>
            </table>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                style={{
                    marginRight: '10px',
                    padding: '5px 10px',
                    background: currentPage === 1 ? '#007acc' : '#007acc',
                    border: 'none',
                    borderRadius: '25px',
                    color: 'white',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    boxShadow: currentPage === 1 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    fontSize: '12px',
                }}
                >
                Previous
                </button>

                {Array.from({ length: totalPages }, (_, i) => (
                <button
                    key={i}
                    onClick={() => paginate(i + 1)}
                    style={{
                    margin: '0 5px',
                    padding: '5px 10px',
                    background: currentPage === i + 1 ? '#007acc' : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
                    color: currentPage === i + 1 ? '#ffffff' : '#333333',
                    border: '1px solid #ddd',
                    borderRadius: '25px',
                    cursor: 'pointer',
                    fontSize: '12px',
                    transition: 'background-color 0.3s ease',
                    }}
                >
                    {i + 1}
                </button>
                ))}

                <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                style={{
                    marginLeft: '10px',
                    padding: '5px 10px',
                    background: currentPage === totalPages ? '#007acc' : '#007acc',
                    border: 'none',
                    borderRadius: '25px',
                    color: 'white',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    boxShadow: currentPage === totalPages ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    fontSize: '12px',
                }}
                >
                Next
                </button>
            </div>
            </div>
            <div
                    className="extra-details-row"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '20px',
                    }}
                >
                </div>
                <div className="charts-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f0f3',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>Production Data</h4>
                <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            height: 350,
                            backgroundColor: '#f9f0f3',
                            toolbar:{
                                tools:{ download:false
                            },
                        },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '50%',
                                endingShape: 'rounded',
                            },
                        },
                        colors: ['#1a27db', '#1ff4ad', '#ffc107', '#ff7e7e', '#fffa63', '#63fff3'],
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: chartData.categories,
                            title: {
                                text: 'Time of Day',
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'Value',
                            },
                            labels: {
                                formatter: (value) => {
                                    return new Intl.NumberFormat('en-US').format(value);
                                },
                            },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            offsetX: 0,
                            offsetY: 0,
                        },
                    }}
                    series={[
                        {
                            name: 'KWh',
                            data: chartData.kWh,
                        },
                        {
                            name: 'Cost',
                            data: chartData.cost,
                        },
                        {
                            name: 'CO2',
                            data: chartData.co2,
                        },
                        {
                            name: 'Pourd',
                            data: chartData.poured,
                        },
                        {
                            name: 'Melted',
                            data: chartData.melted,
                        },
                        {
                            name: 'Sands',
                            data: chartData.sand,
                        },
                    ]}
                    type="bar"
                    height={350}
                    width={950}
                />
            </div>
        </div>
       {/* <div
                   className="charts-container"
                   style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}
               >
                   <div
                       className="container-4"
                       style={{
                           border: '1px solid #ccc',
                           borderRadius: '10px',
                           padding: '20px',
                           boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                           backgroundColor: '#fbe9f6',
                           marginTop: '20px',
                           display: 'flex',
                           flexDirection: 'column',
                           alignItems: 'center',
                           justifyContent: 'center',
                           width: '100%',
                       }}
                   >
                       <h4>Day's Consumption</h4>
                       <div
                       style={{
                           display: "flex",
                           flexDirection: "row",
                           alignItems: "center",
                           marginBottom: "30px",
                       }}
                   >
                       <label
                           style={{
                               fontSize: "14px",
                               color: "#333",
                               fontWeight: "bold",
                               marginRight: "10px",
                           }}
                       >
                           Month:
                       </label>
                       <select
                           value={selectedMonth}
                           onChange={handleMonthChange}
                           style={{
                               padding: "5px",
                               marginRight: "20px",
                               borderRadius: "5px",
                               border: "1px solid #ccc",
                           }}
                       >
                           {monthNames.map((month, index) => (
                               <option key={index + 1} value={index + 1}>
                                   {month}
                               </option>
                           ))}
                       </select>
       
                       <label
                           style={{
                               fontSize: "14px",
                               color: "#333",
                               fontWeight: "bold",
                               marginRight: "10px",
                           }}
                       >
                           Year:
                       </label>
                       <select
                           value={selectedYear}
                           onChange={handleYearChange}
                           style={{
                               padding: "5px",
                               borderRadius: "5px",
                               border: "1px solid #ccc",
                           }}
                       >
                           {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                               <option key={year} value={year}>
                                   {year}
                               </option>
                           ))}
                       </select>
                   </div>
                   <Chart
                           options={{
                               chart: {
                                   type: 'bar',
                                   height: 350,
                                   backgroundColor: '#fbe9f6',
                                   toolbar: {
                                       tools: {
                                           zoom: false,
                                           zoomin: false,
                                           zoomout: false,
                                           pan: false,
                                           reset: false,
                                           download: false,
                                       },
                                   },
                                   zoom: {
                                       enabled: true,
                                   },
                               },
                               plotOptions: {
                                   bar: {
                                       horizontal: false,
                                       columnWidth: '50%',
                                       endingShape: 'rounded',
                                   },
                               },
                               colors: ['#ff47f0', '#3743e6', '#00ced1', '#ff7e7e', '#fffa63', '#63fff3'],
                               dataLabels: {
                                   enabled: false,
                               },
                               xaxis: {
                                   categories: dailyData.categories,
                                   title: {
                                       text: 'Dates',
                                   },
                               },
                               yaxis: {
                                   title: {
                                       text: 'Value',
                                   },
                                   labels: {
                                       formatter: (value) => {
                                           return new Intl.NumberFormat('en-US').format(value);
                                       },
                                   },
                               },
                               legend: {
                                   position: 'top',
                                   horizontalAlign: 'center',
                                   offsetX: 0,
                                   offsetY: 0,
                               },
                               tooltip: {
                                   y: {
                                       formatter: function (value, { seriesIndex }) {
                                           if (seriesIndex === 1) {
                                               return `Rs ${value.toLocaleString()}`;
                                           }
                                           return value.toLocaleString();
                                       },
                                   },
                               },
                           }}
                           series={[
                               {
                                   name: 'KWh',
                                   data: dailyData.kw,
                               },
                               {
                                   name: 'Cost',
                                   data: dailyData.cost,
                               },
                               {
                                   name: 'CO2',
                                   data: dailyData.co2,
                               },
                               {
                                    name: 'Poured',
                                    data: dailyData.poured,
                                },
                                {
                                    name: 'Melted',
                                    data: dailyData.melted,
                                },
                                {
                                    name: 'Sands',
                                    data: dailyData.sand,
                                },
                           ]}
                           type="bar"
                           height={350}
                           width={950}
                       />
                   </div>
               </div>
        <div className="charts-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f0fbff',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>Monthly Consumption</h4>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "30px",
                    }}
                >
                    <label
                        style={{
                            fontSize: "14px",
                            color: "#333",
                            fontWeight: "bold",
                            marginRight: "10px",
                        }}
                    >
                        Year:
                    </label>
                    <select
                        value={selectedNewYear}
                        onChange={handleNewYearChange}
                        style={{
                            padding: "5px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                        }}
                    >
                        {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            height: 350,
                            backgroundColor: '#f0fbff',
                            toolbar: {
                                tools: {
                                    zoom: false,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    download: false,
                                },
                            },
                            zoom: {
                                enabled: true,
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '50%',
                                endingShape: 'rounded',
                            },
                        },
                        colors: ['#a71606', '#2ced7f', '#1e90ff', '#ff7e7e', '#fffa63', '#63fff3'],
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: monthlyData.categories,
                            title: {
                                text: 'Months',
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'Value',
                            },
                            labels: {
                                formatter: (value) => {
                                    return new Intl.NumberFormat('en-US').format(value);
                                },
                            },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            offsetX: 0,
                            offsetY: 0,
                        },
                        tooltip: {
                            y: {
                                formatter: function (value, { seriesIndex }) {
                                    if (seriesIndex === 1) {
                                        return `Rs ${value.toLocaleString()}`;
                                    }
                                    return value.toLocaleString();
                                },
                            },
                        },
                    }}
                    series={[
                      {
                          name: 'KWh',
                          data: monthlyData.kWh,
                      },
                      {
                          name: 'Cost',
                          data: monthlyData.cost,
                      },
                      {
                          name: 'CO2',
                          data: monthlyData.co2,
                      },
                      {
                            name: 'Poured',
                            data: monthlyData.poured,
                        },
                        {
                            name: 'Melted',
                            data: monthlyData.melted,
                        },
                        {
                            name: 'Sands',
                            data: monthlyData.sand,
                        },
                    ]}
                    type="bar"
                    height={350}
                    width={950}
                />
            </div>
        </div> */}

      <div className="dashboard">
              <div className="main-container">
              <div className="nav-bar">
                {tabs.map((tab) => (
                  <button
                    key={tab}
                    className={activeTab === tab ? "active" : ""}
                    onClick={() => handleTabChange(tab)} 
                  >
                    {tab}
                  </button>
                ))}
              </div>
                      {/* {activeTab === "Hourly" && (
                      <div className="hourly-report">
                          <div className="card controls-card">
                          <input
                              type="datetime-local"
                              value={hourlyfromdate}
                              onChange={(e) => setHourlyfromdate(e.target.value)}
                              placeholder="Select From Date"
                              max={new Date().toISOString().slice(0, 16)}
                          />
                          <input
                              type="datetime-local"
                              value={hourlytodate}
                              onChange={(e) => setHourlytodate(e.target.value)}
                              placeholder="Select To Date"
                              max={new Date().toISOString().slice(0, 16)}
                          />
                          <button onClick={fetchHourlyReport}>Get Report</button>
                          <button onClick={() => handleHourlyExportToExcel('Hourly_Report.xlsx')}>
                              <i className="excel-icon">📊</i> Excel
                          </button>
                          </div>
      
                          {hourlyloading ? (
                              <span className="spinner" />
                          ) : (
                              <div className="table-container">
                                  <h4 className="table-title">
                                      Hourly Log View from {hourlyfromdate || "Selected from Date"} to {hourlytodate || "Selected to date"}
                                  </h4>
                                  <table>
                                      <thead>
                                          <tr>
                                              <th>Date</th>
                                              <th>Hour</th>
                                              <th>Start KWh</th>
                                              <th>End KWh</th>
                                              <th>Co2</th>
                                              <th>Total KWh</th>
                                              <th>Total Cost</th>
                                              <th>Poured</th>
                                              <th>Melted</th>
                                              <th>Sand</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                      {paginatedData.length > 0 ? (
                                        paginatedData.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.date}</td>
                                                <td>{row.hour}</td>
                                                <td>{row.startKWh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{row.endKWh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{row.co2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{row.totalKWh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{row.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{row.poured.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{row.melted.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{row.sand.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="7" style={{ textAlign: "center" }}>No Data Available</td>
                                        </tr>
                                    )}
                                      </tbody>
                                      <tfoot>
                                          <tr>
                                              <td colSpan="5" style={{ textAlign: "right" }}>TOTAL</td>
                                              <td>{hourlyData.reduce((acc, row) => acc + row.totalKWh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{hourlyData.reduce((acc, row) => acc + row.totalCost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{hourlyData.reduce((acc, row) => acc + row.poured, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{hourlyData.reduce((acc, row) => acc + row.melted, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{hourlyData.reduce((acc, row) => acc + row.sand, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                          </tr>
                                      </tfoot>
                                  </table>
                              </div>
                          )}
      
                        <div className="pagination-controls">
                            <button
                                disabled={currenthourlyPage === 1}
                                onClick={() => handlehourlyPageChange(currenthourlyPage - 1)}
                            >
                                Previous
                            </button>
                            <span> {currenthourlyPage} of {totalhourlyPages}</span>
                            <button
                                disabled={currenthourlyPage === totalhourlyPages}
                                onClick={() => handlehourlyPageChange(currenthourlyPage + 1)}
                            >
                                Next
                            </button>
                        </div>
                      </div>
                      )} */}
                      {activeTab === "Daily Report" && (
                      <div className="daily-report-production">
                          <div className="card controls-card">
                          <input
                              type="date"
                              value={fromDate}
                              onChange={(e) => setFromDate(e.target.value)}
                              placeholder="From Date"
                              max={getFormattedDateTime(new Date())}
                          />
                          <input
                              type="date"
                              value={toDate}
                              onChange={(e) => setToDate(e.target.value)}
                              placeholder="To Date"
                              max={getFormattedDateTime(new Date())}
                          />
                          <button onClick={handleGetDailyReport}>Get Report</button>
                          <button
                          onClick={() => {
                            const fileName = `Daily_Report_${fromDate}_to_${toDate}.xlsx`;
                            handleExportToExcel(dailyreportData, fileName);
                            }} 
                          >
                              <i className="excel-icon">📊</i> Excel
                          </button>
                          </div>
                          {dailyloading ? (
                              <span className="spinner" />
                          ) : (
                          <div className="table-container">
                          <h4 className="table-title">
                              Daily Log View from {fromDate} to {toDate}
                          </h4>
                          <table>
                              <thead>
                              <tr>
                                  <th>From Date</th>
                                  <th>To Date</th>
                                  <th>Co2</th>
                                  <th>Total KWH</th>
                                  {/* <th>Unit Rate</th> */}
                                  <th>Total Cost</th>
                                  <th>Poured</th>
                                  <th>Melted</th>
                                  <th>Sand</th>
                              </tr>
                              </thead>
                              <tbody>
                              {currentDailyRows.length > 0 ? (
                                currentDailyRows.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.from_date}</td>
                                        <td>{row.to_date}</td>
                                        <td>{row.totalCo2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{row.totalKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{row.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{row.poured.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{row.melted.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{row.sand.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    ))
                                ) : (
                                    <tr>
                                    <td colSpan="11" style={{ textAlign: "center" }}>
                                        No Data Available
                                    </td>
                                    </tr>
                                )}
                              </tbody>
                              <tfoot>
                              <tr>
                                  <td colSpan="3" style={{ textAlign: "right" }}>TOTAL</td>
                                    <td>{dailyreportData.reduce((sum, row) => sum + row.totalKwh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{dailyreportData.reduce((sum, row) => sum + row.totalCost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{dailyreportData.reduce((sum, row) => sum + row.poured, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{dailyreportData.reduce((sum, row) => sum + row.melted, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{dailyreportData.reduce((sum, row) => sum + row.sand, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                              </tr>
                              </tfoot>
                          </table>
                          </div>
                          )}
                          <div className="pagination-controls">
                            <button
                                disabled={currentdailyreportPage === 1 || currentDailyRows.length === 0}
                                onClick={() => handledailyPageChange(currentdailyreportPage - 1)}
                            >
                                Previous
                            </button>
                            <span>  {currentdailyreportPage}   of   {totaldailyPages}  </span>
                            <button
                                disabled={currentdailyreportPage === totaldailyPages || currentDailyRows.length === 0}
                                onClick={() => handledailyPageChange(currentdailyreportPage + 1)}
                            >
                                Next
                            </button>
                        </div>
                      </div>
                      )}
                  {/* {activeTab === "Monthly" && (
                  <div className="Monthly-Report">
                      <div className="card controls-card">
                          <DatePicker
                              selected={monthlyfromDate}
                              onChange={handleMonthlyFromMonthChange}
                              dateFormat="yyyy-MM"
                              maxDate={new Date()}
                              showMonthYearPicker
                              placeholderText="Select From Month"
                              className="custom-date-picker"
                          />
      
                          <DatePicker
                              selected={monthlytoDate}
                              onChange={handleMonthlyToMonthChange}
                              dateFormat="yyyy-MM"
                              maxDate={new Date()}
                              showMonthYearPicker
                              placeholderText="Select To Month"
                              className="custom-date-picker"
                          />
                          <button onClick={handleMonthlyReport}>Get Report</button>
                          <button 
                          onClick={() => handleMonthlyExportToExcel(monthsData, generateFilename())}
                                  >
                              <i className="excel-icon">📊</i> Excel
                          </button>
                      </div>
                      {monthlyloading ? (
                          <div>
                              <span className="spinner"></span>
                          </div>
                      ) : (
                          <div className="table-container">
                              <h4 className="table-title">Monthly Report from {monthlyfromDate ? monthlyfromDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' }) : "Selected from Month"} to {monthlytoDate ? monthlytoDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' }) : "Selected to Month"}</h4>
                              <table>
                                  <thead>
                                      <tr>
                                          <th>S.No</th>
                                          <th>Month</th>
                                          <th>Start KWh</th>
                                          <th>End KWh</th>
                                          <th>Co2</th>
                                          <th>Total KWh</th>
                                          <th>Total Cost</th>
                                          <th>Poured</th>
                                          <th>Melted</th>
                                          <th>Sand</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                  {currentMonthlyRows.length > 0 ? (
                                        currentMonthlyRows.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.month}</td>
                                        <td>{item.startKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{item.endKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{item.totalCo2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{item.totalKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{item.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{item.poured.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{item.melted.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{item.sand.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                    ))
                                ) : (
                                    <tr>
                                    <td colSpan="10" style={{ textAlign: "center" }}>No data available</td>
                                    </tr>
                                )}
                                  </tbody>
                                  <tfoot>
                                  <tr>
                                      <td colSpan="5" style={{ textAlign: "right" }}>TOTAL</td>
                                      <td>
                                        {monthsData.reduce((sum, item) => sum + item.totalKwh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </td>
                                        <td>
                                        {monthsData.reduce((sum, item) => sum + item.totalCost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </td>
                                        <td>
                                        {monthsData.reduce((sum, item) => sum + item.poured, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </td>
                                        <td>
                                        {monthsData.reduce((sum, item) => sum + item.melted, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </td>
                                        <td>
                                        {monthsData.reduce((sum, item) => sum + item.sand, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                        </td>
                                  </tr>
                              </tfoot>
                              </table>
                          </div>
                      )}
                        <div className="pagination-controls">
                            <button
                                disabled={currentmonthlyreportPage === 1 || currentMonthlyRows.length === 0}
                                onClick={() => handlemonthlyPageChange(currentmonthlyreportPage - 1)}
                            >
                                Previous
                            </button>
                            <span>  {currentmonthlyreportPage}   of   {totalmonthlyPages}  </span>
                            <button
                                disabled={currentmonthlyreportPage === totalmonthlyPages || currentMonthlyRows.length === 0}
                                onClick={() => handlemonthlyPageChange(currentmonthlyreportPage + 1)}
                            >
                                Next
                            </button>
                        </div>
                  </div>
                  )} */}
              </div>
            </div>
    </div>
  </div>
    );
};

export default ProductionSub;
