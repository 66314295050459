import React from 'react';
import '../scss/Appfooter.scss';

const AppFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div>
        EMS <span>&copy; {currentYear}</span>
      </div>
    </footer>
  );
};

export default React.memo(AppFooter);
