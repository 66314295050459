import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/dashboard.scss';
import Header from '../components/header';
import axios from 'axios';
import BaseURL from '../assets/contants/BaseURL';
import Spinner from '../components/Spinner';

const Groupportal = () => {
    const [loading, setLoading] = useState(true);
    const [machines, setMachines] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMachines = async () => {
            setLoading(true);
            try {
                const today = new Date().toISOString().split('T')[0];
                const response = await axios.post(`${BaseURL}/meters/machine-details-data/`, { date: today }); 
                setMachines(response.data);
                console.log(response.data)
            } catch (error) {
                console.error('Error fetching machines:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchMachines(); 
    }, []);

    const handleMachineWidgetClick = (machineId, machineName) => {
        navigate('/EMS/productionSub', { state: { machineId, machineName } }); 
        console.log(machineId, machineName);
    };

    const renderMachineWidgets = () => {
        return (
            <div style={{ marginBottom: '20px' }}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px',
                        justifyContent: 'center',
                    }}
                >
                    {machines.map((machine) => {
                        const widgetColor = machine.group_color === '#FFFFFF' ? '#0093ff' : machine.group_color;
    
                        return (
                            <div
                                key={machine.machine_id}
                                onClick={() => handleMachineWidgetClick(machine.machine_id, machine.machine_name)}
                                style={{
                                    width: '400px',
                                    height: '120px',
                                    background: widgetColor,
                                    borderRadius: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '20px',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                    textAlign: 'center',
                                }}
                                className="widget"
                            >
                                <h4
                                    style={{
                                        margin: '5px 0',
                                        color: '#fff',
                                        textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {machine.machine_name}
                                </h4>
                                <div style={{ display: 'table', width: '100%' }}>
                                    <div style={{ display: 'table-row' }}>
                                        <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', fontSize: '12px' }}>Today KWh</p> 
                                        <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                        <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right', fontSize: '12px' }}>{(machine.total_wh || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </div>
                                    <div style={{ display: 'table-row' }}>
                                        <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', fontSize: '12px' }}>Today Cost</p> 
                                        <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                        <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right', fontSize: '12px' }}>Rs {(machine.total_cost || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </div>
                                    {machine.production.sands !== 0 ? (
                                        <div style={{ display: 'table-row' }}>
                                            <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', fontSize: '12px' }}>Today Sands</p> 
                                            <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                            <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right', fontSize: '12px' }}>{(machine.production.sands || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                        </div>
                                    ) : (
                                        <>
                                            <div style={{ display: 'table-row' }}>
                                                <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', fontSize: '12px' }}>Today Poured Tons</p> 
                                                <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                                <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right', fontSize: '12px' }}>{(machine.production.poured_tons || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                            </div>
                                            <div style={{ display: 'table-row' }}>
                                                <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', fontSize: '12px' }}>Today Melted Tons</p> 
                                                <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p> 
                                                <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right', fontSize: '12px' }}>{(machine.production.melted_tons || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };


    return (
        <div className="dashboard" style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
            <Header title="PRODUCTION PORTAL" />
            <div className="content-container" style={{ padding: '20px' }}>
                <div className="card" style={{ background: '#fff', borderRadius: '10px', marginTop: '20px' }}>
                    <h3 className="dashboard-subtitle">EnPI Equipments</h3>
                    <div className="machine-card">
                        {loading ? <Spinner /> : renderMachineWidgets()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Groupportal;
