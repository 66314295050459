import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Header from '../components/header';
import "react-datetime/css/react-datetime.css";
import BaseURL from '../assets/contants/BaseURL';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSearch } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import '../scss/Proreport.scss';

const Productionreport = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [machineGroups, setMachineGroups] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const [selectedFormat, setSelectedFormat] = useState("pdf");
  const [productionData, setProductionData] = useState([]);

  const inputStyle = {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    fontSize: '14px',
    color: '#333',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'border-color 0.3s ease',
    width: '150px',
  };

  useEffect(() => {
    const fetchMachineGroups = async () => {
      try {
        const response = await axios.get(`${BaseURL}meters/machine/`);
        setMachineGroups(response.data);
        setFilteredMachines(response.data);
      } catch (error) {
        console.error("Error fetching machine groups:", error);
      }
    };
    fetchMachineGroups();
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);

    if (query.trim() === "") {
        setFilteredMachines(machineGroups);
        return;
    }

    const filtered = machineGroups.filter((machine) => {
        return machine.machine_id && machine.machine_id.toLowerCase().includes(query.toLowerCase());
    });

    setFilteredMachines(filtered);
};

const handleSelect = (machine) => {
  setSelectedMachine(machine.machine_id);
  setIsOpen(false);
};

  const fetchProductionReport = async () => {
    if (!fromDate || !toDate || selectedMachine === "Select Machine") {
      alert("Please select a valid date range and machine group.");
      return;
    }

    try {
      const response = await axios.post(`${BaseURL}meters/daily-production-report/`, {
        start_date: fromDate,
        end_date: toDate,
        machine_id: selectedMachine,
      });
      setProductionData(response.data.productions);
    } catch (error) {
      console.error("Error fetching production report:", error);
    }
  };


  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Production Report", 14, 10);
  
    const tableColumn = [
      "S.No",
      "From Date",
      "To Date",
      "Total KWh",
      "Total Cost",
      "Total CO2",
      "Poured Tons",
      "Melted Tons",
      "Sands"
    ];
  
    const tableRows = [];
  
    productionData.forEach((production, index) => {
      const row = [
        index + 1,
        production.from_date_time,
        production.to_date_time,
        production.total_kwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        production.total_cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        production.total_co2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        production.poured_tons.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        production.melted_tons.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        production.sands.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      ];
      tableRows.push(row);
    });
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });
  
    doc.save(`production_report_${selectedMachine}.pdf`);
  };
  
  const generateCSV = () => {
    let csvContent = "S.No,From Date,To Date,Total KWh,Total Cost,Total CO2,Poured Tons,Melted Tons,Sands\n";
  
    productionData.forEach((production, index) => {
      const row = [
        index + 1,
        production.from_date_time,
        production.to_date_time,
        production.total_kwh.toFixed(2),
        production.total_cost.toFixed(2),
        production.total_co2.toFixed(2),
        production.poured_tons.toFixed(2),
        production.melted_tons.toFixed(2),
        production.sands.toFixed(2)
      ];
      csvContent += row.join(",") + "\n";
    });
  
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `production_report_${selectedMachine}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  
  const handleDownload = () => {
    if (!fromDate || !toDate || selectedMachine === "Select Machine Group") {
      alert("Please select a valid date range and machine group.");
      return;
    }
  
    if (selectedFormat === "pdf") {
      generatePDF();
    } else {
      generateCSV();
    }
  };

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px'
      }}>
        <Header title="PRODUCTION REPORTS" />
      </div>
      <div style={{
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        maxWidth: '1000px',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '8px'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <label style={{ fontWeight: 'bold' }}>From Date:</label>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              style={inputStyle}
              max={new Date().toISOString().slice(0, 16)}
            />

            <label style={{ fontWeight: 'bold' }}>To Date:</label>
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              style={inputStyle}
              max={new Date().toISOString().slice(0, 16)}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "-10px" }}>
            <div style={{ display: "flex", gap: "20px", width: "100%" }}>
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    width: "90%",
                    padding: "6px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    boxShadow: '0 2px 4px rgba(0, 0, 0 , 0.1)',
                    position: "relative",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    onClick={() => setIsOpen(!isOpen)}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      width: "160px",
                      fontSize: "12px",
                      padding: "5px",
                    }}
                  >
                    <span style={{ flex: 1, textAlign: "left" }}>{selectedMachine || "Select Machine"}</span>
                    <span style={{ fontSize: "10px" }}>
                      <i className="fa-solid fa-angle-down"></i>
                    </span>
                  </div>

                  {isOpen && (
                    <div
                      ref={dropdownRef}
                      style={{
                        position: "absolute",
                        top: "110%",
                        left: 0,
                        right: 0,
                        backgroundColor: "white",
                        borderRadius: "5px",
                        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                        zIndex: 10,
                        maxHeight: "330px",
                        overflowY: "auto",
                        border: "1px solid #000",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px",
                          borderBottom: "1px solid #ccc",
                        }}
                      >
                        <input
                          type="text"
                          placeholder="Search Machine Group"
                          value={searchQuery}
                          onChange={(e) => handleSearch(e.target.value)}
                          style={{
                            width: "90%",
                            padding: "8px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                          }}
                        />
                      </div>

                      {filteredMachines.length > 0 ? (
                                filteredMachines.map((machine) => (
                                    <div
                                        key={machine.id}
                                        style={{
                                            padding: "5px",
                                            cursor: "pointer",
                                            textAlign: "left",
                                            fontSize: "12px",
                                        }}
                                        onMouseEnter={(e) => {
                                            e.target.style.backgroundColor = "#006ec5";
                                            e.target.style.color = "white";
                                        }}
                                        onMouseLeave={(e) => {
                                            e.target.style.backgroundColor = "transparent";
                                            e.target.style.color = "black";
                                        }}
                                        onClick={() => handleSelect(machine)}
                                    >
                                        {machine.machine_id}
                                    </div>
                                ))
                            ) : (
                                <div style={{ padding: "10px" }}>No results found</div>
                            )}
                          </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={fetchProductionReport}
            style={{
              padding: '8px 12px',
              backgroundColor: '#007acc',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '8px',
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>

          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <button
              onClick={handleDownload}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 12px',
                backgroundColor: '#007acc',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                borderRadius: '8px',
                marginRight: '20px',
              }}
            >
              <span>Download</span>
              <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '5px' }} />
            </button>

            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <select
              value={selectedFormat}
              onChange={(e) => setSelectedFormat(e.target.value)}
              style={{ padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
            >
              <option value="pdf">PDF</option>
              <option value="csv">CSV</option>
            </select>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
            border: '1px solid #ccc',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            marginTop: '30px',
            maxWidth: '1000px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '20px',
            padding: '20px',
            borderRadius: '10px',
        }}
    >
        <table
            style={{
                width: '100%',
                borderCollapse: 'collapse',
                textAlign: 'center',
                fontFamily: 'Arial, sans-serif',
            }}
        >
            <thead>
                <tr>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', fontWeight: '600', color: '#333' }}>S.NO</th>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', fontWeight: '600', color: '#333' }}>From Date</th>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', fontWeight: '600', color: '#333' }}>To Date</th>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', fontWeight: '600', color: '#333' }}>Total Co2</th>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', fontWeight: '600', color: '#333' }}>Total KWh</th>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', fontWeight: '600', color: '#333' }}>Total Cost</th>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', fontWeight: '600', color: '#333' }}>Poured Tons</th>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', fontWeight: '600', color: '#333' }}>Melted Tons</th>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f4f4f4', fontWeight: '600', color: '#333' }}>Sands</th>
                </tr>
            </thead>
            <tbody>
                {productionData.length > 0 ? (
                    productionData.map((production, index) => (
                        <tr key={index} style={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                            <td style={{ border: '1px solid #ddd', padding: '12px', color: '#555' }}>{index + 1}</td>
                            <td style={{ border: '1px solid #ddd', padding: '12px', color: '#555' }}>{production.from_date_time}</td>
                            <td style={{ border: '1px solid #ddd', padding: '12px', color: '#555' }}>{production.to_date_time}</td>
                            <td style={{ border: '1px solid #ddd', padding: '12px', color: '#555' }}>{production.total_co2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td style={{ border: '1px solid #ddd', padding: '12px', color: '#555' }}>{production.total_kwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td style={{ border: '1px solid #ddd', padding: '12px', color: '#555' }}>{production.total_cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td style={{ border: '1px solid #ddd', padding: '12px', color: '#555' }}>{production.poured_tons.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td style={{ border: '1px solid #ddd', padding: '12px', color: '#555' }}>{production.melted_tons.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td style={{ border: '1px solid #ddd', padding: '12px', color: '#555' }}>{production.sands.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="9" style={{ textAlign: 'center', padding: '20px', color: '#777' }}>
                            No Data Available
                        </td>
                    </tr>
                )}
            </tbody>
            <tfoot>
                <tr style={{ backgroundColor: '#f4f4f4', fontWeight: '600' }}>
                    <td colSpan="3" style={{ textAlign: 'right', padding: '12px', border: '1px solid #ddd', color: '#333' }}>TOTAL</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px', color: '#333' }}>
                        {productionData.reduce((sum, production) => sum + production.total_co2, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '12px', color: '#333' }}>
                        {productionData.reduce((sum, production) => sum + production.total_kwh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '12px', color: '#333' }}>
                        {productionData.reduce((sum, production) => sum + production.total_cost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '12px', color: '#333' }}>
                        {productionData.reduce((sum, production) => sum + production.poured_tons, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '12px', color: '#333' }}>
                        {productionData.reduce((sum, production) => sum + production.melted_tons, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td style={{ border: '1px solid #ddd', padding: '12px', color: '#333' }}>
                        {productionData.reduce((sum, production) => sum + production.sands, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    </div>
  );
};

export default Productionreport;