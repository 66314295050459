import React, { useState, useEffect,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faPlus,faEdit  } from '@fortawesome/free-solid-svg-icons';
import BaseURL from '../assets/contants/BaseURL';
import axios from 'axios';
import Header from '../components/header';
import { ChromePicker } from 'react-color';

const EnergyGroups = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // const [searchMeter, setSearchMeter] = useState('');
  const [checkedItems, setCheckedItems] = useState({});
  const [newGroup, setNewGroup] = useState({ group_name: '', meters: [] });
  const [fullEnergyGroups, setFullEnergyGroups] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [energyGroups, setEnergyGroups] = useState([]);
  const [meters, setMeters] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);
  const [editGroup, setEditGroup] = useState({ group_name: '', meters: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredMeters, setFilteredMeters] = useState([]);
  const [filteredaddMeters, setFilteredAddMeters] = useState([]);
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const itemsPerPage = 10;
  const modalRef = useRef();
  const editModalRef = useRef();
  const deleteModalRef=useRef();
  const [metersearchTerm, setMeterSearchTerm] = useState('');
  const [meteraddsearchTerm, setMeterAddSearchTerm] = useState('');

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModalOpen(false);
      setMeterAddSearchTerm('');
    }
    if (editModalRef.current && !editModalRef.current.contains(event.target)) {
      setEditModalOpen(false);
      setMeterSearchTerm('');
    }
    if (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
      setIsDeleteOpenModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchEnergyGroups = async () => {
      try {
        const response = await axios.get(`${BaseURL}meters/emsgroups/`);
        setFullEnergyGroups(response.data);
        setEnergyGroups(response.data);

        const metersResponse = await axios.get(`${BaseURL}meters/energymeters/`);
        setMeters(metersResponse.data);
        setFilteredMeters(response.data);
        setFilteredAddMeters(response.data);
      } catch (error) {
        console.error("Error fetching energy groups:", error);
      }
    };

    fetchEnergyGroups();
  }, []);

  useEffect(() => {
    const filtered = meters.filter(meter =>
      meter.meter_name.toLowerCase().includes(metersearchTerm.toLowerCase()) ||
      meter.meter_id.toLowerCase().includes(metersearchTerm.toLowerCase())
    );
    setFilteredMeters(filtered);
  }, [metersearchTerm, meters]);

  useEffect(() => {
    const filtered = meters.filter(meter =>
      meter.meter_name.toLowerCase().includes(meteraddsearchTerm.toLowerCase()) ||
      meter.meter_id.toLowerCase().includes(meteraddsearchTerm.toLowerCase())
    );
    setFilteredAddMeters(filtered);
  }, [meteraddsearchTerm, meters]);

  const handleSearch = (term) => {
    const searchTerm = term.trim().toLowerCase();
    setCurrentPage(1); 
    if (searchTerm === "") {
      setEnergyGroups(fullEnergyGroups);
    } else {
      const results = fullEnergyGroups.filter(item =>
        item.group_name.toLowerCase().includes(searchTerm)||
        item.meter.some(meter => meter.meter_name.toLowerCase().includes(searchTerm))
      );
      setEnergyGroups(results.length > 0 ? results : []);
    }
    setCheckedItems({});
  };


  // const handleMeterSearch = (e) => {
  //   const value = e.target.value;
  //   setSearchMeter(value);

  //   // Filter meters based on search input
  //   const filtered = meters.filter(meter =>
  //     meter.meter_id.toLowerCase().includes(value.toLowerCase()) ||
  //     meter.meter_name.toLowerCase().includes(value.toLowerCase())
  //   );
  //   setFilteredMeters(filtered);
  // };


  // const handleMeterChange = (e) => {
  //   const selectedMeterId = e.target.value;
  //   setEditGroup(prevState => ({
  //     ...prevState,
  //     meters: [...prevState.meters, selectedMeterId]
  //   }));
  // };

  const handleCheckboxChange = (groupId) => {
    setCheckedItems((prev) => ({
      ...prev,
      [groupId]: !prev[groupId],
    }));
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newCheckedItems = {};
    energyGroups.forEach(item => {
      newCheckedItems[item.group_id] = isChecked; 
    });
    setCheckedItems(newCheckedItems);
  };
  const handleAddGroup = async () => {
    if (!newGroup.group_name || newGroup.meters.length === 0) {
      alert("Please fill in the group name and select meters.");
      return;
    }

    try {
      const response = await axios.post(`${BaseURL}meters/emsgroups/`, {
        group_name: newGroup.group_name,
        meter_list: newGroup.meters,
        color: newGroup.color,
      });

      setEnergyGroups([...energyGroups, response.data]);
      setNewGroup({ group_name: '', meters: [], color: ''  });
      setModalOpen(false);
      setSuccessMessage('Group added successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);


      const updatedGroupsResponse = await axios.get(`${BaseURL}meters/emsgroups/`);
      setEnergyGroups(updatedGroupsResponse.data);
    } catch (error) {
      console.error("Error adding new group:", error);
    }
  };

  const handleDeleteGroups = async () => {
    const idsToDelete = Object.keys(checkedItems).filter(groupId => checkedItems[groupId]);

    try {
      for (let groupId of idsToDelete) {
        await axios.delete(`${BaseURL}meters/emsgroups/${groupId}/`);
        console.log(`Group with ID ${groupId} deleted.`);
      }

      const updatedGroups = energyGroups.filter(item => !checkedItems[item.group_id]);
      setEnergyGroups(updatedGroups);
      setCheckedItems({});
      setSuccessMessage('Selected Groups deleted successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error("Error deleting groups:", error);
    } finally {
      setIsDeleteOpenModal(false); 
    }
  };

const handleEdit = (group) => {
  setEditGroup({
    group_name: group.group_name,
    meters: Array.isArray(group.meter) ? group.meter.map(meter => meter.id) : [], 
    group_id: group.group_id ,
    color : group.color
  });
  setEditModalOpen(true);
};
const handleUpdateGroup = async () => {
  try {
    const response = await axios.put(`${BaseURL}meters/emsgroups/${editGroup.group_id}/`, {
      group_name: editGroup.group_name,
      meter_list: editGroup.meters,
      color: editGroup.color
    });

   
    const updatedGroups = energyGroups.map(item => 
      item.group_id === editGroup.group_id ? response.data : item
    );
    setEnergyGroups(updatedGroups);
    setEditModalOpen(false);
    setSuccessMessage('Group updated successfully!');
    setTimeout(() => setSuccessMessage(''), 3000);
  } catch (error) {
    console.error("Error updating group:", error);
  }
};

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = energyGroups.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(energyGroups.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleRemoveMeter = (meterId) => {
    setNewGroup((prevGroup) => ({
      ...prevGroup,
      meters: prevGroup.meters.filter((id) => id !== meterId),
    }));
  };

  const handleRemoveEditMeter = (meterId) => {
    setEditGroup((prevGroup) => ({
      ...prevGroup,
      meters: prevGroup.meters.filter((id) => id !== meterId),
    }));
  };
  
  const meterMap = meters.reduce((acc, meter) => {
    acc[meter.id] = meter.meter_name;
    return acc;
  }, {});

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px'
      }}>
        <Header title="EMS GROUPS" />
      </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px',
        boxShadow: '0 2px 8px rgba(0, 0,  0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto',
        maxWidth: '1000px',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                padding: '8px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                flex: '1',
                marginRight: '10px',
              }}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                handleSearch(e.target.value); 
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch(searchTerm); 
                }
              }}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: '#007acc', cursor: 'pointer' }}
            />
          </div>

          <div style={{ marginLeft: 'auto' }}>
            <button
              style={{
                padding: '10px 15px',
                marginRight: '10px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => {
                const isAnyItemSelected = Object.values(checkedItems).includes(true);
                if (isAnyItemSelected) {
                  setIsDeleteOpenModal(true);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />
              Delete
            </button>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={() => setModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Add
            </button>
          </div>
        </div>
      </div>

      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        {successMessage && (
          <div style={{
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#4CAF50', 
            color: 'white',
            margin: '10px auto',
            width: 'fit-content',
          }}>
            {successMessage}
          </div>
        )}
          </div>

      <div style={{
        marginTop: '20px',
        padding: '10px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        maxWidth: '1000px',
        margin: '20px auto',
        marginBottom: '70px',
      }}>
        <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
          <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
            <tr>
              <th style={{ padding: '10px' }}>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                />
              </th>
              <th style={{ padding: '10px' }}>S.No</th>
              <th style={{ padding: '10px' }}>Group Name</th>
              <th style={{ padding: '10px' }}>Meters</th>
              <th style={{padding:'10px'}}>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((item, index) => (
                <tr
                  key={item.group_id}
                  style={{
                    backgroundColor: (indexOfFirstItem + index) % 2 === 0 ? '#f2f2f2' : '#ffffff',
                  }}
                >
                  <td style={{ padding: '10px' }}>
                    <input
                      type="checkbox"
                      checked={checkedItems[item.group_id] || false}  
            onChange={() => handleCheckboxChange(item.group_id)}  
                    />
                  </td>
                  <td style={{ padding: '10px' }}>{indexOfFirstItem + index + 1}</td>
                  <td style={{ padding: '10px'}}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                    style={{
                      width: '20px',
                      height: '20px',
                      minWidth: '20px',
                      minHeight: '20px',
                      backgroundColor: item.color && item.color !== '#FFFFFF' ? item.color : '#007acc',
                      borderRadius: '50%',
                      border: '2px solid #fff',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                      marginRight: '10px',
                      transition: 'transform 0.2s ease',
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  />
                  <span>{item.group_name || '-'}</span>
                </div>
              </td>
                  <td style={{ padding: '10px', textAlign: 'left', wordWrap: 'break-word' }}>
                  {item.meter.length > 0
                    ? item.meter.map((meter, index) => (
                        <span
                          key={index}
                          style={{
                            marginRight: index < item.meter.length - 1 ? '10px' : '0',
                            padding: '5px',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            display: 'inline-block',
                            whiteSpace: 'normal',
                            marginBottom: '5px',
                            backgroundColor: 'white'
                          }}
                        >
                          {meter.meter_name}
                        </span>
                      )).reduce((acc, curr, index) =>
                        index === 0 ? [curr] : [...acc, ', ', curr], []
                      )
                    : '-'}
                  </td>
                  <td style={{padding:'10px',color:'blue',cursor:'pointer',marginLeft:'10px'}}> <FontAwesomeIcon icon={faEdit}  onClick={() => handleEdit(item)}/></td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" style={{ padding: '10px', textAlign: 'center' }}>
                  No Groups found.
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
              margin: '0 8px',
              padding: '5px 10px',
              background: currentPage === 1 ? '#007acc' : '#007acc',
              color: currentPage === 1 ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === 1 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
              transition: 'all 0.3s ease',
            }}
            onMouseEnter={(e) => {
              if (currentPage !== 1) e.target.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.3)';
            }}
            onMouseLeave={(e) => {
              if (currentPage !== 1) e.target.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)';
            }}
          >
            Previous
          </button>

          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              style={{
                margin: '0 8px',
                padding: '5px 10px',
                background: currentPage === i + 1 ? '#007acc' : '#f7f7f7',
                color: currentPage === i + 1 ? '#ffffff' : '#333333',
                border: currentPage === i + 1 ? 'none' : '1px solid #ddd',
                borderRadius: '25px',
                cursor: 'pointer',
                fontWeight: 'bold',
                boxShadow: currentPage === i + 1 ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
                fontSize: '12px',
                transition: 'all 0.3s ease',
              }}
              onMouseEnter={(e) => {
                if (currentPage !== i + 1) e.target.style.background = 'linear-gradient(to right, #eeeeee, #f5f5f5)';
              }}
              onMouseLeave={(e) => {
                if (currentPage !== i + 1) e.target.style.background = '#f7f7f7';
              }}
            >
              {i + 1}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              margin: '0 8px',
              padding: '5px 10px',
              background: currentPage === totalPages ? '#007acc' : '#007acc',
              color: currentPage === totalPages ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === totalPages ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
              transition: 'all 0.3s ease',
            }}
            onMouseEnter={(e) => {
              if (currentPage !== totalPages) e.target.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.3)';
            }}
            onMouseLeave={(e) => {
              if (currentPage !== totalPages) e.target.style.boxShadow = '0px 4px 8px rgba(0, 0, 0, 0.2)';
            }}
          >
            Next
          </button>
        </div>
      </div>

      {isModalOpen && (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div
      ref={modalRef}
      style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        maxWidth: '530px',
        width: '100%',
        height: '480px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        overflowX : 'auto'
      }}
    >
      <h3 style={{ marginBottom: '10px' }}>Add New Group</h3>

      <label
        htmlFor="group_name"
        style={{
          marginBottom: '5px',
          display: 'block',
          fontWeight: 'bold',
          fontSize: '14px',
        }}
      >
        Group Name
      </label>
      <input
        type="text"
        placeholder="Group Name"
        value={newGroup.group_name}
        onChange={(e) =>
          setNewGroup({ ...newGroup, group_name: e.target.value })
        }
        style={{
          width: '50%',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          marginBottom: '10px',
        }}
      />

      <div
        style={{
          display: 'flex',
          gap: '20px',
          marginBottom: '20px',
          alignItems: 'flex-start',
        }}
      >
        <div style={{ flex: 1 }}>
          <label
            htmlFor="meters"
            style={{
              marginBottom: '5px',
              display: 'block',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            Meters List
          </label>
          <div
            style={{
              width: '90%',
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '8px',
              height: '230px',
              overflowY: 'auto',
            }}
          >
            <span
            style={{
              display: 'block',
              marginBottom: '10px',
            }}
          >
            <input
              type="text"
              placeholder="Search meters..."
              value={meteraddsearchTerm}
              onChange={(e) => setMeterAddSearchTerm(e.target.value)}
              style={{
                width: '90%',
                padding: '5px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                fontSize: '12px',
              }}
            />
          </span>
          {filteredaddMeters.map((meter) => {
            const isAdded = newGroup.meters.includes(meter.id);
            return (
              <div
                key={meter.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '5px',
                  backgroundColor: '#e0e0e0',
                  padding: '5px',
                  borderRadius: '5px',
                  fontSize: '12px'
                }}
              >
                <span style={{ color: isAdded ? 'gray' : 'black' }}>{meter.meter_name}</span>
                <button
                  onClick={() => {
                    if (!isAdded) {
                      setNewGroup((prev) => ({
                        ...prev,
                        meters: [...prev.meters, meter.id],
                      }));
                    }
                  }}
                  style={{
                    backgroundColor: isAdded ? 'gray' : '#4CAF50',
                    color: 'white',
                    border: 'none',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    cursor: isAdded ? 'not-allowed' : 'pointer',
                    fontSize: '10px',
                  }}
                  disabled={isAdded}
                >
                 {isAdded ? 'Added' : 'Add'}
                </button>
              </div>
              );
            })}
          </div>
        </div>
        <div style={{ flex: 1 }}>
        <div style={{ width: '100%' }}>
              <label style={{ marginBottom: '5px', display: 'block', fontWeight: 'bold' }}>
                Added Meters
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  backgroundColor: '#f9f9f9',
                  height: '225px',
                  overflowY: 'auto',
                }}
              >
                {newGroup.meters.map((meterId) => {
                const meterName = meterMap[meterId];
                return (
                  <div
                    key={meterId}
                    style={{
                      backgroundColor: '#e0e0e0',
                      marginBottom: '5px',
                      padding: '5px',
                      borderRadius: '3px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontSize: '12px',
                    }}
                  >
                    <span>{meterName}</span>
                    <button
                      onClick={() => handleRemoveMeter(meterId)}
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none',
                        padding: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '10px',
                      }}
                    >
                      Remove
                    </button>
                  </div>
                );
              })}
              </div>
            </div>
          </div>
      </div>
      <div
       style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
            <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
              Choose Group Color
            </label>
          </div>
          <div
            style={{
              marginTop: '10px',
              padding: '10px',
              background: '#f9f9f9',
              borderRadius: '8px',
              boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
              width: '500px',
              maxWidth: '50%',
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', 
            }}
          >
            <ChromePicker
              color={newGroup.color}
              onChangeComplete={(color) => setNewGroup({ ...newGroup, color: color.hex })}
              disableAlpha
              styles={{
                default: {
                  picker: {
                    width: '100%'
                  },
                },
              }}
            />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: '60%', marginLeft: '120px' }}>
        <button
          onClick={handleAddGroup}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            padding: '10px 15px',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: '1',
            marginRight: '10px',
          }}
        >
          Add Group
        </button>
        <button
           onClick={() => {
            setModalOpen(false);
            setMeterAddSearchTerm('');
          }}
          style={{
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            padding: '10px 15px',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: '1',
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}
      {isEditModalOpen && (
  <div
  style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <div
    ref={editModalRef}
    style={{
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      maxWidth: '530px',
      width: '100%',
      height: '480px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      overflowX :'auto'
    }}
  >
    <h3 style={{ marginBottom: '10px' }}> Edit Group</h3>

    <label
      htmlFor="group_name"
      style={{
        marginBottom: '5px',
        display: 'block',
        fontWeight: 'bold',
        fontSize: '14px',
      }}
    >
      Group Name
    </label>
    <input
      type="text"
      placeholder="Group Name"
      value={editGroup.group_name}
      onChange={(e) => setEditGroup({ ...editGroup, group_name: e.target.value })}
      style={{
        width: '50%',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        marginBottom: '10px',
      }}
    />

    <div
      style={{
        display: 'flex',
        gap: '20px',
        marginBottom: '20px',
        alignItems: 'flex-start',
      }}
    >
      <div style={{ flex: 1 }}>
        <label
          htmlFor="meters"
          style={{
            marginBottom: '5px',
            display: 'block',
            fontWeight: 'bold',
            fontSize: '14px',
          }}
        >
          Meters List
        </label>
        <div
          style={{
            width: '90%',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            height: '230px',
            overflowY: 'auto',
          }}
        >
          <span
            style={{
              display: 'block',
              marginBottom: '10px',
            }}
          >
            <input
              type="text"
              placeholder="Search meters..."
              value={metersearchTerm}
              onChange={(e) => setMeterSearchTerm(e.target.value)}
              style={{
                width: '90%',
                padding: '5px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                fontSize: '12px',
              }}
            />
          </span>
          {filteredMeters.map((meter) => {
            const isAdded = editGroup.meters.includes(meter.id);
            return (
              <div
                key={meter.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '5px',
                  backgroundColor: '#e0e0e0',
                  padding: '5px',
                  borderRadius: '5px',
                  fontSize: '12px'
                }}
              >
                <span style={{ color: isAdded ? 'gray' : 'black' }}>{meter.meter_name}</span>
                <button
                  onClick={() => {
                    if (!isAdded) {
                      setEditGroup((prev) => ({
                        ...prev,
                        meters: [...prev.meters, meter.id],
                      }));
                    }
                  }}
                  style={{
                    backgroundColor: isAdded ? 'gray' : '#4CAF50',
                    color: 'white',
                    border: 'none',
                    padding: '5px 10px',
                    borderRadius: '5px',
                    cursor: isAdded ? 'not-allowed' : 'pointer',
                    fontSize: '10px',
                  }}
                  disabled={isAdded}
                >
                  {isAdded ? 'Added' : 'Add'}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <div style={{ width: '100%' }}>
              <label style={{ marginBottom: '5px', display: 'block', fontWeight: 'bold' }}>
                Added Meters
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  backgroundColor: '#f9f9f9',
                  height: '225px',
                  overflowY: 'auto',
                }}
              >
                {editGroup.meters.map((meterId) => {
                const meterName = meterMap[meterId];
                return (
                  <div
                    key={meterId}
                    style={{
                      backgroundColor: '#e0e0e0',
                      marginBottom: '5px',
                      padding: '5px',
                      borderRadius: '3px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontSize: '12px',
                    }}
                  >
                    <span>{meterName}</span>
                    <button
                      onClick={() => handleRemoveEditMeter(meterId)}
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none',
                        padding: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '10px',
                      }}
                    >
                      Remove
                    </button>
                  </div>
                );
              })}
              </div>
            </div>
          </div>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
          <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
            Edit Group Color
          </label>
        </div>
        <div
          style={{
            marginTop: '10px',
            padding: '10px',
            background: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
            width: '500px',
              maxWidth: '50%',
              marginBottom: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', 
          }}
        >
              <ChromePicker
                color={editGroup.color}
                onChangeComplete={(color) => setEditGroup({ ...editGroup, color: color.hex })}
                disableAlpha
                styles={{
                  default: {
                    picker: {
                      width: '100%'
                    },
                  },
                }}
              />
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: '60%', marginLeft: '120px' }}>
              <button
                onClick={handleUpdateGroup}
                style={{
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  padding: '10px 15px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  flex: '1',
                  marginRight: '10px',
                }}
              >
                Update Group
              </button>
              <button
              onClick={() => {
                setEditModalOpen(false);
                setMeterSearchTerm('');
              }}
                style={{
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  padding: '10px 15px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  flex: '1',
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
   {isDeleteOpenModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            ref={deleteModalRef}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "400px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete the selected groups?</p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
               <div style={{ display: "flex", gap: "10px" }}>
              <button
                style={{
                  padding: "10px 15px",
                  color: "white",
                  backgroundColor:"#4CAF50",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={handleDeleteGroups}
              >
                Yes, Delete
              </button>
              <button
                style={{
                  padding: "10px 15px",
                  color: "white",
                  backgroundColor:"#f44336",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => setIsDeleteOpenModal(false)}
              >
                Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnergyGroups;