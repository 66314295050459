import React, { useState, useEffect,useRef} from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faPlus,faEdit } from '@fortawesome/free-solid-svg-icons';
import BaseURL from '../assets/contants/BaseURL';
import Header from '../components/header';
import { ChromePicker } from 'react-color';
// import { color } from 'highcharts';
// import { Colors } from 'chart.js';

const MachineList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchMeter, setSearchMeter] = useState('');
  const [searchEditMeter, setSearchEditMeter] = useState('');
  const [checkedItems, setCheckedItems] = useState({});
  const [machines, setMachines] = useState([]);
  const [meters, setMeters] = useState([]);
  const [newMachine, setNewMachine] = useState({
    machine_name: '',
    machine_id: '',
    line: '',
    manufacture: '',
    meter_list: [],
    color: ''
  });
  const [editMachine, setEditMachine] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [meterIds, setMeterIds] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
   const[isDeleteModalOpen,setDeleteModalOpen] =useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(machines.length / itemsPerPage);
  const paginatedMachines = machines.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [addedMeters, setAddedMeters] = useState([]);
  const addMachineModalRef = useRef();
  const editMachineModalRef = useRef();
  const deleteModalRef=useRef();
  const [originalMachines, setOriginalMachines] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [validationMessages, setValidationMessages] = useState({
    machine_name: '',
    machine_id: '',
  
  });




  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  // const handleMeterSelection = (e) => {
  //   const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
  //   setSelectedMeters(selectedOptions);
  //   setAddedMeters(selectedOptions);
  //   setNewMachine(prevMachine => ({
  //     ...prevMachine,
  //     meter_list: selectedOptions,
  //   }));
  // };


  const fetchMachines = async () => {
    try {
      const response = await axios.get(`${BaseURL}meters/machine/`);
      const sortedMachines = response.data.sort((a, b) => new Date(b.create_date_time) - new Date(a.create_date_time));
      setMachines(sortedMachines);
      setOriginalMachines(sortedMachines);
    } catch (error) {
      console.error('Error fetching machine data:', error);
    }
  };


  const fetchMeters = async () => {
    try {
      const response = await axios.get(`${BaseURL}meters/energymeters/`);
      setMeters(response.data);
      setMeterIds(response.data.map((meter) => meter.meter_id)); 
    } catch (error) {
      console.error('Error fetching meters:', error);
    }
  };
  

  useEffect(() => {
    fetchMachines();
    fetchMeters();
  }, []);

  const handleAddMachine = async () => {
    setValidationMessages({
      machine_name: '',
      machine_id: '',
    });
  
    let isValid = true;
  
    if (!newMachine.machine_name) {
      setValidationMessages((prev) => ({ ...prev, machine_name: 'Machine Name is required.' }));
      isValid = false;
    } 
  
    if (!newMachine.machine_id) {
      setValidationMessages((prev) => ({ ...prev, machine_id: 'Machine ID is required.' }));
      isValid = false;
    } else {
      const isDuplicate = machines.some((machine) => machine.machine_id === newMachine.machine_id);
      if (isDuplicate) {
        setValidationMessages((prev) => ({ ...prev, machine_id: 'Machine details with this Machine ID already exist.' }));
        isValid = false;
      }
    }
  
    if (!isValid) return;
    try {
      const updatedMeterList = selectedMeters.map((meterId) => {
        const meter = meters.find((m) => m.meter_id === meterId);
        return meter ? meter.id : null;
      }).filter((id) => id !== null);
      const machineData = {
        ...newMachine,
        meter_list: updatedMeterList,
      };
  
      await axios.post(`${BaseURL}meters/machine/`, machineData);
      fetchMachines();
      setNewMachine({
        machine_name: '',
        machine_id: '',
        line: '',
        manufacture: '',
        meter_list: [],
        color: ''
      });
      setSelectedMeters([]);
      setAddedMeters([]);
      setModalOpen(false);
      setSuccessMessage('Machine added successfully!');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error adding machine:', error);
    }
  };  

  const handleEditButtonClick = (machine) => {
    console.log("Machine to Edit:", machine);
    const meterIds = machine.meter_list.map(meter => {
      console.log("Meter ID:", meter);
      return meter;
    });
    console.log("Mapped meter_ids:", meterIds);

    setEditMachine({
      id: machine.id,
      machine_name: machine.machine_name,
      machine_id: machine.machine_id,
      line: machine.line,
      manufacture: machine.manufacture,
      meter_list: meterIds,
      color: machine.color,
    });
    console.log("editMachine after set:", {
      id: machine.id,
      machine_name: machine.machine_name,
      machine_id: machine.machine_id,
      line: machine.line,
      manufacture: machine.manufacture,
      meter_list: meterIds,
    });
    setEditModalOpen(true);
  };

  useEffect(() => {
    if (editMachine) {
      setSelectedMeters(editMachine.meter_list);
    }
  }, [editMachine])

  const handleEditMachine = async () => {
    setValidationMessages({
      machine_name: '',
      machine_id: '',
    });
  
    let isValid = true;
  
    if (!editMachine.machine_name) {
      setValidationMessages((prev) => ({ ...prev, machine_name: 'Machine Name is required.' }));
      isValid = false;
    }
  
    if (!editMachine.machine_id) {
      setValidationMessages((prev) => ({ ...prev, machine_id: 'Machine ID is required.' }));
      isValid = false;
    } else {
     
      const isDuplicate = machines.some(
        (machine) => machine.machine_id === editMachine.machine_id && machine.id !== editMachine.id
      );
      
      if (isDuplicate) {
        setValidationMessages((prev) => ({ ...prev, machine_id: 'Machine details with this Machine ID already exist.' }));
        isValid = false;
      }
    }
  
    if (!isValid) return;
    try {
      console.log('Sending data for editing:', {
        ...editMachine,
        meter_list: editMachine.meter_list,
      });
  
      await axios.put(`${BaseURL}meters/machine/${editMachine.id}/`, {
        ...editMachine,
        meter_list: editMachine.meter_list,
      });
      fetchMachines(); 
      setEditMachine(null);
      setEditModalOpen(false);
      setSuccessMessage('Machine updated successfully!'); 
    setTimeout(() => setSuccessMessage(''), 3000); 
    } catch (error) {
      console.error('Error editing machine:', error);
    }
  };

  const handleDeleteMachines = async () => {
    const idsToDelete = Object.keys(checkedItems).filter((id) => checkedItems[id]);
    if (idsToDelete.length === 0) {
      setDeleteModalOpen(false);
      return;
    }
  
    try {
      for (const id of idsToDelete) {
        await axios.delete(`${BaseURL}meters/machine/${id}/`);
      }
      fetchMachines();
      setCheckedItems({});
      setSuccessMessage('Selected machines deleted successfully!'); 
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error deleting machines:', error);
    } finally {
      setDeleteModalOpen(false);
    }
  };
  

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      setMachines(originalMachines);
    } else {
      const results = originalMachines.filter((machine) => {
        const machineNameMatch = machine.machine_name.toLowerCase().includes(searchTerm.toLowerCase());
        const machineIdMatch = machine.machine_id.toLowerCase().includes(searchTerm.toLowerCase());
        const meterListMatch = machine.meter_list
          .map((meterId) => meters.find((meter) => meter.id === meterId)?.meter_id)
          .some((meterId) => meterId && meterId.toLowerCase().includes(searchTerm.toLowerCase()));
  
        return machineNameMatch || machineIdMatch || meterListMatch;
      });
      setMachines(results);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  const handleCheckboxChange = (id) => {
    setCheckedItems((prev) => {
      const newCheckedItems = {
        ...prev,
        [id]: !prev[id],
      };
      return newCheckedItems;
    });
  };
  
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newCheckedItems = {};
    machines.forEach((machine) => {
      newCheckedItems[machine.id] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addMachineModalRef.current && !addMachineModalRef.current.contains(event.target)) {
        setModalOpen(false);
        setNewMachine({
          machine_name: '',
          machine_id: '',
          line: '',
          manufacture: '',
          meter_list: [],
          color: ''
        });
        setSelectedMeters([]);
        setAddedMeters([]);
        setValidationMessages({
          machine_name: '',
          machine_id: '',
        });
      }
      if (editMachineModalRef.current && !editMachineModalRef.current.contains(event.target)) {
        setEditModalOpen(false);
        setEditMachine(null);
        setSelectedMeters([]);
        setValidationMessages({
          machine_name: '',
          machine_id: '',
        });
      }
      if (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
        setDeleteModalOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleRemoveMeter = (meterId) => {
    setAddedMeters((prevAddedMeters) => prevAddedMeters.filter((id) => id !== meterId));
    setSelectedMeters((prevSelectedMeters) => prevSelectedMeters.filter((id) => id !== meterId));
    setNewMachine((prevMachine) => ({
      ...prevMachine,
      meter_list: prevMachine.meter_list.filter((id) => id !== meterId),
    }));
  };

  const handleAddMeter = (meterId) => {
    if (!addedMeters.includes(meterId)) {
      setAddedMeters((prevAddedMeters) => {
        const updatedAddedMeters = [...prevAddedMeters, meterId];
        return updatedAddedMeters;
      });
  
      setSelectedMeters((prevSelectedMeters) => {
        const updatedSelectedMeters = [...prevSelectedMeters, meterId];
        return updatedSelectedMeters;
      });
    } else {
      console.log(`Meter ID ${meterId} is already added.`);
    }
  };


  const handlemeterSearch = () => {
    if (searchMeter.trim() === '') {
      setMeterIds(meters.map((meter) => meter.meter_id));
    } else {
      const filteredMeters = meters.filter((meter) =>
        meter.meter_id.toLowerCase().includes(searchMeter.toLowerCase())
      );
      setMeterIds(filteredMeters.map((meter) => meter.meter_id)); 
    }
  };

  const handlemeterSearchChange = (e) => {
    setSearchMeter(e.target.value);
  };

  useEffect(() => {
    handlemeterSearch();
  }, [searchMeter]);

  const addMeter = (meterId) => {
    if (!editMachine.meter_list.includes(meterId)) {
      setEditMachine({
        ...editMachine,
        meter_list: [...editMachine.meter_list, meterId],
      });
    }
  };

  const handleMeterSearchChange = (e) => {
    setSearchEditMeter(e.target.value);
  };

  const filteredMeters = meters.filter((meter) =>
    meter.meter_id.toLowerCase().includes(searchEditMeter.toLowerCase())
  );
 
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
       backgroundColor: '#007acc',
       color: 'white',
       alignContent: 'center',
       fontSize: '14px',
       height:'40px'
      }}>
       <Header title="MACHINE LIST" />
      </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',  
        marginTop: '20px', 
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto', 
        maxWidth: '1000px',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                flex: '1',
                marginRight: '10px',
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FontAwesomeIcon
              icon={ faSearch}
              style={{ color: '#007acc', cursor: 'pointer' }}
              onClick={handleSearch}
            />
          </div>

          <div style={{ marginLeft: 'auto' }}>
            <button
              style={{
                padding: '10px 15px',
                marginRight: '10px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => {
                const idsToDelete = Object.keys(checkedItems).filter((id) => checkedItems[id]);
                if (idsToDelete.length > 0) {
                  setDeleteModalOpen(true);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />
              Delete
            </button>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={() => setModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Add
            </button>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        {successMessage && (
          <div style={{
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#4CAF50', 
            color: 'white',
            margin: '10px auto',
            width: 'fit-content',
          }}>
            {successMessage}
          </div>
        )}
          </div>

          <div style={{
  marginTop: '20px', 
  padding: '10px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: 'white',
  maxWidth: '1000px',
  margin: '20px auto',
  marginBottom: '70px', 
}}>
  <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
    <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
      <tr>
        <th style={{ padding: '10px' }}>
          <input
            type="checkbox"
            onChange={handleSelectAll}
            checked={
              machines.length > 0 && 
              Object.keys(checkedItems).length === machines.length && 
              Object.values(checkedItems).every((checked) => checked)
            }
          />
        </th>
        <th style={{ padding: '10px' }}>S.No</th>
        <th style={{ padding: '10px' }}>Equipment Name</th>
        <th style={{ padding: '10px' }}>Equipment ID</th>
        <th style={{ padding: '10px' }}>Meter List</th>
        <th style={{ padding: '10px' }}>Action</th>
      </tr>
    </thead>
    <tbody>
      {paginatedMachines.length === 0 ? (
        <tr>
          <td colSpan={6} style={{ textAlign: 'center', padding: '20px'}}>
            No data available
          </td>
        </tr>
      ) : (
        paginatedMachines.map((machine, index) => {
          const meterNames = machine.meter_list
            .map((meterId) => meters.find((meter) => meter.id === meterId)?.meter_name)
            .filter(Boolean);

          return (
            <tr key={machine.id} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff' }}>
              <td style={{ padding: '10px' }}>
                <input
                  type="checkbox"
                  checked={checkedItems[machine.id] || false}
                  onChange={() => handleCheckboxChange(machine.id)}
                />
              </td>
              <td style={{ padding: '10px', textAlign: 'center' }}>{(currentPage - 1) * itemsPerPage + index + 1}</td>
              <td style={{ padding: '10px', textAlign: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <span
                    style={{
                      width: '20px',
                      height: '20px',
                      minWidth: '20px',
                      minHeight: '20px',
                      backgroundColor: machine.color && machine.color !== '#FFFFFF' ? machine.color : '#007acc',
                      borderRadius: '50%',
                      border: '2px solid #fff',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                      marginRight: '10px',
                      transition: 'transform 0.2s ease',
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  />
                  <span>{machine.machine_name || '-'}</span>
                </div>
              </td>
              <td style={{ padding: '10px', textAlign: 'center' }}>{machine.machine_id}</td>
              <td style={{ padding: '10px', textAlign: 'left', wordWrap: 'break-word' }}>
                {meterNames.length > 0
                  ? meterNames.map((meter, index) => (
                      <span
                        key={index}
                        style={{
                          marginRight: index < meterNames.length - 1 ? '10px' : '0',
                          padding: '5px',
                          border: '1px solid #ddd',
                          borderRadius: '5px',
                          display: 'inline-block',
                          whiteSpace: 'normal',
                          marginBottom: '5px',
                          backgroundColor: 'white'
                        }}
                      >
                        {meter}
                      </span>
                    ))
                  : '-'}
              </td>
              <td
                style={{
                  padding: '10px',
                  color: 'blue',
                  cursor: 'pointer',
                  textAlign: 'center',
                }}
                onClick={() => handleEditButtonClick(machine)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </td>
            </tr>
          );
        })
      )}
    </tbody>
  </table>



        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: currentPage === 1 ? '#007acc' : '#007acc',
              color: currentPage === 1 ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === 1 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
            }}
          >
            Previous
          </button>

          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              style={{
                margin: '0 5px',
                padding: '5px 10px',
                background: currentPage === i + 1 ? '#007acc' : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
                color: currentPage === i + 1 ? '#ffffff' : '#333333',
                border: '1px solid #ddd',
                borderRadius: '25px',
                cursor: 'pointer',
                fontSize: '12px',
              }}
            >
              {i + 1}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: currentPage === totalPages ? '#007acc' : '#007acc',
              color: currentPage === totalPages ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === totalPages ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
            }}
          >
            Next
          </button>
        </div>
      </div>

        {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
           ref={addMachineModalRef}
            style={{
              backgroundColor: 'white',
              padding: '30px',
              borderRadius: '8px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
              width: '60%',
              maxWidth: '600px',
              height: '480px',
              transition: 'all 0.3s ease',
              overflowX :'auto'
            }}
          >
            <h4 style={{ marginBottom: '20px', textAlign: 'center' }}>Add New Equipment</h4>
            <div style={{ display: 'flex', gap: '30px' }}>
              <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <div style={{ display: 'flex', gap: '20px' }}>
                  <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                  <label>
                    Group Name <span style={{ color: 'red' }}>*</span>
                  </label>
                    <input
                      type="text"
                      value={newMachine.machine_name}
                      onChange={(e) => setNewMachine({ ...newMachine, machine_name: e.target.value })}
                      placeholder="Enter Machine Name"
                      style={{
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        width: '95%',
                      }}
                    />
                     {validationMessages.machine_name && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.machine_name}</div>
                  )}
                  </div>
                  <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                  <label>
                    Group ID <span style={{ color: 'red' }}>*</span>
                  </label>
                    <input
                      type="text"
                      value={newMachine.machine_id}
                      onChange={(e) => setNewMachine({ ...newMachine, machine_id: e.target.value })}
                      placeholder="Enter Machine ID (Unique)"
                      style={{
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        width: '95%',
                      }}
                    />
                    {validationMessages.machine_id && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.machine_id}</div>
                  )}
                  </div>
                </div>

                {/* <div style={{ display: 'flex', gap: '20px' }}>
                  <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                    <label>Line</label>
                    <input
                      type="text"
                      value={newMachine.line}
                      onChange={(e) => setNewMachine({ ...newMachine, line: e.target.value })}
                      placeholder="Enter Line"
                      style={{
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        width: '95%',
                      }}
                    />
                    {validationMessages.line && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.line}</div>
                  )}
                  </div>
                  <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                    <label>Manufacture</label>
                    <input
                      type="text"
                      value={newMachine.manufacture}
                      onChange={(e) => setNewMachine({ ...newMachine, manufacture: e.target.value })}
                      placeholder="Enter Manufacture"
                      style={{
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        width: '95%',
                      }}
                    />
                    {validationMessages.manufacture && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.manufacture}</div>
                  )}
                  </div>
                </div> */}

                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginBottom: '10px' }}>
                  <div style={{ flex: '1' }}>
                    <label htmlFor="meters" style={{ marginBottom: '5px', display: 'block', fontWeight: 'bold' }}>Meters List</label>
                    <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', height: '150px', overflowY: 'auto' }}>
                      <input
                        type="text"
                        placeholder="Search meters..."
                        value={searchMeter}
                        onChange={handlemeterSearchChange}
                        style={{ padding: '5px', marginBottom: '10px', width: '90%', border: '1px solid #ccc', borderRadius: '4px' }}
                      />
                      {meterIds.map((meterId, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', backgroundColor: '#f9f9f9', padding: '5px', borderRadius: '4px' }}>
                          <span style={{ fontSize: '12px' }}>{meterId}</span>
                          <button
                            onClick={() => handleAddMeter(meterId)}
                            disabled={addedMeters.includes(meterId)}
                            style={{
                              background: addedMeters.includes(meterId) ? 'gray' : 'green',
                              color: 'white',
                              border: 'none',
                              borderRadius: '5px',
                              padding: '5px',
                              cursor: addedMeters.includes(meterId) ? 'not-allowed' : 'pointer',
                              fontSize: '10px',
                            }}
                          >
                            {addedMeters.includes(meterId) ? 'Added' : 'Add'}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div style={{ flex: '1' }}>
                    <label style={{ marginBottom: '5px', display: 'block', fontWeight: 'bold' }}>Added Meters</label>
                    <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', height: '150px', overflowY: 'auto' }}>
                      {addedMeters.map((meterId, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px', backgroundColor: '#f9f9f9', padding: '5px', borderRadius: '4px' }}>
                          <span style={{ fontSize: '12px' }}>{meterId}</span>
                          <button
                            onClick={() => handleRemoveMeter(meterId)}
                            style={{
                              background: 'red',
                              color: 'white',
                              border: 'none',
                              borderRadius: '5px',
                              padding: '5px',
                              cursor: 'pointer',
                              fontSize: '10px',
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                    <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
                      Choose Group Color
                    </label>
                  </div>
                  <div
                    style={{
                      marginTop: '10px',
                      padding: '10px',
                      background: '#f9f9f9',
                      borderRadius: '8px',
                      boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
                      width: '500px',
                      maxWidth: '50%',
                      marginBottom: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center', 
                    }}
                  >
                    <ChromePicker
                      color={newMachine.color}
                      onChangeComplete={(color) => setNewMachine({ ...newMachine, color: color.hex })}
                      disableAlpha
                      styles={{
                        default: {
                          picker: {
                            width: '100%'
                          },
                        },
                      }}
                    />
                  </div>
                </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
                width: '40%',
                marginLeft: '200px',
              }}
            >
              <button
                onClick={handleAddMachine}
                style={{
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  padding: '10px 15px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  flex: '1',
                  marginRight: '10px',
                }}
              >
                Add Machine
              </button>
              <button
                onClick={() => setModalOpen(false)}
                style={{
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  padding: '10px 15px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  flex: '1',
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}



{isEditModalOpen && (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div
    ref={editMachineModalRef}
      style={{
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
        width: '60%',
        maxWidth: '600px',
        height: '480px',
        transition: 'all 0.3s ease',
        overflowX :'auto'
      }}
    >
      <h4 style={{ marginBottom: '20px', textAlign: 'center' }}>Edit Equipment</h4>
      <div style={{ display: 'flex', gap: '30px' }}>
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div style={{ display: 'flex', gap: '20px' }}>
            <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
            <label>
            Group Name <span style={{ color: 'red' }}>*</span>
          </label>
            <input
              type="text"
              value={editMachine.machine_name}
              onChange={(e) => setEditMachine({ ...editMachine, machine_name: e.target.value })}
              style={{
                flex: '1',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
              }}
            />
            {validationMessages.machine_name && (
                <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.machine_name}</div>
              )}
            </div>

          <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          <label>
            Group ID <span style={{ color: 'red' }}>*</span>
          </label>
            <input
              type="text"
              value={editMachine.machine_id}
              onChange={(e) => setEditMachine({ ...editMachine, machine_id: e.target.value })}
              style={{
                flex: '1',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
              }}
            />
            {validationMessages.machine_id && (
                <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.machine_id}</div>
              )}
          </div>
        </div>

        {/* <div style={{ display: 'flex', gap: '20px' }}>
          <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
            <label>Line</label>
            <input
              type="text"
              value={editMachine.line}
              onChange={(e) => setEditMachine({ ...editMachine, line: e.target.value })}
              style={{
                flex: '1',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
              }}
            />
          </div>

          <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
            <label>Manufacture</label>
            <input
              type="text"
              value={editMachine.manufacture}
              onChange={(e) => setEditMachine({ ...editMachine, manufacture: e.target.value })}
              style={{
                flex: '1',
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
              }}
            />
          </div>
        </div> */}
      </div>
      </div>

      <div style={{ display: 'flex', gap: '30px' }}>
        <div style={{ flex: '1' }}>
          <label style={{ marginBottom: '5px', display: 'block', fontWeight: 'bold' }}>Meters List</label>
          <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', height: '150px', overflowY: 'auto' }}>
          <input
            type="text"
            value={searchEditMeter}
            onChange={handleMeterSearchChange}
            placeholder="Search meters"
            style={{
              padding: '5px',
              marginBottom: '10px',
              width: '90%',
              borderRadius: '4px',
              border: '1px solid #ccc',
            }}
          />
            {filteredMeters.map((meter) => (
              <div
                key={meter.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '5px',
                  backgroundColor: '#f9f9f9',
                  padding: '5px',
                  borderRadius: '4px',
                }}
              >
                <span style={{ fontSize: '12px' }}>{meter.meter_id}</span>
                <button
                  onClick={() => addMeter(meter.id)}
                  style={{
                    background: editMachine.meter_list.includes(meter.id) ? 'gray' : 'green',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '5px',
                    cursor: editMachine.meter_list.includes(meter.id) ? 'not-allowed' : 'pointer',
                    fontSize: '10px',
                  }}
                  disabled={editMachine.meter_list.includes(meter.id)}
                >
                  {editMachine.meter_list.includes(meter.id) ? 'Added' : 'Add'}
                </button>
              </div>
            ))}
          </div>
        </div>

        <div style={{ flex: '1' }}>
          <label style={{ marginBottom: '5px', display: 'block', fontWeight: 'bold' }}>Added Meters</label>
          <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', height: '150px', overflowY: 'auto' }}>
            {editMachine.meter_list.map((meterId, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '5px',
                  backgroundColor: '#f9f9f9',
                  padding: '5px',
                  borderRadius: '4px',
                }}
              >
                <span style={{ fontSize: '12px' }}>
                  {meters.find((m) => m.id === meterId)?.meter_id || meterId}
                </span>
                <button
                  onClick={() => {
                    setEditMachine({
                      ...editMachine,
                      meter_list: editMachine.meter_list.filter((id) => id !== meterId),
                    });
                  }}
                  style={{
                    background: 'red',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '5px',
                    cursor: 'pointer',
                    fontSize: '10px',
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px' }}>
                <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
                  Edit Group Color
                </label>
              </div>
              <div
                style={{
                  marginTop: '10px',
                  padding: '10px',
                  background: '#f9f9f9',
                  borderRadius: '8px',
                  boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
                  width: '500px',
                    maxWidth: '50%',
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', 
                }}
              >
                    <ChromePicker
                      color={editMachine.color}
                      onChangeComplete={(colors) => setEditMachine({ ...editMachine, color: colors.hex })}
                      disableAlpha
                      styles={{
                        default: {
                          picker: {
                            width: '100%'
                          },
                        },
                      }}
                    />
              </div>
            </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
          width: '40%',
          marginLeft: '200px',
        }}
      >
        <button
          onClick={handleEditMachine}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            padding: '10px 10px',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: '1',
            marginRight: '10px',
          }}
        >
          Save Changes
        </button>
        <button
          onClick={() => setEditModalOpen(false)}
          style={{
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            padding: '10px 10px',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: '1',
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}
 {isDeleteModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            ref={deleteModalRef}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "400px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete the selected machines?</p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
               <div style={{ display: "flex", gap: "10px" }}>
              <button
                style={{
                  padding: "10px 15px",
                  color: "white",
                  backgroundColor:"#4CAF50",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={handleDeleteMachines}
              >
                Yes, Delete
              </button>
              <button
                style={{
                  padding: "10px 15px",
                  color: "white",
                  backgroundColor: "#f44336",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default MachineList;
