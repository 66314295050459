import React, { useState, useEffect,useRef } from "react";
import Header from "../components/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTrash, faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import BaseURL from '../assets/contants/BaseURL';
import axios from "axios"; 

const EnergyData = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [energyData, setEnergyData] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [renewable, setRenewable] = useState("");
  const [nonRenewable, setNonRenewable] = useState("");
  const [editData, setEditData] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [renewablePercentage, setRenewablePercentage] = useState('');
  const [nonRenewablePercentage, setNonRenewablePercentage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [validationMessages, setValidationMessages] = useState({
    year: '',
    month: '',
    renewable: '',
    nonRenewable: '',
    renewablePercentage: '',
    nonRenewablePercentage: '',
  });
  const [editValidationMessages, setEditValidationMessages] = useState({
    year: '',
    month: '',
    renewable: '',
    nonRenewable: '',
    renewablePercentage: '',
    nonRenewablePercentage: '',
  });

  const addModalRef = useRef();
  const editModalRef = useRef();
  const deleteModalRef = useRef();
  const errorModalRef = useRef();

  const closeModal = (setModalOpen) => {
    setModalOpen(false);
  };

  const handleClickOutside = (event, modalRef, setModalOpen,resetValidationMessages) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal(setModalOpen);
      resetValidationMessages();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      handleClickOutside(event, addModalRef, setModalOpen, () => setValidationMessages({
        year: '',
        month: '',
        renewable: '',
        nonRenewable: '',
        renewablePercentage: '',
        nonRenewablePercentage: '',
      }));
      handleClickOutside(event, editModalRef, setEditModalOpen, () => setValidationMessages({
        year: '',
        month: '',
        renewable: '',
        nonRenewable: '',
        renewablePercentage: '',
        nonRenewablePercentage: '',
      }));
      handleClickOutside(event, deleteModalRef, setIsDeleteModalOpen, () => setValidationMessages({
        year: '',
        month: '',
        renewable: '',
        nonRenewable: '',
        renewablePercentage: '',
        nonRenewablePercentage: '',
      }));
      handleClickOutside(event, errorModalRef, () => {
        setErrorMessage('');
        setValidationMessages({
          year: '',
          month: '',
          renewable: '',
          nonRenewable: '',
          renewablePercentage: '',
          nonRenewablePercentage: '',
        }); 
        setEditValidationMessages({
          year: '',
          month: '',
          renewable: '',
          nonRenewable: '',
          renewablePercentage: '',
          nonRenewablePercentage: '',
        });       
      });
    };
  
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);


  useEffect(() => {
    fetchEnergyData();
  }, []);

  const fetchEnergyData = async () => {
    try {
      const response = await fetch(`${BaseURL}data/energy/`);
      let data = await response.json();
      data = data.sort((a, b) => {
        if (a.year === b.year) {
          return b.month - a.month;
        }
        return b.year - a.year;
      });

      setEnergyData(data);
    } catch (error) {
      console.error("Error fetching energy data:", error);
    }
  };

  const totalPages = Math.ceil(energyData.length / itemsPerPage);

  const paginatedData = energyData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value === "") {
      fetchEnergyData();
      setCurrentPage(1);
    } else {
      const filteredData = energyData.filter((item) => {
        return (
          item.year.toString().includes(value) ||
          item.renewable.toString().includes(value) ||
          item.non_renewable.toString().includes(value)
        );
      });
      setEnergyData(filteredData);
      setCurrentPage(1);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((id) => id !== itemId)
      );
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(paginatedData.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const addEnergyData = async () => {
    setValidationMessages({
      year: '',
      month: '',
      renewable: '',
      nonRenewable: '',
      renewablePercentage: '',
      nonRenewablePercentage: '',
    });
  
    let hasError = false;
  
    // Validation checks
    if (!year) {
      setValidationMessages((prev) => ({ ...prev, year: 'Year is required' }));
      hasError = true;
    }
    if (!month) {
      setValidationMessages((prev) => ({ ...prev, month: 'Month is required' }));
      hasError = true;
    }
    if (!renewable) {
      setValidationMessages((prev) => ({ ...prev, renewable: 'Renewable units are required' }));
      hasError = true;
    }
    if (!nonRenewable) {
      setValidationMessages((prev) => ({ ...prev, nonRenewable: 'Non-renewable units are required' }));
      hasError = true;
    }
    if (!renewablePercentage) {
      setValidationMessages((prev) => ({ ...prev, renewablePercentage: 'Renewable percentage is required' }));
      hasError = true;
    }
    if (!nonRenewablePercentage) {
      setValidationMessages((prev) => ({ ...prev, nonRenewablePercentage: 'Non-renewable percentage is required' }));
      hasError = true;
    }
  
    if (hasError) return;
    const monthNumber = new Date(Date.parse(month + " 1, 2020")).getMonth() + 1;
    const energyData = {
      year: year,
      month: monthNumber,
      renewable: renewable,
      non_renewable: nonRenewable,
      renewable_percentage:renewablePercentage,
      non_renewable_percentage:nonRenewablePercentage,
    };

    try {
      const response = await axios.post(`${BaseURL}data/energy/`, energyData);
      setEnergyData((prevData) => [response.data, ...prevData]);

      fetchEnergyData();
      setModalOpen(false);
      setYear("");
      setMonth("");
      setRenewable("");
      setNonRenewable("");
      setRenewablePercentage("");
      setNonRenewablePercentage("");
      setSuccessMessage('Energy data added successfully!');
    setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.non_field_errors) {
        setErrorMessage("This year and month combination already exists.");
        setIsErrorModalOpen(true);
      } else {
        console.error("Error adding energy data", error);
      }
    }
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
    setErrorMessage("");
  };

  const handleEditClick = (data) => {
    setEditData(data);
    setEditModalOpen(true);
  };

  const getMonthNumberFromName = (monthName) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months.indexOf(monthName) + 1;
  };

  const handleSaveChanges = async () => {
    setEditValidationMessages({
      year: '',
      month: '',
      renewable: '',
      nonRenewable: '',
      renewablePercentage: '',
      nonRenewablePercentage: '',
    });
  
    let hasError = false;
  
    // Validation checks
    if (!editData.year) {
      setEditValidationMessages((prev) => ({ ...prev, year: 'Year is required' }));
      hasError = true;
    }
    if (!editData.month) {
      setEditValidationMessages((prev) => ({ ...prev, month: 'Month is required' }));
      hasError = true;
    }
    if (!editData.renewable) {
      setEditValidationMessages((prev) => ({ ...prev, renewable: 'Renewable units are required' }));
      hasError = true;
    }
    if (!editData.non_renewable) {
      setEditValidationMessages((prev) => ({ ...prev, nonRenewable: 'Non-renewable units are required' }));
      hasError = true;
    }
    if (!editData.renewable_percentage) {
      setEditValidationMessages((prev) => ({ ...prev, renewablePercentage: 'Renewable percentage is required' }));
      hasError = true;
    }
    if (!editData.non_renewable_percentage) {
      setEditValidationMessages((prev) => ({ ...prev, nonRenewablePercentage: 'Non-renewable percentage is required' }));
      hasError = true;
    }
  
    if (hasError) return;
    let monthNumber;
  
    if (typeof editData.month === 'number') {
      monthNumber = editData.month;
    } else {
      monthNumber = getMonthNumberFromName(editData.month);
    }
  
  
    const updatedEnergyData = {
      year: editData.year,
      month: monthNumber,
      renewable: editData.renewable,
      non_renewable: editData.non_renewable,
      renewable_percentage:editData.renewable_percentage,
      non_renewable_percentage:editData.non_renewable_percentage,
    };
  
  
    try {
      const response = await axios.put(`${BaseURL}data/energy/${editData.id}/`, updatedEnergyData);
      setEnergyData((prevData) =>
        prevData.map((item) =>
          item.id === editData.id ? { ...item, ...updatedEnergyData } : item
        )
      );
      setEditModalOpen(false);
      setSuccessMessage('Energy data updated successfully!'); 
    setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error("Error updating energy data", error);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      for (const itemId of selectedItems) {
        await axios.delete(`${BaseURL}data/energy/${itemId}/`);
      }

      console.log("Selected energy data deleted successfully.");
      fetchEnergyData();
      setSelectedItems([]);
      setSuccessMessage('Selected energy data deleted successfully!'); 
    setTimeout(() => setSuccessMessage(''), 3000); 
    } catch (error) {
      console.error("Error deleting energy data", error);
    } finally {
      setIsDeleteModalOpen(false);
    }
  };


  const formatNumberWithCommas = (number) => {
    if (typeof number !== 'string') {
      number = number.toString();
    }
    // Return formatted number with commas if it's a valid string or number
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  
  const removeCommas = (number) => {
    if (typeof number !== 'string') {
      number = number.toString();
    }
    // Return the number without commas
    return number.replace(/,/g, '');
  };
  


  return (
    <div style={{ textAlign: "center", width: "100%" }}>
      <div
        style={{
          backgroundColor: "#007acc",
          color: "white",
          fontSize: "14px",
          height: "40px",
        }}
      >
        <Header title="ENERGY DATA" />
      </div>
      <div
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          marginTop: "20px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "white",
          margin: "20px auto",
          maxWidth: "1000px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", width: "30%" }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                flex: "1",
                marginRight: "10px",
              }}
              value={searchTerm}
              onChange={handleSearch}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: "#007acc", cursor: "pointer" }}
            />
          </div>

          <div style={{ marginLeft: "auto" }}>
            <button
              style={{
                padding: "10px 15px",
                marginRight: "10px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
              }} 
              onClick={() => {
                if (selectedItems.length > 0) {
                  setIsDeleteModalOpen(true);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: "5px" }} />
              Delete
            </button>
            <button
                onClick={() => setModalOpen(true)}
              style={{
                padding: "10px 15px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "8px",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: "5px" }} />
              Add
            </button>
          </div>
        </div>
        </div>
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
        {successMessage && (
          <div style={{
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#4CAF50', 
            color: 'white',
            margin: '10px auto',
            width: 'fit-content',
          }}>
            {successMessage}
          </div>
        )}
          </div>
        <div
          style={{
            marginTop: "20px",
            padding: "10px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            backgroundColor: "white",
            maxWidth: "1000px",
            margin: "20px auto",
            marginBottom: "50px",
          }}
        >
           <table style={{ width: "100%", textAlign: "left", borderCollapse: "collapse" }}>
            <thead style={{ backgroundColor: "#007acc", color: "white" }}>
              <tr>
              <th style={{ padding: "10px" }}>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedItems.length > 0 && selectedItems.length === paginatedData.length} 
                />
              </th>
                <th style={{ padding: "10px" }}>S.No</th>
                <th style={{ padding: "10px" }}>Year</th>
                <th style={{ padding: "10px" }}>Month</th>
                <th style={{ padding: "10px" }}>Renewable</th>
                <th style={{ padding: "10px" }}>Non-Renewable</th>
                <th style={{ padding: "10px" }}>Renewable %</th>
                <th style={{ padding: "10px" }}>Non-Renewable %</th>
                <th style={{ padding: "10px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((item, index) => (
                  <tr
                    key={item.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f2f2f2" : "#ffffff",
                    }}
                  >
                    <td style={{ padding: "10px" }}>
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item.id)}
                        onChange={(e) => handleCheckboxChange(e, item.id)}
                      />
                    </td>
                    <td style={{ padding: "10px" }}>
                      {(currentPage - 1) * itemsPerPage + index + 1}
                    </td>
                    <td style={{ padding: "10px" }}>{item.year}</td>
                    <td style={{ padding: "10px" }}>{item.month}</td>
                    <td style={{ padding: "10px" }}>{item.renewable.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KWh</td>
                    <td style={{ padding: "10px" }}>{item.non_renewable.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KWh</td>
                    <td style={{ padding: "10px" }}>{item.renewable_percentage} %</td>
                    <td style={{ padding: "10px" }}>{item.non_renewable_percentage} %</td>
                    <td
                      style={{ padding: "10px", color: "blue", cursor: "pointer" }}
                      onClick={() => handleEditClick(item)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ padding: "10px", textAlign: "center" }}>
                    No results found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              style={{
                margin: "0 5px",
                padding: "5px 10px",
                background: currentPage === 1 ? "#ddd" : "#007acc",
                color: "#ffffff",
                border: "none",
                borderRadius: "25px",
                cursor: currentPage === 1 ? "not-allowed" : "pointer",
              }}
            >
              Previous
            </button>

            {[...Array(totalPages).keys()].map((_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                style={{
                  margin: "0 5px",
                  padding: "5px 10px",
                  background: currentPage === i + 1 ? "#007acc" : "#ddd",
                  color: currentPage === i + 1 ? "#ffffff" : "#333",
                  border: "1px solid #ddd",
                  borderRadius: "25px",
                  cursor: "pointer",
                }}
              >
                {i + 1}
              </button>
            ))}

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{
                margin: "0 5px",
                padding: "5px 10px",
                background: currentPage === totalPages ? "#ddd" : "#007acc",
                color: "#ffffff",
                border: "none",
                borderRadius: "25px",
                cursor: currentPage === totalPages ? "not-allowed" : "pointer",
              }}
            >
              Next
            </button>
          </div>
        </div>

        {isModalOpen && (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
    <div
      ref={addModalRef}
      style={{
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
        width: '90%',
        maxWidth: '600px',
        maxHeight: '80vh',
        overflowY: 'auto',
      }}
    >
      <h4 style={{ marginBottom: '20px', textAlign: 'center' }}>Add Energy Data</h4>

      
      <div style={{ display: 'flex', gap: '20px', marginBottom: '15px' }}>
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginBottom: '5px' }}>
          Year <span style={{ color: 'red' }}>*</span>
        </label>
          <select
            style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            {Array.from({ length: 21 }, (_, index) => 2021 + index).map((yearOption) => (
              <option key={yearOption} value={yearOption}>
                {yearOption}
              </option>
            ))}
          </select>
          {validationMessages.year && <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.year}</div>}
        </div>
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
            <label style={{ marginBottom: '5px' }}>
          Month <span style={{ color: 'red' }}>*</span>
        </label>
          <select
            style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            <option value="">Select Month</option>
            {[
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December',
            ].map((monthOption) => (
              <option key={monthOption} value={monthOption}>
                {monthOption}
              </option>
            ))}
          </select>
          {validationMessages.month && <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.month}</div>}
        </div>
      </div>

      {/* Row 2: Renewable and Non-Renewable */}
      <div style={{ display: 'flex', gap: '20px', marginBottom: '15px' }}>
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginBottom: '5px' }}>
          Renewable Units <span style={{ color: 'red' }}>*</span>
        </label>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                width: '92%',
              }}
              value={formatNumberWithCommas(renewable)}
              onChange={(e) => setRenewable(removeCommas(e.target.value))}
            />
            <span style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              color: '#000',
            }}>
              KWh
            </span>
          </div>
          {validationMessages.renewable && <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.renewable}</div>}
        </div>
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginBottom: '5px' }}>
          Non-Renewable Units <span style={{ color: 'red' }}>*</span>
        </label>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                width: '92%',
              }}
              value={formatNumberWithCommas(nonRenewable)}
              onChange={(e) => setNonRenewable(removeCommas(e.target.value))}
            />
            <span style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              color: '#000',
            }}>
              KWh
            </span>
          </div>
          {validationMessages.nonRenewable && <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.nonRenewable}</div>}
        </div>
      </div>

      {/* Row 3: Renewable and Non-Renewable Percentage */}
      <div style={{ display: 'flex', gap: '20px', marginBottom: '15px' }}>
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginBottom: '5px' }}>
          Renewable Percentage <span style={{ color: 'red' }}>*</span>
        </label>
          <div style={{ position: 'relative' }}>
            <input
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                width: '92%',
              }}
              value={renewablePercentage}
              onChange={(e) => setRenewablePercentage(e.target.value)}
            />
            <span style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              color: '#000',
            }}>
              %
            </span>
          </div>
          {validationMessages.renewablePercentage && <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.renewablePercentage}</div>}
        </div>

        <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginBottom: '5px' }}>
          Non-Renewable Percentage <span style={{ color: 'red' }}>*</span>
        </label>
          <div style={{ position: 'relative' }}>
            <input
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                width: '92%',
              }}
              value={nonRenewablePercentage}
              onChange={(e) => setNonRenewablePercentage(e.target.value)}
            />
            <span style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              color: '#000',
            }}>
              %
            </span>
          </div>
          {validationMessages.nonRenewablePercentage && <div style={{ color: 'red', fontSize: '12px' }}>{validationMessages.nonRenewablePercentage}</div>}
        </div>
      </div>

      {/* Buttons */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <button
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            padding: '10px 12px',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: '1',
            marginRight: '10px',
          }}
          onClick={() => {
            const formattedRenewable = formatNumberWithCommas(renewable);
            const formattedNonRenewable = formatNumberWithCommas(nonRenewable);
            addEnergyData({ renewable: formattedRenewable, nonRenewable: formattedNonRenewable });
          }}
        >
          Add Energy
        </button>
        <button
          onClick={() => setModalOpen(false)}
          style={{
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            padding: '10px 12px',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: '1',
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

{isEditModalOpen && (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  }}>
    <div ref={editModalRef} style={{
      backgroundColor: 'white',
      padding: '30px',
      borderRadius: '10px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)', 
      width: '90%',
      maxWidth: '600px',
      maxHeight: '80vh',
      overflowY: 'auto',
    }}>
      <h4 style={{ marginBottom: '20px', textAlign: 'center', fontSize: '1.5rem', color: '#333' }}>Edit Energy Data</h4>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          <label style={{ marginBottom: '8px' }}>
            Year <span style={{ color: 'red' }}>*</span>
          </label>
            <select
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                fontSize: '0.9rem',
              }}
              value={editData.year}
              onChange={(e) => setEditData({ ...editData, year: e.target.value })}
              aria-label="Select Year"
            >
              {Array.from({ length: 21 }, (_, index) => 2021 + index).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            {editValidationMessages.year && <div style={{ color: 'red', fontSize: '12px' }}>{editValidationMessages.year}</div>}
          </div>
          <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          <label style={{ marginBottom: '8px' }}>
            Month <span style={{ color: 'red' }}>*</span>
          </label>
            <select
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                fontSize: '0.9rem',
              }}
              value={editData.month}
              onChange={(e) => setEditData({ ...editData, month: parseInt(e.target.value) })}
              aria-label="Select Month"
            >
              {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((monthName) => (
                <option key={monthName} value={monthName}>
                  {monthName}
                </option>
              ))}
            </select>
            {editValidationMessages.month && <div style={{ color: 'red', fontSize: '12px' }}>{editValidationMessages.month}</div>}
          </div>
        </div>

        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          <label style={{ marginBottom: '8px' }}>
          Renewable Units <span style={{ color: 'red' }}>*</span>
        </label>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                fontSize: '0.9rem',
                width: '92%',
              }}
              value={formatNumberWithCommas(editData.renewable)}
              onChange={(e) => setEditData({ ...editData, renewable: removeCommas(e.target.value) })}
              aria-label="Renewable Energy"
            />
            <span
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                color: '#000',
              }}
            >
              KWh
            </span>
          </div>
          {editValidationMessages.renewable && <div style={{ color: 'red', fontSize: '12px' }}>{editValidationMessages.renewable}</div>}
        </div>

        <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginBottom: '8px' }}>
          Non-Renewable Units <span style={{ color: 'red' }}>*</span>
        </label>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                fontSize: '0.9rem',
                width: '92%',
              }}
              value={formatNumberWithCommas(editData.non_renewable)}
              onChange={(e) => setEditData({ ...editData, non_renewable: removeCommas(e.target.value) })}
              aria-label="Non-Renewable Energy"
            />
            <span
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                color: '#000',
              }}
            >
              KWh
            </span>
          </div>
          {editValidationMessages.nonRenewable && <div style={{ color: 'red', fontSize: '12px' }}>{editValidationMessages.nonRenewable}</div>}
        </div>
        </div>

        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          <label style={{ marginBottom: '8px' }}>
          Renewable Percentage <span style={{ color: 'red' }}>*</span>
        </label>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                fontSize: '0.9rem',
                width: '92%',
              }}
              value={editData.renewable_percentage}
              onChange={(e) => setEditData({ ...editData, renewable_percentage: e.target.value })}
              aria-label="Renewable Percentage"
            />
            <span
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                color: '#000',
              }}
            >
              %
            </span>
          </div>
          {editValidationMessages.renewablePercentage && <div style={{ color: 'red', fontSize: '12px' }}>{editValidationMessages.renewablePercentage}</div>}
        </div>

        <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
        <label style={{ marginBottom: '8px' }}>
          Non-Renewable Percentage <span style={{ color: 'red' }}>*</span>
        </label>
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                fontSize: '0.9rem',
                width: '92%',
              }}
              value={editData.non_renewable_percentage}
              onChange={(e) => setEditData({ ...editData, non_renewable_percentage: e.target.value })}
              aria-label="Non-Renewable Percentage"
            />
            <span
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                color: '#000',
              }}
            >
              %
            </span>
          </div>
          {editValidationMessages.nonRenewablePercentage && <div style={{ color: 'red', fontSize: '12px' }}>{editValidationMessages.nonRenewablePercentage}</div>}
        </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '20px' }}>
          <button
            style={{
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '8px',
              cursor: 'pointer',
              fontSize: '0.9rem',
            }}
            onClick={handleSaveChanges}
          >
            Save Changes
          </button>
          <button
            style={{
              backgroundColor: '#f44336',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '8px',
              cursor: 'pointer',
              fontSize: '0.9rem',
            }}
            onClick={() => setEditModalOpen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)}

{isDeleteModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            ref={deleteModalRef}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "400px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete the selected Energy?</p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
               <div style={{ display: "flex", gap: "10px" }}>
              <button
                style={{
                  padding: "10px 15px",
                  color: "white",
                  backgroundColor:"#4CAF50",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={handleDeleteSelected}
              >
                Yes, Delete
              </button>
              <button
                style={{
                  padding: "10px 15px",
                  color: "white",
                  backgroundColor:"#f44336",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => setIsDeleteModalOpen(false)}
              >
                Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isErrorModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
          ref={errorModalRef}
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
              width: '90%',
              maxWidth: '400px',
            }}
          >
            <h4 style={{ marginBottom: '15px', textAlign: 'center' }}>Error</h4>
            <p style={{ textAlign: 'center' }}>{errorMessage}</p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <button
                onClick={closeErrorModal}
                style={{
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  padding: '10px 12px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default EnergyData;
